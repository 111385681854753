import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { endpoints } from "../../settings/endpoints";
import { GET } from "../../utils/apiRequests";
import getFormattedNews from "../../utils/getFormattedNews";

export const FETCH_NEWS_REDUCER = createAsyncThunk(
  "NewsReducer/fetchNewsReducer",
  async (params, { rejectWithValue }) => {
    try {
      let response = await GET(endpoints.dict.news, params);
      return response.data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        return rejectWithValue(e?.response?.data);
      }
    }
  },
);

const initialData = {
  error: null,
  data: {
    meta: {},
    list: [],
  },
  fetching: false,
  loading: false,
};

export const NewsReducer = createSlice({
  name: "NewsReducer",
  initialState: { ...initialData },
  reducers: {
    RUN_NEWS_LOADING_REDUCER: (state) => {
      state.fetching = false;
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(FETCH_NEWS_REDUCER.pending, (state) => {
      state.error = null;
      if (state?.data?.meta?.end) {
        state.fetching = true;
        return;
      }
      state.loading = true;
    });
    builder.addCase(FETCH_NEWS_REDUCER.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.fetching = false;
      state.data.meta = payload.meta;

      const updatedData = getFormattedNews(payload?.data);

      if (payload?.meta?.start > 0) {
        state.data.list = [...state.data.list, ...updatedData];
        return;
      }
      state.data.list = updatedData;
    });
    builder.addCase(FETCH_NEWS_REDUCER.rejected, (state, { payload }) => {
      state.loading = false;
      state.fetching = false;
      state.error = payload || {};
    });
  },
});

export const { RUN_NEWS_LOADING_REDUCER } = NewsReducer.actions;
export default NewsReducer.reducer;
