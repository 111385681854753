import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { endpoints } from "../../settings/endpoints";
import { GET } from "../../utils/apiRequests";

export const FETCH_CURRICULUM_TITLE_REDUCER = createAsyncThunk(
  "CurriculumReducer/fetchCurriculumTitleReducer",
  async (slug, { rejectWithValue }) => {
    try {
      const response = await GET(endpoints.curriculum.list, {
        slug,
      });
      return response.data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        return rejectWithValue(e?.response?.data);
      }
    }
  },
);

const initialData = {
  error: null,
  data: {
    activeCurriculumName: "",
    activeCurriculumTitle: "",
    activeCurriculumSlug: "",
    activeCurriculumPath: "",
    activeCurriculumEndDate: "",
    activeCurriculumStartDate: "",
    openedResourceSlug: "",
  },
  loading: false,
};

export const CurriculumReducer = createSlice({
  name: "CurriculumReducer",
  initialState: { ...initialData },
  reducers: {
    UPDATE_ACTIVE_CURRICULUM_DATES_REDUCER: (state, { payload }) => {
      state.data.activeCurriculumStartDate = payload?.startDate || "";
      state.data.activeCurriculumEndDate = payload?.endDate || "";
    },
    UPDATE_ACTIVE_CURRICULUM_REDUCER: (state, { payload }) => {
      state.data.activeCurriculumName = payload.name || "";
      state.data.activeCurriculumSlug = payload.slug || "";
    },
    UPDATE_ACTIVE_CURRICULUM_PATH_REDUCER: (state, { payload }) => {
      state.data.activeCurriculumPath = payload || "";
    },
    UPDATE_OPENED_RESOURCE_SLUG_REDUCER: (state, { payload }) => {
      state.data.openedResourceSlug = payload || "";
    },
    UPDATE_ACTIVE_CURRICULUM_TITLE_REDUCER: (state, { payload }) => {
      state.data.activeCurriculumTitle = payload || "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(FETCH_CURRICULUM_TITLE_REDUCER.pending, (state) => {
      state.error = null;
      state.loading = true;
    });
    builder.addCase(
      FETCH_CURRICULUM_TITLE_REDUCER.fulfilled,
      (state, { payload }) => {
        const curriculum = payload?.[0];
        state.data.activeCurriculumName = curriculum?.curriculum_name || "";
        state.data.activeCurriculumSlug = curriculum?.slug || "";
        state.data.activeCurriculumEndDate = curriculum?.end_date || "";
        state.data.activeCurriculumStartDate = curriculum?.start_date || "";
        state.loading = false;
      },
    );
    builder.addCase(
      FETCH_CURRICULUM_TITLE_REDUCER.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload || {};
      },
    );
  },
});

export const {
  UPDATE_ACTIVE_CURRICULUM_REDUCER,
  UPDATE_ACTIVE_CURRICULUM_PATH_REDUCER,
  UPDATE_OPENED_RESOURCE_SLUG_REDUCER,
  UPDATE_ACTIVE_CURRICULUM_TITLE_REDUCER,
  UPDATE_ACTIVE_CURRICULUM_DATES_REDUCER,
} = CurriculumReducer.actions;
export default CurriculumReducer.reducer;
