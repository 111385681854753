import React from "react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import TEST_IDS from "../../../constants/testIds";
import usePathActive from "../../../hooks/usePathActive";
import { UPDATE_MAIN_ITEM_REDUCER } from "../../../store/reducers/MainReducer";
import LinkComponent from "../../Links/LinkComponent";
import ShortSideMenuComponent from "../components/ShortSideMenuComponent";
import ActiveMenuItemTitleComponent from "./ActiveMenuItemTitleComponent";
import MainSideMenuIconComponent from "./MainSideMenuIconComponent";
import MenuItemListIconComponent from "./MenuItemListIconComponent";
import SecondLevelMenuItemComponent from "./SecondLevelMenuItemComponent";
import SideMenuTooltipComponent from "./SideMenuTooltipComponent";

// this component is an item of the first level of the side menu
// this component is can be shown in all versions of menu

const SideMenuItemComponent = ({
  name,
  iconKey,
  items = [],
  path,
  external = false,
  namespace,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isFirstMenuOpen, setIsFirstMenuOpen] = useState(false);
  const [hideAllMenus, setHideAllMenus] = useState(false);
  const isPathActive = usePathActive(path);

  const handleHideAllMenus = useCallback(async () => {
    await setHideAllMenus(true);
    setHideAllMenus(false);
  }, []);

  const onFirstLevelItemClick = useCallback(() => {
    if (isFirstMenuOpen === true) {
      handleHideAllMenus();
    }
    setIsFirstMenuOpen(!isFirstMenuOpen);
  }, [isFirstMenuOpen, handleHideAllMenus]);

  const handleCloseSideMenu = useCallback(() => {
    if (path) {
      dispatch(
        UPDATE_MAIN_ITEM_REDUCER({
          id: "showSideMenu",
          value: false,
        }),
      );
    }
  }, [path]);

  useEffect(() => {
    if (!location.pathname.includes(namespace)) {
      setIsFirstMenuOpen(false);
      handleHideAllMenus();
    }
  }, [location.pathname, namespace, handleHideAllMenus]);

  return (
    <LinkComponent
      to={path}
      externalLink={external}
      target={""}
      onClick={handleCloseSideMenu}
    >
      <li className="overflow-hidden">
        <div
          className="cursor-pointer px-6 py-4 flex justify-between relative hover:text-primary duration-default"
          onClick={onFirstLevelItemClick}
          data-testid={TEST_IDS.SIDE_MENU.FIRST_LEVEL_ITEM}
        >
          <div className="flex gap-4">
            <SideMenuTooltipComponent title={name}>
              <MainSideMenuIconComponent iconKey={iconKey} name={name} />
            </SideMenuTooltipComponent>
            <ShortSideMenuComponent>
              <ActiveMenuItemTitleComponent
                className="text-sm"
                path={path}
                title={name}
              />
            </ShortSideMenuComponent>
          </div>
          <ShortSideMenuComponent>
            <MenuItemListIconComponent
              show={items?.length}
              isRotate={isFirstMenuOpen}
              title={name}
            />
          </ShortSideMenuComponent>
          <div
            className={`w-1 h-full rounded-br rounded-tr left-0 top-0 absolute ${
              isPathActive ? "bg-primary" : "bg-transparent"
            }`}
          />
        </div>
        <ol
          data-testid={TEST_IDS.SIDE_MENU.SECOND_LEVEL_LIST}
          className={`${
            items.length > 0 && isFirstMenuOpen ? "block" : "hidden"
          } duration-default`}
        >
          {items.map((childItem, id) => (
            <SecondLevelMenuItemComponent
              key={id}
              hideAllMenus={hideAllMenus}
              onHideAllMenus={handleHideAllMenus}
              path={childItem.path}
              slug={childItem.slug}
              brandName={childItem.brand_name}
              url={childItem.url}
              iconSrc={childItem.icon}
              itemId={childItem.id}
              itemName={childItem.title}
              external={childItem.external}
              internalLink={childItem.internal_link}
              hasCategories={childItem.has_categories}
            />
          ))}
        </ol>
      </li>
    </LinkComponent>
  );
};

export default SideMenuItemComponent;
