import { Auth } from "aws-amplify";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import alarmIcon from "../../assets/icons/alarm.svg";
import chevronDownIcon from "../../assets/icons/chevron-down.svg";
import BookmarksIcon from "../../assets/icons/component-icons/resource-card/BookmarksIcon";
import { LIST_ITEM_CLASS_NAMES, USER_ROLES } from "../../constants/constants";
import TEST_IDS from "../../constants/testIds";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import {
  BOOKMARKS_ROUTE,
  LATEST_ROUTE,
  LOGIN_ROUTE,
  MY_PERFORMANCE_ROUTE,
  SETTINGS_ROUTE,
} from "../../settings/routes";
import { useGetLatestResourceQuery } from "../../store/services";
import removeAccessToken from "../../utils/localStorage/token/removeAccessToken";
import CardContainerComponent from "../Cards/CardContainerComponent";
import HeaderIconContainerComponent from "../Containers/HeaderIconContainerComponent";
import LMSHideComponent from "../LMSHide/LMSHideComponent";

import CustomerServiceComponent from "./components/CustomerServiceComponent";
import UserMenuItemComponent from "./components/UserMenuItemComponent";
import UserProfileAvatarComponent from "./components/UserProfileAvatarComponent";

const UserProfileMenuComponent = () => {
  const { data: userData } = useSelector((state) => state.UserReducer);
  const { data: latestResourceResponse } = useGetLatestResourceQuery();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [latest, setLatest] = useState(false);
  const menu = useRef();
  const navigate = useNavigate();

  useOnClickOutside(menu, () => setIsMenuOpen(false));

  const onLogout = async () => {
    try {
      await Auth.signOut();
      removeAccessToken();
      navigate(LOGIN_ROUTE);
    } catch (error) {
      console.log("Error.", error.message, "danger");
    }
  };

  const handleCloseMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  useEffect(() => {
    if (userData?.user_role !== USER_ROLES.ADMIN) {
      setLatest(Boolean(latestResourceResponse?.data?.length));
    }
  }, [userData?.user_role, latestResourceResponse]);

  return (
    <div
      className="relative cursor-default w-auto min-w-fit flex gap-2 items-center"
      ref={menu}
    >
      <HeaderIconContainerComponent title="Bookmarks">
        <Link to={BOOKMARKS_ROUTE} className="stroke-dark">
          <BookmarksIcon className="w-3" />
        </Link>
      </HeaderIconContainerComponent>
      <CustomerServiceComponent />
      <HeaderIconContainerComponent title="Latest items">
        <Link to={LATEST_ROUTE}>
          <img src={alarmIcon} alt="Latest items" className="w-full" />
          {latest ? (
            <div
              className="absolute right-1 -mr-2 top-1 -mt-2 w-[8px] h-[8px] min-w-[8px] min-h-[8px] rounded-full bg-alert text-white flex items-center
              justify-center text-[10px] font-bold"
            />
          ) : null}
        </Link>
      </HeaderIconContainerComponent>
      <div
        data-testid={TEST_IDS.HEADER.BUTTON}
        className="flex items-center justify-end cursor-pointer select-none hover:text-primary duration-default"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <UserProfileAvatarComponent />
        <div className="mr-4 hidden lg:block">{userData?.user_name}</div>
        <img
          src={chevronDownIcon}
          className={`hidden duration-default w-4 ${
            isMenuOpen ? "rotate-180" : ""
          } sm:block`}
          alt="Icon: Extend menu"
          title="Icon: Extend menu"
        />
      </div>
      {isMenuOpen && (
        <CardContainerComponent
          testId={TEST_IDS.HEADER.MENU}
          paddingClassName
          className="absolute top-full mt-2 right-0 w-64 sm:w-80 shadow-extended z-50"
        >
          <div className="flex items-center py-3 px-4">
            <UserProfileAvatarComponent />
            <div className="ml-2 sm:ml-0 items-center text-sm">
              <div className="font-bold">{userData?.user_name}</div>
              <div className="break-words text-xs truncate max-w-[11rem] sm:max-w-[15rem]">
                {userData?.email}
              </div>
            </div>
          </div>
          <ol>
            <LMSHideComponent>
              <UserMenuItemComponent
                title="My Learning & Development"
                path={MY_PERFORMANCE_ROUTE}
                onClick={handleCloseMenu}
              />
            </LMSHideComponent>
            <UserMenuItemComponent
              title="Settings"
              path={SETTINGS_ROUTE}
              onClick={handleCloseMenu}
            />
            <li
              className={LIST_ITEM_CLASS_NAMES}
              onClick={onLogout}
              data-testid={TEST_IDS.BUTTONS.LOGOUT}
            >
              Log Out
            </li>
          </ol>
        </CardContainerComponent>
      )}
    </div>
  );
};

export default UserProfileMenuComponent;
