import isIpad from "./isIpad";

const getPlatform = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const checkIpad = isIpad();

  switch (true) {
    case /android/i.test(userAgent):
      return "Android";
    case /iphone/i.test(userAgent) || checkIpad:
      return "iOS";
    case /windows/i.test(userAgent):
      return "Windows";
    case /linux/i.test(userAgent):
      return "Linux";
    case /mac/i.test(userAgent):
      return "Mac";
    default:
      return "Unknown Platform";
  }
};

export default getPlatform;
