import PropTypes from "prop-types";
import React from "react";
import ViewButton from "../ViewButton/ViewButton";

const SingleItemCardFooterComponent = ({
  children,
  buttonTitle,
  isDisabledClassName,
}) => {
  return (
    <div className="px-4 py-3 flex items-center justify-between bg-gray-light rounded-b-medium">
      <ViewButton title={buttonTitle} isDisabled={isDisabledClassName} />
      {children}
    </div>
  );
};

SingleItemCardFooterComponent.propTypes = {
  children: PropTypes.object,
  buttonTitle: PropTypes.string,
  isDisabledClassName: PropTypes.string,
};

export default React.memo(SingleItemCardFooterComponent);
