import React from "react";

import LinksIcon from "../../assets/icons/component-icons/side-menu/LinksIcon";

const DirectIconItemComponent = ({
  show,
  color = "#212121",
  className = "w-4 h-4 min-w-[1rem]",
  children,
}) => {
  if (!show) {
    return children;
  }
  return <LinksIcon color={color} className={className} />;
};

export default React.memo(DirectIconItemComponent);
