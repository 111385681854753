import PropTypes from "prop-types";
import React, { useContext, useMemo } from "react";
import ViewResourceIcon from "../../assets/icons/component-icons/resource-card/ViewResourceIcon";
import LinksIcon from "../../assets/icons/component-icons/side-menu/LinksIcon";
import { RESOURCE_REDIRECT_TYPES } from "../../constants/singleItemTypes";
import { SingleItemCardOrListContext } from "../../contexts/SingleItemCardOrListProvider";
import IconResourceButtonComponent from "../Icons/IconResourceButtonComponent";
import SingleItemLinkComponent from "../SingleItemLink/SingleItemLinkComponent";

export default function ViewButton({ title, isDisabledClassName }) {
  const { contentType } = useContext(SingleItemCardOrListContext);

  const ViewIcon = useMemo(() => {
    if (RESOURCE_REDIRECT_TYPES.includes(contentType)) {
      return LinksIcon;
    }
    return ViewResourceIcon;
  }, [contentType]);

  return (
    <SingleItemLinkComponent className={isDisabledClassName}>
      <IconResourceButtonComponent
        Icon={ViewIcon}
        title={title}
        iconHeightClassName="h-3"
        useCssFlex={true}
      />
    </SingleItemLinkComponent>
  );
}

ViewButton.propTypes = {
  title: PropTypes.string,
  isDisabledClassName: PropTypes.string,
};
