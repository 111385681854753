import React, { useMemo } from "react";
import chevronDownArrow from "../../../assets/icons/chevron-down.svg";

const MenuItemListIconComponent = ({
  show,
  title,
  containerClassName = "flex items-center",
  isRotate,
}) => {
  const className = useMemo(() => {
    const className = "w-[12px] min-w-[12px] duration ";

    if (isRotate) {
      return `${className} rotate-180`;
    }

    return className;
  }, [isRotate]);

  if (!show) {
    return null;
  }

  return (
    <section className={containerClassName}>
      <img
        src={chevronDownArrow}
        className={className}
        alt={`Extend: ${title}`}
        title={`Extend: ${title}`}
      />
    </section>
  );
};

export default React.memo(MenuItemListIconComponent);
