import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import TEST_IDS from "../../../constants/testIds";
import { BRANDS_ROUTE } from "../../../settings/routes";
import { CLOSE_MOBILE_SIDE_MENU_REDUCER } from "../../../store/reducers/MainReducer";
import DirectIconItemComponent from "../../Icons/DirectIconItemComponent";
import LinkComponent from "../../Links/LinkComponent";
import ActiveMenuItemTitleComponent from "./ActiveMenuItemTitleComponent";

// this component is an item of the third level of the side menu
// this component is can be shown just in the default version of menu

const ThirdLevelMenuItemComponent = ({
  categoryName,
  iconSrc,
  parentSlug,
  slug,
  url,
  path,
  internalLink,
}) => {
  const childItemPath = useMemo(
    () => url || `${BRANDS_ROUTE}/${parentSlug}/${slug}`,
    [parentSlug, slug, url],
  );
  const dispatch = useDispatch();

  const handleCloseSideMenu = useCallback(() => {
    dispatch(CLOSE_MOBILE_SIDE_MENU_REDUCER());
  }, [dispatch]);

  return (
    <LinkComponent
      internalLink={internalLink}
      to={childItemPath}
      externalLink={Boolean(url)}
      onClick={handleCloseSideMenu}
    >
      <li
        className="pl-16 lg:pl-12 xl:pl-16 py-4 pr-6 flex gap-4 relative text-sm text-dark hover:text-primary duration-default items-center justify-between"
        data-testid={TEST_IDS.SIDE_MENU.CATEGORY_ITEM}
      >
        <div className="flex">
          {iconSrc && (
            <img
              src={iconSrc}
              alt={`Go to ${categoryName}`}
              title={`Go to ${categoryName}`}
            />
          )}
          <ActiveMenuItemTitleComponent title={categoryName} path={path} />
        </div>
        <DirectIconItemComponent show={url} />
      </li>
    </LinkComponent>
  );
};

export default React.memo(ThirdLevelMenuItemComponent);
