import { useContext, useMemo } from "react";

import { CompanyDataContext } from "../contexts/CompanyDataProvider";

const useMetaTitle = (title) => {
  const companyContextData = useContext(CompanyDataContext);
  const metaTitle = useMemo(() => {
    let updatedTitle = "";
    const companyName = companyContextData?.data?.name;

    updatedTitle = companyName ? `${companyName} - ${title}` : title;

    return updatedTitle.substring(0, 60);
  }, [companyContextData, title]);

  return metaTitle || "";
};

export default useMetaTitle;
