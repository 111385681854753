/* eslint-disable react/no-unescaped-entities */
import React from "react";
import NotFoundIcon from "../../assets/icons/component-icons/NotFoundIcon";
import BrandCardListComponent from "../../components/Cards/BrandCardListComponent";
import ErrorBoundaryComponent from "../../components/ErrorBoundary/ErrorBoundaryComponent";
import ResourcesColumnComponent from "../../components/ResourcesColumn/ResourcesColumnComponent";
import { DEFAULT_GAP_CLASS_NAMES } from "../../constants/classNamesConstants";
import {
  NOT_FOUND_BRANDS_LENGTH,
  NOT_FOUND_NEWS_PAGINATION,
  NOT_FOUND_RESOURCES_PAGINATION,
} from "../../constants/constants";
import NEWS_ORIENTATION from "../../constants/newsOrientation";
import { useGetNewlyAssignedQuery } from "../../store/services";
import DashboardNewsComponent from "../Company/components/DashboardNewsComponent";

const NotFoundPage = () => {
  return (
    <div className=" h-full bg-background-light ">
      <ErrorBoundaryComponent
        Icon={NotFoundIcon}
        title={"Error 404"}
        subtitle={"Uh oh, we can’t find that page..."}
        description={
          "Sorry, the page you are looking for doesn't exist or has been moved."
        }
      />
      <div
        className={`mt-20 pb-6 w-full block justify-between sm:flex sm:flex-wrap lg:flex-nowrap ${DEFAULT_GAP_CLASS_NAMES}`}
      >
        <div className={`${DEFAULT_GAP_CLASS_NAMES} w-full sm:flex lg:w-2/3`}>
          <BrandCardListComponent
            containerClassName="sm:w-1/2"
            maxLength={NOT_FOUND_BRANDS_LENGTH}
          />
          <ResourcesColumnComponent
            title="Recently Added"
            perPage={NOT_FOUND_RESOURCES_PAGINATION}
            disableInfiniteScroll
            containerClassName={"mt-4 sm:mt-0 w-full sm:w-1/2"}
            getResourcesQuery={useGetNewlyAssignedQuery}
          />
        </div>
        <DashboardNewsComponent
          orientation={NEWS_ORIENTATION.VERTICAL}
          top={NOT_FOUND_NEWS_PAGINATION}
          containerClassName="mt-4 md:mt-0 w-full lg:w-1/3"
        />
      </div>
    </div>
  );
};

export default NotFoundPage;
