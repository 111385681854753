import React, { useMemo } from "react";
import { useSelector } from "react-redux";

// this component hides child elements if the short version of the menu has been activated
// viceVersa displays children if the short version of the menu has been activated

const ShortSideMenuComponent = ({ children, viceVersa, isControl }) => {
  const { shortSideMenu, shortSideMenuControls } = useSelector(
    (state) => state.MainReducer,
  );
  const hideChildren = useMemo(() => {
    if (isControl) {
      return viceVersa ? shortSideMenuControls : !shortSideMenuControls;
    }

    return viceVersa ? !shortSideMenu : shortSideMenu;
  }, [shortSideMenu, viceVersa, isControl, shortSideMenuControls]);

  if (hideChildren) {
    return null;
  }
  return <>{children}</>;
};

export default React.memo(ShortSideMenuComponent);
