import React from "react";

const ContestsIcon = ({ color, ...props }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.375 6.25H3.75C3.08696 6.25 2.45107 5.98661 1.98223 5.51777C1.51339 5.04893 1.25 4.41304 1.25 3.75V0.625H4.375"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M15.625 6.25H16.25C16.913 6.25 17.5489 5.98661 18.0178 5.51777C18.4866 5.04893 18.75 4.41304 18.75 3.75V0.625H15.625"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M10 16.25V12.5"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M10 12.5C8.50816 12.5 7.07742 11.9074 6.02252 10.8525C4.96763 9.79758 4.375 8.36684 4.375 6.875V0.625H15.625V6.875C15.625 7.61369 15.4795 8.34514 15.1968 9.02759C14.9141 9.71005 14.4998 10.3301 13.9775 10.8525C13.4551 11.3748 12.8351 11.7891 12.1526 12.0718C11.4701 12.3545 10.7387 12.5 10 12.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M14.375 19.375H5.625C5.625 18.5462 5.95424 17.7513 6.54029 17.1653C7.12634 16.5792 7.9212 16.25 8.75 16.25H11.25C11.6604 16.25 12.0667 16.3308 12.4459 16.4879C12.825 16.6449 13.1695 16.8751 13.4597 17.1653C13.7499 17.4555 13.9801 17.8 14.1371 18.1791C14.2942 18.5583 14.375 18.9646 14.375 19.375Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default ContestsIcon;
