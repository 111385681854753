import React from "react";

const SideMenuImageComponent = ({ src, alt }) => {
  if (!src) {
    return <div className="w-4" />;
  }

  return <img src={src} className="w-4" alt={`Brand: ${alt}`} />;
};

export default SideMenuImageComponent;
