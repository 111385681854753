import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import useShowMobile from "../../hooks/useShowMobile";

const DashboardCardContainerComponent = ({ children, testId }) => {
  const isMobile = useShowMobile();
  return (
    <section
      className={classNames("w-full min-w-[90%]", {
        "sm:min-w-full": !isMobile,
        "sm:min-w-[55%]": isMobile,
      })}
      data-testid={testId}
    >
      {children}
    </section>
  );
};

DashboardCardContainerComponent.propTypes = {
  children: PropTypes.node,
  testId: PropTypes.string,
};
export default React.memo(DashboardCardContainerComponent);
