import PropTypes from "prop-types";
import React, { useMemo } from "react";

import spiderNetIcon from "../../assets/icons/spider-net.svg";
import { EMPTY_ITEMS_TEXT } from "../../constants/constants";

const EmptyContainerComponent = ({ emptyText = "", show, children }) => {
  const updatedEmptyText = useMemo(
    () => emptyText || EMPTY_ITEMS_TEXT,
    [emptyText],
  );

  if (!show) {
    return <>{children}</>;
  }

  return (
    <div className="flex flex-col h-full justify-center items-center gap-2 cursor-default select-none">
      <img
        src={spiderNetIcon}
        alt={updatedEmptyText}
        title={updatedEmptyText}
        className="w-8"
      />
      <p className="text-sm text-cement text-center">{updatedEmptyText}</p>
    </div>
  );
};

EmptyContainerComponent.propTypes = {
  children: PropTypes.any,
  emptyText: PropTypes.string,
  show: PropTypes.bool,
};
export default React.memo(EmptyContainerComponent);
