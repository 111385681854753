import PropTypes from "prop-types";
import React, { useContext } from "react";
import { SingleItemCardOrListContext } from "../../contexts/SingleItemCardOrListProvider";
import DateChipComponent from "../Chip/DateChipComponent";
import BrandIconImageComponent from "../Images/BrandIconImageComponent";

const SingleItemCardHeaderComponent = ({
  children,
  image,
  canBeUncoming,
  isActiveCurriculumDate,
  hideDate,
  endDate,
  startDate,
  className = "pointer-events-auto",
}) => {
  const { title } = useContext(SingleItemCardOrListContext);
  return (
    <div className="absolute top-0 left-0 p-4 w-full flex justify-between items-center">
      <BrandIconImageComponent title={title} image={image} />
      <div className={`flex items-center gap-2 ${className}`}>
        {children}

        <DateChipComponent
          hideDate={hideDate}
          isActiveCurriculumDate={isActiveCurriculumDate}
          endDate={endDate}
          canBeUncoming={canBeUncoming}
          startDate={startDate}
          className={"ml-2"}
          showTooltip
        />
      </div>
    </div>
  );
};

SingleItemCardHeaderComponent.propTypes = {
  children: PropTypes.object,
  image: PropTypes.string,
  canBeUncoming: PropTypes.bool,
  isActiveCurriculumDate: PropTypes.bool,
  hideDate: PropTypes.bool,
  endDate: PropTypes.string,
  startDate: PropTypes.string,
};
export default React.memo(SingleItemCardHeaderComponent);
