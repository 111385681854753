import ReactGA from "react-ga4";
import { PROJECT_NAME } from "../constants/constants";
import isPWA from "./isPwa";

// this file contains all the elements that are used in the system to communicate with GA4.

export const GA_CATEGORIES = {
  RESOURCES: "resources",
  CHALLENGES: "challenges",
  USERS: "users",
};

export const GA_ACTIONS = {
  OPENED: "opened",
  DOWNLOADED: "downloaded",
  PDF_DOWNLOADED: "pdf_downloaded",
  ADDED_TO_BOOKMARK: "added_to_bookmark",
  REMOVED_FROM_BOOKMARK: "removed_from_bookmark",
  USER_IS_LOGGED_IN: "user_is_logged_in",
  USER_USES_PWA: "user_uses_pwa",
};

// This allows the GA administrator to identify all user activities in the platform in GA
const gaSetUserId = (defaultId) => {
  const userId = defaultId || localStorage.getItem("userGaId");
  if (!userId) {
    return;
  }

  ReactGA.set({
    user_id: `agilify-${userId}`,
  });
};

export const gaEventPush = (category, action, label, value) => {
  if (!ReactGA.isInitialized) {
    return;
  }
  gaSetUserId();
  ReactGA.event({
    category,
    action,
    label,
    value,
  });
};

export const gaUserLogin = (userId) => {
  if (!ReactGA.isInitialized) {
    return;
  }
  localStorage.setItem("userGaId", userId);
  gaSetUserId(userId);
  gaEventPush(GA_CATEGORIES.USERS, GA_ACTIONS.USER_IS_LOGGED_IN, userId);

  if (isPWA()) {
    gaEventPush(GA_CATEGORIES.USERS, GA_ACTIONS.USER_USES_PWA, "PWA Login");
  }
};

export const gaPageViewEvent = (title) => {
  if (title === PROJECT_NAME || !ReactGA.isInitialized) {
    return;
  }
  gaSetUserId();
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: title,
  });
};
