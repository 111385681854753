import { NEWS_ROUTE } from "../settings/routes";

import getGeneratedImgUrl from "./getGeneratedImgUrl";

const getFormattedNews = (list = []) => {
  if (!list.length) {
    return [];
  }

  return list.map((news) => ({
    createdAt: news.created_at,
    title: news.title,
    brandSlug: news.brand_slug,
    newsDate: news.news_date,
    description: news.news_description,
    path: `${NEWS_ROUTE}/${news.id}`,
    previewImage: getGeneratedImgUrl(news.preview_image),
  }));
};

export default getFormattedNews;
