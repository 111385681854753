import classNames from "classnames";
import React from "react";
import { Tooltip } from "react-tooltip";

import iconChevronRight from "../../assets/icons/chevron-right.svg";
import iconUserArrow from "../../assets/icons/user-arrow.svg";
import TEST_IDS from "../../constants/testIds";
import OptionalLinkComponent from "../Links/OptionalLinkComponent";

const WidgetHeaderComponent = ({
  title,
  icon,
  withArrow = true,
  path,
  isCursorPointer,
  classNameContainer = "",
}) => {
  return (
    <>
      <OptionalLinkComponent itemPath={path} isCursorPointer={isCursorPointer}>
        <div
          data-testid={TEST_IDS.WIDGET_HEADER}
          className={classNames(
            "flex justify-between items-center select-none",
            { [classNameContainer]: classNameContainer },
          )}
          id={`tooltip-widget-header-${title}`}
        >
          <div className="flex items-center gap-2.5">
            <img
              className="w-4 h-4 lg:w-6 lg:h-6 object-contain"
              src={icon || iconUserArrow}
              alt={`Widget: ${title}`}
              title={`Widget: ${title}`}
            />
            <span
              className="truncate font-medium text-sm lg:text-md"
              title={title}
            >
              {title}
            </span>
          </div>
          {withArrow && (
            <img
              src={iconChevronRight}
              alt={`Go to ${title}`}
              title={`Go to ${title}`}
            />
          )}
        </div>
      </OptionalLinkComponent>
      <Tooltip
        anchorId={`tooltip-widget-header-${title}`}
        content={title}
        className="sm:block lg:hidden"
      />
    </>
  );
};

export default WidgetHeaderComponent;
