import stringToBoolean from "../string/stringToBoolean";

export default function prepareConfiguration() {
  const enabled = stringToBoolean(process.env.REACT_APP_SENTRY_ENABLED);
  const debug = stringToBoolean(process.env.REACT_APP_SENTRY_DEBUG);
  const dsn = process.env.REACT_APP_SENTRY_DSN;

  const sessionReplay = {
    sampleRate: extractFloatFrom(
      process.env.REACT_APP_SENTRY_SESSION_REPLAY_SAMPLE_RATE,
    ),
    onErrorSampleRate: extractFloatFrom(
      process.env.REACT_APP_SENTRY_SESSION_REPLAY_ON_ERROR_SAMPLE_RATE,
    ),
  };

  function extractFloatFrom(value) {
    return Math.abs(Number(value));
  }

  return {
    enabled,
    debug,
    dsn,
    sessionReplay,
  };
}
