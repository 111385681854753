import React from "react";

const PanelIcon = ({ color, ...props }) => {
  return (
    <svg
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 1V3.5"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M16.4817 3.685L14.7142 5.4525"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M19.1666 10.1667H16.6666"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M5.83331 6L8.82165 8.98833"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M0.833313 10.1667H3.33331"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M9.99998 11.8333C10.9205 11.8333 11.6666 11.0871 11.6666 10.1667C11.6666 9.24619 10.9205 8.5 9.99998 8.5C9.07951 8.5 8.33331 9.24619 8.33331 10.1667C8.33331 11.0871 9.07951 11.8333 9.99998 11.8333Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M17.0708 16C18.38 14.415 19.1666 12.3825 19.1666 10.1667C19.1666 5.10417 15.0625 1 9.99998 1C4.93748 1 0.833313 5.10417 0.833313 10.1667C0.833313 12.3825 1.61998 14.415 2.92915 16H17.0708Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default PanelIcon;
