import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import SideMenuCloseIcon from "../../../assets/icons/component-icons/side-menu/SideMenuCloseIcon";
import SideMenuIcon from "../../../assets/icons/component-icons/side-menu/SideMenuIcon";

const SideMenuIconComponent = ({ onClick = () => {} }) => {
  const { showSideMenu } = useSelector((state) => state.MainReducer);
  const Icon = useMemo(
    () => (showSideMenu ? SideMenuCloseIcon : SideMenuIcon),
    [showSideMenu],
  );

  return (
    <Icon
      onClick={onClick}
      className={"w-3.5 h-3.5 cursor-pointer hover:opacity-80"}
    />
  );
};

SideMenuIconComponent.propTypes = {
  onClick: PropTypes.func,
};
export default React.memo(SideMenuIconComponent);
