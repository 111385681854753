import React from "react";

import ArticlesComponent from "../../../components/Articles/ArticlesComponent";
import CUSTOM_ICON_KEYS from "../../../constants/customIconKeys";
import useGetCustomIcon from "../../../hooks/useGetCustomIcon";

const DashboardNewsComponent = ({
  orientation,
  containerClassName,
  removeActiveArticle,
}) => {
  const [customIcon] = useGetCustomIcon(CUSTOM_ICON_KEYS.NEWS);

  return (
    <div className={containerClassName}>
      <ArticlesComponent
        removeActiveArticle={removeActiveArticle}
        title="News"
        iconSrc={customIcon}
        path="/news"
        orientation={orientation}
      />
    </div>
  );
};

export default DashboardNewsComponent;
