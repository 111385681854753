import moment from "moment";
import React, { useContext, useMemo } from "react";
import { CARD_DATE_FORMAT } from "../../constants/date";
import { SingleItemCardOrListContext } from "../../contexts/SingleItemCardOrListProvider";

const SingleItemCardExpirationDateComponent = () => {
  const { expirationDate, jobCode } = useContext(SingleItemCardOrListContext);
  const title = useMemo(() => {
    let newTitle = `${jobCode || ""}${expirationDate && jobCode ? " · " : " "}`;

    if (expirationDate) {
      const date = moment(expirationDate).format(CARD_DATE_FORMAT);
      newTitle += `Expires: ${date}`;
    }

    return newTitle;
  }, [expirationDate, jobCode]);

  if (!title) {
    return null;
  }

  return <p className="text-cement text-sm">{title}</p>;
};

export default React.memo(SingleItemCardExpirationDateComponent);
