import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import TEST_IDS from "../../constants/testIds";

const LinkComponent = ({
  to,
  title,
  onClick = () => {},
  className = "cursor-pointer",
  testId,
  externalLink,
  internalLink,
  download,
  children,
  target = "_blank",
}) => {
  // The link can be external or internal.
  // Internal links always should be opened in the same tab.
  // External links should be opened in the next tab y default, but it can be changed.
  const updatedTarget = useMemo(
    () => (internalLink ? "_self" : target),
    [internalLink],
  );

  if (externalLink) {
    return (
      <a
        className={className}
        href={to}
        download={download}
        target={updatedTarget}
        rel="noreferrer"
        title={title}
        data-testid={TEST_IDS.EXTERNAL_LINK}
      >
        {children}
      </a>
    );
  }

  if (!to) {
    return (
      <div className={className} data-testid={TEST_IDS.WITHOUT_LINK}>
        {children}
      </div>
    );
  }
  return (
    <Link
      to={to}
      title={title}
      onClick={onClick}
      className={className}
      data-testid={testId}
    >
      {children}
    </Link>
  );
};

export default React.memo(LinkComponent);
