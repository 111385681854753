import BrandBackground1Icon from "../assets/icons/brandBackground/BrandBackground1Icon";
import BrandBackground2Icon from "../assets/icons/brandBackground/BrandBackground2Icon";
import BrandBackground3Icon from "../assets/icons/brandBackground/BrandBackground3Icon";
import BrandBackground4Icon from "../assets/icons/brandBackground/BrandBackground4Icon";

const getCardSvgBackground = (index) => {
  const backgrounds = [
    BrandBackground1Icon,
    BrandBackground2Icon,
    BrandBackground3Icon,
    BrandBackground4Icon,
    BrandBackground4Icon,
  ];
  const returnedIndex = index % backgrounds.length;
  return backgrounds[returnedIndex || 0];
};

export default getCardSvgBackground;
