import React from "react";

const NewsIcon = ({ color, ...props }) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.5 12.1667H15.8333"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M7.5 15.5H15.8333"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M15.8333 5.5H7.5V8.83333H15.8333V5.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M2.49998 19.6667C2.05836 19.6653 1.6352 19.4893 1.32293 19.177C1.01065 18.8648 0.834632 18.4416 0.833313 18V11.3333H1.66665"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M4.16667 1.33333V18C4.16535 18.4416 3.98933 18.8648 3.67705 19.1771C3.36478 19.4893 2.94162 19.6653 2.5 19.6667H17.5C17.9416 19.6653 18.3648 19.4893 18.6771 19.1771C18.9893 18.8648 19.1653 18.4416 19.1667 18V1.33333H4.16667Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default NewsIcon;
