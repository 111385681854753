import { createSlice } from "@reduxjs/toolkit";

const initialData = {
  showSideMenu: false,
  title: "",
  navbarHeight: 0,
  footerHeight: 0,
  warningHeight: 0,
  mainContainerStyle: {},
  shortSideMenu: false, // if true, the side menu will be shown as a short menu without text
  shortSideMenuControls: false, // if ture, some short menu controls which show/hide the side menu will be shown
};

export const MainReducer = createSlice({
  name: "MainReducer",
  initialState: { ...initialData },
  reducers: {
    UPDATE_MAIN_ITEM_REDUCER: (state, { payload }) => {
      state[payload.id] = payload.value;
    },
    UPDATE_MAIN_TITLE_REDUCER: (state, { payload }) => {
      state.title = payload;
    },
    CLOSE_MOBILE_SIDE_MENU_REDUCER: (state) => {
      state.showSideMenu = false;
    },
  },
});

export const {
  UPDATE_MAIN_ITEM_REDUCER,
  UPDATE_MAIN_TITLE_REDUCER,
  CLOSE_MOBILE_SIDE_MENU_REDUCER,
} = MainReducer.actions;
export default MainReducer.reducer;
