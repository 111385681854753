import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import TEST_IDS from "../../constants/testIds";
import { BRANDS_ROUTE } from "../../settings/routes";
import getCardSvgBackground from "../../utils/getCardSvgBackground";
import DashboardCardContainerComponent from "../Containers/DashboardCardContainerComponent";
import GradientComponent from "../Gradient/GradientComponent";
import DirectIconItemComponent from "../Icons/DirectIconItemComponent";
import BrandIconImageComponent from "../Images/BrandIconImageComponent";
import RedirectContainerComponent from "../Redirect/RedirectContainerComponent";

const BrandCardComponent = ({
  slug,
  brandIcon,
  previewImage,
  name,
  index,
  changeIconColor,
  url,
  to = BRANDS_ROUTE,
  internalLink,
}) => {
  // Adds each brand background in turn, if there are more brands than standard backgrounds, then again starts the order from zero from the background list
  const Icon = useMemo(() => getCardSvgBackground(index), [index]);
  const navigate = useNavigate();

  const handleNavigate = useCallback(() => {
    navigate(`${to}/${slug}`);
  }, [navigate, to, slug]);

  return (
    <DashboardCardContainerComponent>
      <RedirectContainerComponent
        internalLink={internalLink}
        url={url}
        className="w-full min-w-full"
      >
        <section
          className={`group block w-full sm:min-w-full h-0 pb-[45%] min-h-[150px]  2xl:pb-[35%] relative rounded-medium overflow-hidden cursor-pointer`}
          onClick={handleNavigate}
        >
          {previewImage ? (
            <img
              src={previewImage}
              className="absolute top-0 bottom-0 left-0 right-0 object-cover object-center h-full w-full"
              data-testid={TEST_IDS.BRAND_PREVIEW_IMAGE}
            />
          ) : (
            <Icon
              className="absolute top-0 w-[200%] sm:w-[150%] lg:w-[180%] xl:w-[150%] h-auto"
              data-testid={TEST_IDS.BRAND_CARD_ICON}
            />
          )}
          <GradientComponent className="rounded-medium" />
          <div className="flex flex-col justify-between absolute inset-y-5 inset-x-4 2xl:inset-y-6 2xl:inset-x-5 pointer-events-none z-20 items-start">
            <BrandIconImageComponent
              image={brandIcon}
              title={name}
              changeColor={changeIconColor}
            />
            <div className="text-white overflow-hidden flex items-center">
              <h5 className="text-md sm:text-lg md:text-xl font-bold line-clamp-2 3xl:line-clamp-3 duration-default text-shadow-default mr-2">
                {name}
              </h5>
              <DirectIconItemComponent color={"white"} show={url} />
            </div>
          </div>
        </section>
      </RedirectContainerComponent>
    </DashboardCardContainerComponent>
  );
};

export default BrandCardComponent;
