import React, { useMemo } from "react";

import DownloadResourceIcon from "../../assets/icons/component-icons/resource-card/DownloadResourceIcon";
import { RESOURCE_DOWNLOAD_TYPES } from "../../constants/singleItemTypes";
import generateSingleItemUrl from "../../utils/generateSingleItemUrl";
import DownloadLinkResourceComponent from "../DownloadLinkResource/DownloadLinkResourceComponent";
import IconResourceButtonComponent from "../Icons/IconResourceButtonComponent";
import TooltipComponent from "../Tooltip/TooltipComponent";

const DownloadResourceButtonComponent = ({
  id,
  contentType,
  resourceData,
  defaultShow,
}) => {
  const { url } = useMemo(
    () => generateSingleItemUrl({ id, contentType, resourceData }),
    [id, contentType],
  );
  // defaultShow - The download button can be hidden by the administrator globally or for a specific resource / challenge.
  const showItem = useMemo(
    () => defaultShow && RESOURCE_DOWNLOAD_TYPES.includes(contentType),
    [contentType, defaultShow],
  );

  if (!showItem) {
    return null;
  }
  return (
    <TooltipComponent title="Download">
      <DownloadLinkResourceComponent
        url={url}
        type={contentType}
        name={resourceData?.name}
      >
        <IconResourceButtonComponent Icon={DownloadResourceIcon} />
      </DownloadLinkResourceComponent>
    </TooltipComponent>
  );
};

export default React.memo(DownloadResourceButtonComponent);
