import Color from "color";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import {
  DEFAULT_BRANDS_NAME,
  DEFAULT_CONTESTS_NAME,
  DEFAULT_IMPORTANT_LINKS_NAME,
} from "../constants/constants";
import { BRANDS_NAME_STORAGE } from "../constants/storageNames";
import { endpoints } from "../settings/endpoints";
import api from "../utils/api";
import { GET } from "../utils/apiRequests";
import changeToDefaultDomain from "../utils/changeToDefaultDomain";

import getGeneratedImgUrl from "../utils/getGeneratedImgUrl";
import { AuthContext } from "./AuthProvider";

export const CompanyDataContext = createContext({});

export const CompanyDataProvider = ({ children }) => {
  const [error, setError] = useState(null);
  const [data, setData] = useState({
    projectLogo: "",
    color: "",
    name: "",
    logoUrl: "",
    loginPageTitle: "",
    shortDescription: "",
    footerHTML: "",
    favicon: "",
    showContests: false,
    customBrandName: DEFAULT_BRANDS_NAME,
    customImportantLinksName: DEFAULT_IMPORTANT_LINKS_NAME,
    customContestsName: DEFAULT_CONTESTS_NAME,
  });

  const authContext = useContext(AuthContext);

  const [brands, setBrands] = useState({});
  const [brandsList, setBrandsList] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [brandsLoading, setBrandsLoading] = useState(true);

  const [customIcons, setCustomIcons] = useState({});

  const updateBrandRoute = useCallback((name) => {
    const formattedName = name.toLowerCase().replace(/[^a-zA-Z]/g, "");
    localStorage.setItem(BRANDS_NAME_STORAGE, formattedName);
  }, []);

  const getImage = useCallback((item, objectKey) => {
    if (
      !item ||
      !objectKey ||
      !item[objectKey] ||
      !item[objectKey]?.images?.length
    ) {
      return "";
    }

    return changeToDefaultDomain(
      item[objectKey]?.images[item[objectKey]?.mainImage]?.original,
    );
  }, []);

  const handleUpdateSideMenu = useCallback((menuIcons) => {
    if (!menuIcons) {
      return;
    }

    const updatedMenuIcons = {};

    Object.keys(menuIcons).forEach((key) => {
      const icon = {
        ...menuIcons[key],
      };
      icon.src = getGeneratedImgUrl(icon);
      updatedMenuIcons[key] = icon;
    });

    setCustomIcons(updatedMenuIcons);
  }, []);

  // Fetches the default platform data
  const fetchPrimaryData = useCallback(async () => {
    await api
      .get(`${endpoints.companies}/${process.env.REACT_APP_COMPANY_ID}`)
      .then((response) => {
        const dataItem = response.data;
        const { brand, important_links, contest } =
          dataItem?.custom_names || {};

        const customBrandName = brand || DEFAULT_BRANDS_NAME;
        const customImportantLinksName =
          important_links || DEFAULT_IMPORTANT_LINKS_NAME;
        const customContestsName = contest || DEFAULT_CONTESTS_NAME;
        const favicon = changeToDefaultDomain(
          dataItem.favicon?.images[dataItem.favicon?.mainImage].thumbnail,
        );
        handleUpdateSideMenu(dataItem.left_menu_icons);
        updateBrandRoute(customBrandName);
        setData({
          projectLogo: dataItem.project_logo,
          color: dataItem.company_primary_color,
          name: dataItem.company_name,
          layout: dataItem.layout,
          showContests: dataItem.show_contests,
          importantLinks: dataItem.important_links,
          logoUrl: getImage(dataItem, "company_logo"),
          staticBackground: getImage(dataItem, "static_background"),
          loginPageTitle: dataItem.login_page_title,
          shortDescription: dataItem.short_description,
          footerHTML: dataItem.footer_html,
          assessments: dataItem.assessments,
          customImportantLinksName,
          customContestsName,
          myCustomContestsName: `My ${customContestsName}`,
          customBrandName,
          favicon,
        });
      })
      .catch((error) => {
        setError(error);
      });
  }, []);

  const fetchBrandsData = useCallback(async () => {
    try {
      setBrandsLoading(true);
      const response = await GET(endpoints.dict.brands);
      const brandsData = response.data;
      if (!brandsData.length) {
        return;
      }

      const brands = Object.keys(brandsData).reduce(
        (o, key) => ({ ...o, [brandsData[key]["slug"]]: brandsData[key] }),
        {},
      );
      setBrandsList(brandsData);
      setBrands(brands);
      setBrandsLoading(false);
    } catch (error) {
      setError(error);
    }
  }, []);

  // Sets the primary colors of the platform
  const handleUpdatePlatformColors = useCallback(async () => {
    setIsLoading(true);
    const root = document.documentElement;
    root.style.setProperty("--primary-color", data.color);
    root.style.setProperty("--primary-color-extra-light", `${data.color}20`);
    await root.style.setProperty(
      "--primary-color-light",
      Color(data.color).lighten(0.25),
    );
    await root.style.setProperty(
      "--primary-color-dark",
      Color(data.color).darken(0.25),
    );
    setIsLoading(false);
  }, [data]);

  useEffect(() => {
    const faviconItem = document.querySelector("#favicon");
    if (faviconItem?.href) {
      faviconItem.href = data.favicon;
    }
    if (data?.color) {
      handleUpdatePlatformColors();
    }
  }, [data]);

  useEffect(() => {
    if (!authContext.isLoading) {
      fetchBrandsData();
    }
  }, [authContext.isLoading]);

  useEffect(() => {
    fetchPrimaryData();
  }, []);

  return (
    <CompanyDataContext.Provider
      value={{
        data,
        brands,
        customIcons,
        isLoading,
        brandsList,
        brandsLoading,
        error,
      }}
    >
      {children}
    </CompanyDataContext.Provider>
  );
};

export default CompanyDataProvider;
