import * as React from "react";

const ViewResourceIcon = ({ className, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeMiterlimit: 10,
    }}
    viewBox="0 0 17 13"
    className={className}
  >
    <path
      d="M.5 6.5S3.5 1 8 1s7.5 5.5 7.5 5.5S12.5 12 8 12 .5 6.5.5 6.5Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: color,
        strokeWidth: "1.5px",
      }}
      transform="translate(.25 -.25)"
    />
    <path
      d="M8 9a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: color,
        strokeWidth: "1.5px",
      }}
      transform="translate(.25 -.25)"
    />
  </svg>
);
export default ViewResourceIcon;
