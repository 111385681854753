import { createSlice } from "@reduxjs/toolkit";
import { FALLBACK_VIEW_TYPE } from "../../../constants/types/switchView/viewType";

const initialState = {};

export default createSlice({
  name: "ViewType",
  initialState,
  reducers: {
    switchViewType: (state, { payload }) => {
      const { page, view } = payload;

      if (!state[page.type]) {
        state[page.type] = {};
      }

      state[page.type][page.nameSlug] = view.type || FALLBACK_VIEW_TYPE;
    },
  },
});
