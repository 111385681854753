import React from "react";
import { useSelector } from "react-redux";

import TEST_IDS from "../../constants/testIds";
import ButtonComponent from "../Inputs/ButtonComponent";
import ValidationErrorComponent from "../ValidationError/ValidationErrorComponent";

const AuthorizationContainerComponent = ({
  submitDisabled,
  children,
  title,
  subtitle,
  formStep,
  submitButton = {
    title: "",
    onSubmit: () => {},
  },
  helpButton = {
    title: "",
    onClick: () => {},
  },
}) => {
  const { data, error, loading, forgotPasswordLoading } = useSelector(
    (state) => state.LoginReducer,
  );

  if (formStep !== data.currentFormStep) {
    return null;
  }

  return (
    <>
      <h2 className="text-3xl font-bold">{title}</h2>
      <h3 className="text-sm mt-3">{subtitle}</h3>
      <form
        className="mt-8 flex flex-col"
        onSubmit={submitButton.onSubmit}
        data-testid={TEST_IDS.FORM}
      >
        {children}
        <input type="submit" className="hidden" />
        {error && <ValidationErrorComponent message={error} />}
        <div className="mt-6">
          <ButtonComponent
            text={submitButton.title}
            fullWidth
            onClick={submitButton.onSubmit}
            loading={loading}
            disabled={forgotPasswordLoading || submitDisabled}
            testId={TEST_IDS.BUTTONS.SUBMIT}
          />
        </div>
        <div className="mt-4 flex justify-center">
          <ButtonComponent
            text={helpButton.title}
            type="tertiary"
            size="small"
            disabled={loading}
            loading={forgotPasswordLoading}
            testId={TEST_IDS.BUTTONS.FORGOT_PASSWORD}
            onClick={helpButton.onClick}
          />
        </div>
      </form>
    </>
  );
};

export default AuthorizationContainerComponent;
