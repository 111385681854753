// import axios from "axios";
import { endpoints } from "../settings/endpoints";
import { GET } from "./apiRequests";
import createCookies from "./createCookies";

const setInitialCookies = async () => {
  try {
    // this cookies allow to show images on platform
    await GET(
      `${process.env.REACT_APP_ASSETS_DOMAIN}${endpoints.cookies.assets}`,
      {},
      {
        withCredentials: true,
      },
    );

    // this cookies allow to show pi possible on platform;
    const response = await GET(endpoints.cookies.frontend);
    const data = response.data;
    createCookies(data.cookies, data.expires);
  } catch (error) {
    console.log(error);
  }
};

export default setInitialCookies;
