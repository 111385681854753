const Type = {
  LIST: "list",
  CARDS: "cards",
};

const Name = {
  LIST: "List View",
  CARDS: "Tiles View",
};

export const FALLBACK_VIEW_TYPE = Type.CARDS;

export { Type, Name };
