import PropTypes from "prop-types";
import React, { useMemo } from "react";

const SingleItemIconComponent = ({ contentType, className = "" }) => {
  const typeImage = useMemo(() => {
    const fileName = contentType || "other";
    return require(`../../assets/icons/resource-types/${fileName}.svg`);
  }, [contentType]);

  return (
    <img
      src={typeImage}
      alt={contentType}
      className={`h-4 object-contain ${className}`}
    />
  );
};

SingleItemIconComponent.propTypes = {
  contentType: PropTypes.string,
  className: PropTypes.string,
};
export default React.memo(SingleItemIconComponent);
