import { Auth } from "aws-amplify";
import updateAccessToken from "../localStorage/token/updateAccessToken";
import getUserSessionRefreshInterval from "./getUserSessionRefreshInterval";

async function doRefreshSession() {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();

    cognitoUser.refreshSession(
      currentSession.refreshToken,
      (error, session) => {
        updateAccessToken(session?.accessToken.jwtToken);
      },
    );
  } catch (error) {
    console.log("Unable to refresh user session", { error });
  }
}

export default function refreshUserSession() {
  const sessionRefreshInterval = getUserSessionRefreshInterval();

  if (!sessionRefreshInterval) {
    console.log("The user session refresh interval is unknown");
    return;
  }

  return setInterval(doRefreshSession, sessionRefreshInterval);
}
