import React, { useMemo } from "react";
import TEST_IDS from "../../../constants/testIds";
import { useGetCategoriesQuery } from "../../../store/services";
import SpinnerComponent from "../../Spinner/SpinnerComponent";
import ShortSideMenuComponent from "./ShortSideMenuComponent";
import ThirdLevelMenuItemComponent from "./ThirdLevelMenuItemComponent";

// this level of the side menu is not used in the short version of menu
// this component is used to show the third level of the side menu
// this component is the last level of the side menu

const ThirdLevelMenuComponent = ({ brandId, showMenu, parentSlug }) => {
  const { data: categories, isFetching } = useGetCategoriesQuery({
    brand: brandId,
  });

  const canShowMenu = useMemo(
    () => categories?.length && showMenu,
    [categories, showMenu],
  );

  if (!canShowMenu) {
    return null;
  }

  if (isFetching) {
    return (
      <section className="py-2 flex justify-center">
        <SpinnerComponent mode="dark" size="small" />
      </section>
    );
  }

  return (
    <ShortSideMenuComponent>
      <ol
        className="bg-gray-light"
        data-testid={TEST_IDS.SIDE_MENU.THIRD_LEVEL_ITEM}
      >
        {categories.map((item, id) => (
          <ThirdLevelMenuItemComponent
            key={id}
            categoryName={item?.category_name}
            iconSrc={item?.icon}
            parentSlug={parentSlug}
            slug={item?.slug}
            url={item?.url}
            internalLink={item?.internal_link}
            path={item?.path}
          />
        ))}
      </ol>
    </ShortSideMenuComponent>
  );
};

export default React.memo(ThirdLevelMenuComponent);
