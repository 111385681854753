import PropTypes from "prop-types";
import React, { useContext } from "react";
import TEST_IDS from "../../../constants/testIds";
import { CompanyDataContext } from "../../../contexts/CompanyDataProvider";
import useGetCustomIcon from "../../../hooks/useGetCustomIcon";

const MainSideMenuIconComponent = ({ iconKey, name }) => {
  const { data } = useContext(CompanyDataContext);
  const [src, Icon] = useGetCustomIcon(iconKey, true);

  if (src) {
    return (
      <img
        src={src}
        className="w-4 h-4"
        alt={name}
        data-testid={TEST_IDS.SIDE_MENU.MAIN_ICON_IMAGE}
      />
    );
  }

  return (
    <Icon
      color={data?.color}
      className="h-4 w-4"
      data-testid={TEST_IDS.SIDE_MENU.MAIN_ICON}
    />
  );
};

MainSideMenuIconComponent.propTypes = {
  iconKey: PropTypes.string,
  name: PropTypes.string,
};
export default React.memo(MainSideMenuIconComponent);
