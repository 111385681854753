import React, { useEffect, useMemo } from "react";

import ValidationErrorComponent from "../ValidationError/ValidationErrorComponent";

const FormValidationComponent = ({
  onUpdateSubmitStatus = () => {},
  value,
  validationParameters = [],
}) => {
  const error = useMemo(() => {
    if (!value || !validationParameters.length) {
      return "";
    }

    const currentError = validationParameters.find(
      (validation) => !validation.parameter.test(value),
    );

    if (currentError) {
      onUpdateSubmitStatus(true);
      return currentError.title;
    }

    return "";
  }, [value, validationParameters]);

  useEffect(() => {
    if (!value || !error) {
      onUpdateSubmitStatus(false);
    }
  }, [value, error]);

  if (!value || !error) {
    return null;
  }

  return <ValidationErrorComponent message={error} />;
};

export default React.memo(FormValidationComponent);
