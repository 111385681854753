import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useWindowSize } from "react-use";
import { UPDATE_MAIN_ITEM_REDUCER } from "../store/reducers/MainReducer";

// Sends the height of an element to the mainReducer.
// The height of this element and other elements is then used to calculate the height of the resource and challenge containers.

const useUpdateItemRefHeight = (itemRef, itemId) => {
  const { width } = useWindowSize();
  const mainData = useSelector((state) => state.MainReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    const height = itemRef?.current?.clientHeight;
    const previusItemHeight = mainData[itemId];

    if (previusItemHeight === height) {
      return;
    }

    dispatch(
      UPDATE_MAIN_ITEM_REDUCER({
        id: itemId,
        value: height || 0,
      }),
    );
  }, [width, itemId]);
};

export default useUpdateItemRefHeight;
