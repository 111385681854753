import React, { useContext, useMemo } from "react";

import { CompanyDataContext } from "../../contexts/CompanyDataProvider";
import BrandsListComponent from "../BrandsList/BrandsListComponent";

import CardListComponent from "./CardListComponent";

// The component displays a tab with a list of all brands
const BrandCardListComponent = ({ maxLength, containerClassName = "" }) => {
  const { brandsList, brandsLoading, data } = useContext(CompanyDataContext);

  const updatedList = useMemo(() => {
    if (!brandsList?.length) {
      return [];
    }

    if (maxLength && maxLength < brandsList.length) {
      const updatedList = [...brandsList];
      updatedList.length = maxLength;
      return updatedList;
    }

    return brandsList;
  }, [maxLength, brandsList]);

  return (
    <div className={containerClassName}>
      <CardListComponent
        title={data.customBrandName}
        withArrow={false}
        show={!!updatedList?.length}
        loading={brandsLoading}
      >
        <BrandsListComponent list={updatedList} />
      </CardListComponent>
    </div>
  );
};

export default React.memo(BrandCardListComponent);
