import { useMemo } from "react";
import { useWindowSize } from "react-use";
import {
  RESPONSIVE_BREAKPOINTS,
  RESPONSIVE_HEIGHT_BREAKPOINTS,
} from "../constants/constants";

const useShowMobile = () => {
  const { width, height } = useWindowSize();

  const showMobile = useMemo(() => {
    // for landscape screen, we need to check height
    if (width > height) {
      return height < RESPONSIVE_HEIGHT_BREAKPOINTS.SM;
    }

    // for vertical screen, we need to check width
    return width < RESPONSIVE_BREAKPOINTS.SM;
  }, [height, width]);

  return showMobile;
};

export default useShowMobile;
