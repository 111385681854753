import * as React from "react";
const CrossIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
      strokeLinejoin: "round",
    }}
    viewBox="0 0 23 23"
    {...props}
  >
    <path
      d="m.181 0 21.245 21.246M21.426 0 .181 21.246"
      style={{
        fill: "none",
        fillRule: "nonzero",
        strokeWidth: "1.5px",
      }}
      transform="translate(.57 .75)"
    />
  </svg>
);
export default CrossIcon;
