import * as React from "react";
import { CompanyDataContext } from "../../../../contexts/CompanyDataProvider";
const SideMenuCloseIcon = (props) => {
  const companyContextData = React.useContext(CompanyDataContext);
  const { color } = companyContextData.data;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      viewBox="0 0 17 17"
      {...props}
    >
      <path
        d="M.875 10.5h19.25"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: color,
          strokeWidth: 2,
        }}
        transform="rotate(-45 6.49 12.286)"
      />
      <path
        d="M.875 2.625h19.25"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: color,
          strokeWidth: 2,
        }}
        transform="rotate(45 2.603 2.98)"
      />
    </svg>
  );
};
export default SideMenuCloseIcon;
