import * as React from "react";

const BookmarkedIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
      strokeLinejoin: "round",
    }}
    viewBox="0 0 13 17"
    {...props}
  >
    <path
      d="m1.5 15.5 5.5-4 5.5 4V2A1.5 1.5 0 0 0 11 .5H3A1.5 1.5 0 0 0 1.5 2v13.5Z"
      className="fill-dark hover:fill-primary"
      style={{
        fillRule: "nonzero",
        stroke: "none",
        strokeWidth: "1.5px",
      }}
      transform="translate(-.75 .25)"
    />
    <path
      d="M7 4.5v4M5 6.5h4"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#fff",
        strokeWidth: "1.5px",
      }}
      transform="translate(-.75 .25)"
    />
  </svg>
);
export default BookmarkedIcon;
