import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { LIST_PER_PAGE_LAZY_LOADING } from "../../constants/constants";
import PageLoaderComponent from "../Loaders/PageLoaderComponent";

const InfiniteScrollComponent = ({
  children,
  length = 0,
  totalCount,
  disableInfiniteScroll,
  loading,
  updatePaginationParameters = () => {},
}) => {
  const observerTarget = useRef(null);
  const hasMore = useMemo(() => totalCount > length, [totalCount, length]);

  const handleLazyLoading = useCallback(() => {
    if (!totalCount || parseInt(totalCount) === parseInt(length)) {
      return;
    }
    const nextEnd = length + LIST_PER_PAGE_LAZY_LOADING;
    const end = totalCount > nextEnd ? nextEnd : totalCount;
    updatePaginationParameters(length, end);
  }, [totalCount, length, updatePaginationParameters]);

  useEffect(() => {
    if (disableInfiniteScroll) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        if (!entries[0]?.isIntersecting || !hasMore) {
          return;
        }
        handleLazyLoading();
      },
      { root: null, rootMargin: "0px", threshold: 1.0 },
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, totalCount, length, disableInfiniteScroll]);

  return (
    <>
      {children}
      {hasMore ? (
        <div ref={observerTarget} className="min-h-[1px]"></div>
      ) : null}
      <PageLoaderComponent loading={loading} />
    </>
  );
};

export default React.memo(InfiniteScrollComponent);
