import React from "react";
import ButtonComponent from "../../components/Inputs/ButtonComponent";
import { AUTHENTICATION_ERROR_IMAGE_BASE64 } from "../../constants/constants";
import TEST_IDS from "../../constants/testIds";
import CommonErrorPage from "./components/CommonErrorPage";

function GoToLogin() {
  function handleGoToLoginClick(event) {
    event.preventDefault();
    window.location.reload();
  }

  return (
    <div className="flex justify-center">
      <ButtonComponent
        testId={TEST_IDS.MISSING_ACCESS_TOKEN_PAGE.CTA}
        text="Go to login page"
        onClick={handleGoToLoginClick}
        type="primary"
      />
    </div>
  );
}

export default function MissingAccessTokenPage() {
  const image = {
    src: AUTHENTICATION_ERROR_IMAGE_BASE64,
    alt: "Network issue",
  };

  const description = {
    header: "You are not authenticated!",
    texts: ["It looks like we ran into an issue. Please log in again!"],
  };

  const testIds = {
    container: TEST_IDS.MISSING_ACCESS_TOKEN_PAGE.CONTAINER,
    wrapper: TEST_IDS.MISSING_ACCESS_TOKEN_PAGE.CONTENT_WRAPPER,
  };

  return (
    <CommonErrorPage
      title="Authentication error"
      image={image}
      description={description}
      belowDescription={<GoToLogin />}
      testIds={testIds}
    />
  );
}
