import api from "./api";

export const GET = async (url, params = {}, customParameters = {}) => {
  return api.get(url, {
    params: params,
    ...customParameters,
  });
};

// using only with Redux services
export const SERVICES_GET = async ({
  url,
  params = {},
  customParameters = {},
}) => {
  return await GET(url, params, customParameters);
};

export const POST = async (url, body, params = {}) => {
  return api.post(url, body, {
    params,
  });
};

export const NOT_AUTH_POST = async (url, body, params = {}) => {
  return api.post(url, body, {
    params,
  });
};

export const DELETE = async (url, params = {}) => {
  return api.delete(url, {
    params,
  });
};

export const PUT = async (url, body) => {
  return api.put(url, body);
};

export const NOT_AUTH_PUT = async (url, body) => {
  delete api.defaults?.headers["Authorization"];
  return api.put(url, body, {
    addAuthorizationHeader: false,
  });
};

export const PATCH = async (url, body) => {
  return api.patch(url, body);
};
