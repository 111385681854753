import React from "react";
import { useSelector } from "react-redux";
import TEST_IDS from "../../constants/testIds";

// This component is the background of the platform. The background is gray by default.
// The administrator can customize the background by adding a background image for contests, milestones, challenges, and resources
const BackgroundComponent = () => {
  const { mainContainerStyle } = useSelector((state) => state.MainReducer);
  return (
    <section
      data-testid={TEST_IDS.BACKGROUND_CONTAINER}
      style={mainContainerStyle}
      className="bg-background-light fixed -z-1 top-0 left-0 right-0 bottom-0 h-screen w-screen"
    />
  );
};

export default React.memo(BackgroundComponent);
