// Names of default icons of the main parts of the platform
const CUSTOM_ICON_KEYS = {
  DASHBOARD: "dashboard",
  BRANDS: "brands",
  CONTESTS: "contests",
  NEWS: "news",
  MY_TEAM: "team",
  IMPORTANT_LINKS: "links",
  ADMIN_PANEL: "panel",
};

export default CUSTOM_ICON_KEYS;
