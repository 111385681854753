import { DEVICES } from "../constants/constants";
import isIpad from "./isIpad";

const getDevice = () => {
  const userAgent = navigator.userAgent;
  const checkIpad = isIpad();

  switch (true) {
    case checkIpad:
      return DEVICES.IPAD;
    case /(tablet|playbook|silk)|(android(?!.*mobi))/i.test(userAgent):
      return DEVICES.TABLET;
    case /iP(hone|od)/i.test(userAgent):
      return DEVICES.IPHONE;
    case /Mobile|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Dolfin|Skyfire|Zune/i.test(
      userAgent,
    ):
      return DEVICES.MOBILE;
    default:
      return DEVICES.DESKTOP;
  }
};

export default getDevice;
