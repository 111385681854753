import React, { useRef } from "react";
import { NAVBAR_HEIGHT_CLASS_NAMES } from "../../constants/classNamesConstants";
import useShortSideMenuClasses from "../../hooks/useShortSideMenuClasses";
import useUpdateItemRefHeight from "../../hooks/useUpdateItemRefHeight";
import SearchBoxComponent from "../SearchBox/SearchBoxComponent";
import CompanyLogoComponent from "../SideMenu/components/CompanyLogoComponent";
import ShortSideMenuComponent from "../SideMenu/components/ShortSideMenuComponent";
import SideMenuButtonComponent from "../SideMenuButton/SideMenuButtonComponent";
import UserProfileMenuComponent from "../UserProfileMenu/UserProfileMenuComponent";

const NavbarComponent = () => {
  const navbarRef = useRef();

  const containerClassNames = useShortSideMenuClasses(
    "sm:px-tablet 2xl:px-desktop",
    "pl-0 sm:pr-tablet 2xl:pr-desktop",
  );
  useUpdateItemRefHeight(navbarRef, "navbarHeight");

  return (
    <div
      ref={navbarRef}
      className={`sticky md:relative top-0  w-full bg-gray-500 ${NAVBAR_HEIGHT_CLASS_NAMES} flex items-center justify-between border-b border-gray bg-white shadow-navbar z-30 px-mobile ${containerClassNames}`}
    >
      <SideMenuButtonComponent />
      <ShortSideMenuComponent viceVersa>
        <CompanyLogoComponent />
      </ShortSideMenuComponent>
      <SearchBoxComponent />
      <UserProfileMenuComponent />
    </div>
  );
};

export default NavbarComponent;
