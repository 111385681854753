import React from "react";

const TeamIcon = ({ color, ...props }) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.625 5.5C5.625 4.46447 4.78553 3.625 3.75 3.625C2.71447 3.625 1.875 4.46447 1.875 5.5C1.875 6.53553 2.71447 7.375 3.75 7.375C4.78553 7.375 5.625 6.53553 5.625 5.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.375 18.625H1.875V14.875H0.625V11.125C0.625 10.7935 0.756696 10.4755 0.991117 10.2411C1.22554 10.0067 1.54348 9.875 1.875 9.875H3.125"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.375 5.5C14.375 6.53553 15.2145 7.375 16.25 7.375C17.2855 7.375 18.125 6.53553 18.125 5.5C18.125 4.46447 17.2855 3.625 16.25 3.625C15.2145 3.625 14.375 4.46447 14.375 5.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.625 18.625H18.125V14.875H19.375V11.125C19.375 10.7935 19.2433 10.4755 19.0089 10.2411C18.7745 10.0067 18.4565 9.875 18.125 9.875H16.875"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 3.625C12.5 2.24429 11.3807 1.125 10 1.125C8.61929 1.125 7.5 2.24429 7.5 3.625C7.5 5.00571 8.61929 6.125 10 6.125C11.3807 6.125 12.5 5.00571 12.5 3.625Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.125 19.875H6.875V14.875H5.625V11.125C5.625 10.462 5.88839 9.82607 6.35723 9.35723C6.82607 8.88839 7.46196 8.625 8.125 8.625H11.875C12.538 8.625 13.1739 8.88839 13.6428 9.35723C14.1116 9.82607 14.375 10.462 14.375 11.125V14.875H13.125V19.875Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TeamIcon;
