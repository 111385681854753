import * as React from "react";

import { BRAND_BACKGROUND_PATH_CLASS_NAMES } from "../../../constants/constants";

const BrandBackground4Icon = (props) => (
  <svg
    viewBox="0 0 332 123"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    {...props}
  >
    <path
      style={{
        fill: "url(#a)",
      }}
      d="M0 0h332v123H0z"
    />
    <path className={BRAND_BACKGROUND_PATH_CLASS_NAMES} d="M0 0h332v123H0z" />
    <path
      d="M332 99.555c-3.123.294-6.294.445-9.5.445-55.301 0-100.23-44.763-100.498-100H332v99.555Z"
      style={{
        fill: "#fff",
        fillOpacity: 0.19,
      }}
    />
    <path
      d="M0 50.055C21.783 22.037 55.802 4 94 4c65.678 0 119 53.322 119 119H0V50.055Z"
      style={{
        fill: "#fff",
        fillOpacity: 0.13,
      }}
    />
    <path
      d="M77.902 123c18.151-17.926 43.092-29 70.598-29s52.447 11.074 70.598 29H77.902Z"
      style={{
        fill: "#fff",
        fillOpacity: 0.19,
      }}
    />
    <defs>
      <linearGradient
        id="a"
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(-.396 83.0035 -83.0035 -.396 166.468 39.996)"
      >
        <stop
          offset={0}
          style={{
            stopColor: "white",
            stopOpacity: 0,
          }}
        />
        <stop
          offset={0.8}
          style={{
            stopColor: "black",
            stopOpacity: 0.31,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "black",
            stopOpacity: 0.31,
          }}
        />
      </linearGradient>
    </defs>
  </svg>
);

export default BrandBackground4Icon;
