import { DEFAULT_BRANDS_NAME } from "../constants/constants";
import { BRANDS_NAME_STORAGE } from "../constants/storageNames";

export const HOME_ROUTE = "/";

/* BRANDS/BRAND/CATEGORY/RESOURCE */
const BRANDS_ROUTE_INDICATOR = "/b";
export const BRANDS_ROUTE = `${BRANDS_ROUTE_INDICATOR}/${
  localStorage.getItem(BRANDS_NAME_STORAGE) || DEFAULT_BRANDS_NAME
}`;
export const BRAND_ROUTE = `${BRANDS_ROUTE_INDICATOR}/:customName/:brand`;
export const CATEGORY_ROUTE = `${BRAND_ROUTE}/:category`;

export const RESOURCES_ROUTE = `/resources`;
export const SINGLE_RESOURCE_ROUTE = `${RESOURCES_ROUTE}/:slug`;
export const CURRICULUMS_SINGLE_RESOURCE_ROUTE = `${RESOURCES_ROUTE}/:slug/:assessment`;
/* ************************* */

/* RESOURCES LISTS */
export const BOOKMARKS_ROUTE = "/bookmarks";
export const LATEST_ROUTE = "/latest";
/* ************************* */

/* NEWS/NEWS_ITEM */
export const NEWS_ROUTE = "/news";
export const NEWS_ITEM_ROUTE = `${NEWS_ROUTE}/:id`;
/* ************************* */

/* CONTESTS/CONTEST/MILESTONE/CHALLENGE/SCORMS */
export const CONTESTS_ROUTE = "/contests";
export const SINGLE_CONTEST_ROUTE = `${CONTESTS_ROUTE}/:slug`;

export const MILESTONES_ROUTE = `/milestones`;
export const MILESTONE_ROUTE = `${MILESTONES_ROUTE}/:slug`;

export const CHALLENGES_ROUTE = "/challenges";
export const SINGLE_CHALLENGE_ROUTE = `${CHALLENGES_ROUTE}/:slug`;

export const IFRAME_LAYOUT_ROUTE = "/:itemType/:slug";

export const HTMLS_ROUTE = "/html";
export const SINGLE_HTML_ROUTE = `${HTMLS_ROUTE}${IFRAME_LAYOUT_ROUTE}`;

export const SCORMS_ROUTE = "/scorms";
export const SINGLE_SCORM_ROUTE = `${SCORMS_ROUTE}${IFRAME_LAYOUT_ROUTE}`;

export const APPLICATIONS_ROUTE = "/applications";
export const SINGLE_APPLICATION_ROUTE = `${APPLICATIONS_ROUTE}${IFRAME_LAYOUT_ROUTE}`;
/* ************************* */

/* GAME QUIZ  */
export const GAMES_QUIZ_ROUTE = `/games`;
export const SINGLE_GAME_QUIZ_ROUTE = `${GAMES_QUIZ_ROUTE}/:itemType/:resourceId/:gameId`;
/* ************************* */

/* MY TEAM/MY LEARNING AND DEVELOPMENT || USER LEARNING AND DEVELOPMENT/ASSIGNED CURRICULUMS || PERSONALIZED CURRICULUMS/CURRICULUM */
export const MY_TEAM_ROUTE = "/my-team";

export const MY_PERFORMANCE_ROUTE = "/my-learning-&-development";
export const USER_PERFORMANCE_ROUTE = `${MY_PERFORMANCE_ROUTE}/:user`;

export const PERSONALIZED_LEARNING_ASSESSMENT_ROUTE =
  "/personalized-learning-assessment";

export const CURRICULUMS_ROUTE = "/curriculums";
export const CURRICULUMS_USER_ROUTE = `${CURRICULUMS_ROUTE}/:type/:user/:slug`;
export const PERSONALIZED_CURRICULUMS_ROUTE = `${CURRICULUMS_ROUTE}/:type/:user`;
/* ************************* */

/* SEARCH RESULTS */
export const SEARCH_RESULTS_ROUTE = "/search";
/* ************************* */

/* SETTINGS */
export const SETTINGS_ROUTE = "/settings";
/* ************************* */

/* STATIC */
export const LOGIN_ROUTE = "/login";
export const LOGOUT_ROUTE = "/logout";
/* ************************* */

export const NOT_FOUND_ROUTE = "/404";
