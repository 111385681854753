import * as React from "react";

import { BRAND_BACKGROUND_PATH_CLASS_NAMES } from "../../../constants/constants";

const BrandBackground2Icon = (props) => (
  <svg
    viewBox="0 0 332 123"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    {...props}
  >
    <path
      style={{
        fill: "url(#a)",
      }}
      d="M0 0h332v123H0z"
    />
    <path className={BRAND_BACKGROUND_PATH_CLASS_NAMES} d="M0 0h332v123H0z" />
    <circle
      cx={249}
      cy={11}
      r={119}
      style={{
        fill: "#fff",
        fillOpacity: 0.13,
      }}
      transform="rotate(-90 249 11)"
    />
    <circle
      cx={130.5}
      cy={126.5}
      r={100.5}
      style={{
        fill: "#fff",
        fillOpacity: 0.19,
      }}
      transform="matrix(0 -1 1 0 4 257)"
    />
    <defs>
      <linearGradient
        id="a"
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(-.396 83.0035 -83.0035 -.396 166.468 39.996)"
      >
        <stop
          offset={0}
          style={{
            stopColor: "white",
            stopOpacity: 0,
          }}
        />
        <stop
          offset={0.8}
          style={{
            stopColor: "black",
            stopOpacity: 0.31,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "black",
            stopOpacity: 0.31,
          }}
        />
      </linearGradient>
    </defs>
  </svg>
);

export default BrandBackground2Icon;
