import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { endpoints } from "../../settings/endpoints";
import { GET } from "../../utils/apiRequests";

export const FETCH_CHALLENGES_REDUCER = createAsyncThunk(
  "ChallengesReducer/fetchChallengesReducer",
  async ({ slug, params, isCards }, { rejectWithValue }) => {
    try {
      let response = await GET(
        `${endpoints.contest.milestones}/${slug}${endpoints.contest.challenges}`,
        params,
      );
      return { ...response.data, isCards };
    } catch (e) {
      if (axios.isAxiosError(e)) {
        return rejectWithValue(e?.response?.data);
      }
    }
  },
);

const initialData = {
  error: null,
  data: {
    meta: {},
    list: [],
  },
  fetching: false,
  loading: false,
};

export const ChallengesReducer = createSlice({
  name: "ChallengesReducer",
  initialState: { ...initialData },
  reducers: {
    RUN_CHALLENGES_LOADING_REDUCER: (state) => {
      state.fetching = false;
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(FETCH_CHALLENGES_REDUCER.pending, (state) => {
      state.error = null;
      if (state?.data?.meta?.end) {
        state.fetching = true;
        return;
      }
      state.loading = true;
    });
    builder.addCase(
      FETCH_CHALLENGES_REDUCER.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.fetching = false;
        state.data.meta = payload.meta;
        const updatedData = payload?.data;

        if (payload.isCards && payload?.meta?.start > 0) {
          state.data.list = [...state.data.list, ...updatedData];
          return;
        }
        state.data.list = updatedData;
      },
    );
    builder.addCase(FETCH_CHALLENGES_REDUCER.rejected, (state, { payload }) => {
      state.loading = false;
      state.fetching = false;
      state.error = payload || {};
    });
  },
});

export const { RUN_CHALLENGES_LOADING_REDUCER } = ChallengesReducer.actions;
export default ChallengesReducer.reducer;
