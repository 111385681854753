import React, { useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import TEST_IDS from "../../../constants/testIds";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import { UPDATE_MAIN_ITEM_REDUCER } from "../../../store/reducers/MainReducer";
import SideMenuComponent from "../SideMenuComponent";

const MobileSideMenuComponent = () => {
  const dispatch = useDispatch();
  const { showSideMenu } = useSelector((state) => state.MainReducer);
  const menu = useRef();
  const isActiveMenu = useMemo(
    () => (showSideMenu ? "opacity-1" : "opacity-0 pointer-events-none"),
    [showSideMenu],
  );

  useOnClickOutside(menu, () => {
    dispatch(
      UPDATE_MAIN_ITEM_REDUCER({
        id: "showSideMenu",
        value: false,
      }),
    );
  });

  return (
    <div
      data-testid={TEST_IDS.MOBILE_MENU_CONTAINER}
      className={`block lg:hidden duration-default fixed z-50 bg-white h-full w-full md:w-1/2 ${isActiveMenu} `}
      ref={menu}
    >
      <SideMenuComponent />
    </div>
  );
};

export default React.memo(MobileSideMenuComponent);
