import React, { useMemo } from "react";
import HelpIcon from "../../../assets/icons/component-icons/side-menu/HelpIcon";
import getContactSupportMail from "../../../utils/getContactSupportMail";
import HeaderIconContainerComponent from "../../Containers/HeaderIconContainerComponent";

const CustomerServiceComponent = () => {
  const mailHref = useMemo(getContactSupportMail, []);

  return (
    <HeaderIconContainerComponent title="Contact support">
      <a href={mailHref} target="_blank" rel="noreferrer">
        <HelpIcon className="w-full" />
      </a>
    </HeaderIconContainerComponent>
  );
};

export default React.memo(CustomerServiceComponent);
