import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import SingleItemCardExpirationDateComponent from "../../SingleItemCard/SingleItemCardExpirationDateComponent";
import SingleItemIconComponent from "../../SingleItemIcon/SingleItemIconComponent";

export default function IconJobCodeAndExpirationDate({
  contentType,
  className,
}) {
  return (
    <div className={classNames("flex items-center gap-2", className)}>
      <SingleItemIconComponent contentType={contentType} />
      <SingleItemCardExpirationDateComponent />
    </div>
  );
}

IconJobCodeAndExpirationDate.propTypes = {
  contentType: PropTypes.string,
  className: PropTypes.string,
};
