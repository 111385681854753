import React, { useCallback, useMemo } from "react";

import { RESOURCE_TYPES } from "../../constants/singleItemTypes";
import TEST_IDS from "../../constants/testIds";

const DownloadLinkResourceComponent = ({
  children,
  title,
  url,
  type,
  onClick = () => {},
  name,
}) => {
  const updatedTitle = useMemo(() => title && `Download ${title}`, [title]);
  const updatedUrl = useMemo(() => {
    if (type === RESOURCE_TYPES.TEXT) {
      return url;
    }

    return `${url}?response-content-disposition=attachment`;
  }, [url, type]);

  const handleDownload = useCallback(
    async (event) => {
      // Required to avoid opening a page with the resource details when clicking on the icon on the Resources List
      event.stopPropagation();

      await onClick();
      const link = document.createElement("a");
      link.setAttribute("download", name);
      link.href = updatedUrl;
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    [updatedUrl, onClick, name],
  );

  return (
    <div
      href={updatedUrl}
      onClick={handleDownload}
      title={updatedTitle}
      data-testid={TEST_IDS.BUTTONS.DOWNLOAD}
    >
      {children}
    </div>
  );
};

export default React.memo(DownloadLinkResourceComponent);
