import { Auth } from "aws-amplify";
import axios from "axios";
import { StatusCodes } from "http-status-codes";
import authorizationErrors from "../constants/api/authorizationErrors";
import axiosError from "../constants/axios/axiosError";
import { networkError } from "../store/reducers/error/actions";
import { sessionExpired } from "../store/reducers/security/actions";
import extractErrorMessage from "./extractErrorMessage";
import getAccessToken from "./localStorage/token/getAccessToken";

let store;

const getAuthToken = async () => {
  return await Auth.currentSession().then((res) =>
    res.getAccessToken().getJwtToken(),
  );
};

export const setStore = (injectedStore) => {
  store = injectedStore;
};

const setAuthorizationHeader = async (config) => {
  try {
    config.headers.Authorization = await getAuthToken();
  } catch (error) {
    handleAuthorizationError(error);
  }
};

const handleAuthorizationError = (error) => {
  const errorMessage = extractErrorMessage(error);

  // Oops, unsupported authorization error occurred
  if (!isAuthorizationError(errorMessage)) {
    console.log("Cannot get authorization token because of: " + errorMessage);
  }

  store.dispatch(sessionExpired());
};

const isAuthorizationError = (error) => {
  if (!error) {
    return false;
  }

  // Is it one of the supported authorization errors?
  const errorFound = Object.values(authorizationErrors).find(
    (authorizationError) => {
      return error.includes(authorizationError);
    },
  );

  return !!errorFound;
};

const isAuthorizationHeaderRequired = (config) => {
  if (config?.addAuthorizationHeader === undefined) {
    return true;
  }

  return config.addAuthorizationHeader;
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: getAccessToken(),
  },
});

instance.interceptors.request.use(async (config) => {
  if (isAuthorizationHeaderRequired(config)) {
    await setAuthorizationHeader(config);
  }

  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.code === axiosError.NetworkError) {
      store.dispatch(networkError());
    }

    if (error.response.status === StatusCodes.UNAUTHORIZED) {
      store.dispatch(sessionExpired());
    }

    return Promise.reject(error);
  },
);

export default instance;
