export const COGNITO = {
  REGION: process.env.REACT_APP_COGNITO_REGION,
  USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  APP_CLIENT_ID: process.env.REACT_APP_COGNITO_CLIENT_ID,
  DOMAIN: process.env.REACT_APP_COGNITO_DOMAIN,
};

export const CONFIGURE_AMPLIFY = {
  aws_cognito_region: COGNITO.REGION,
  aws_user_pools_id: COGNITO.USER_POOL_ID,
  aws_user_pools_web_client_id: COGNITO.APP_CLIENT_ID,
  cookieStorage: process.env.NODE_ENV === "production" && {
    domain: COGNITO.DOMAIN,
    path: "/",
    expires: 365,
    sameSite: "strict",
    secure: true,
  },
};
