import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import TooltipComponent from "../../Tooltip/TooltipComponent";

const SideMenuTooltipComponent = ({ title, children }) => {
  const { shortSideMenu } = useSelector((state) => state.MainReducer);

  if (!shortSideMenu) {
    return children;
  }
  return <TooltipComponent title={title}>{children}</TooltipComponent>;
};

SideMenuTooltipComponent.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};
export default React.memo(SideMenuTooltipComponent);
