import React from "react";

import TEST_IDS from "../../constants/testIds";
import SpinnerComponent from "../Spinner/SpinnerComponent";

const PageLoaderComponent = ({
  loading,
  children = null,
  className = "h-40",
  style,
}) => {
  if (!loading) {
    return children;
  }
  return (
    <div
      className={`w-full flex items-center justify-center ${className}`}
      data-testid={TEST_IDS.LOADING}
      style={style}
    >
      <SpinnerComponent mode="dark" size="large" />
    </div>
  );
};

export default PageLoaderComponent;
