import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

import MainContainerComponent from "./components/Containers/MainContainerComponent";
import FullScreenLoaderComponent from "./components/Loaders/FullScreenLoaderComponent";
import useComponentFinder from "./hooks/routing/useComponentFinder";
import NotFoundPage from "./pages/NotFound/NotFoundPage";
import routesList from "./settings/routesList";

const RoutesWrapper = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [loading, setLoading] = useState(true);
  const findRouteComponent = useComponentFinder(isAuthenticated);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const user = await Auth.currentAuthenticatedUser();
        const session = await Auth.currentSession();
        setIsAuthenticated(user && session);
      } catch (e) {
        setIsAuthenticated(false);
      }
      setLoading(false);
    })();
  }, []);

  if (loading) {
    return <FullScreenLoaderComponent />;
  }

  return (
    <Routes>
      {routesList.map(({ path, Component, exact = false }) => (
        <Route
          key={path}
          path={path}
          exact={exact}
          element={findRouteComponent(path, Component)}
        />
      ))}

      <Route
        path="*"
        element={
          <MainContainerComponent>
            <NotFoundPage />
          </MainContainerComponent>
        }
      />
    </Routes>
  );
};

export default React.memo(RoutesWrapper);
