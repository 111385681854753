import classNames from "classnames";
import React from "react";

const InputComponent = ({
  placeholder,
  label,
  value = "",
  type = "text",
  disabled = false,
  onChange,
  required,
  autoComplete,
  testId,
}) => {
  const inputStyles = classNames(
    "bg-white text-cement py-2.5 px-3.5 rounded-medium border border-gray w-full duration-default min-w-20 outline-0 focus:shadow focus:border-primary",
    {
      "bg-gray-light opacity-80": disabled,
    },
  );

  return (
    <div className="w-full max-w-screen-sm">
      <label>
        {label && (
          <div className="text-sm w-full font-medium mb-1.5">{label}</div>
        )}
        <input
          data-testid={testId}
          className={inputStyles}
          placeholder={placeholder}
          type={type}
          value={value}
          autoComplete={autoComplete}
          required={required}
          disabled={disabled}
          onChange={(evt) => onChange(evt.target.value)}
        />
      </label>
    </div>
  );
};

export default InputComponent;
