import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import getLeftEndDateTitle from "../../utils/getLeftEndDateTitle";

import TooltipComponent from "../Tooltip/TooltipComponent";
import ChipComponent from "./ChipComponent";

const DateChipComponent = ({
  isActiveCurriculumDate,
  endDate,
  startDate,
  hideDate,
  canBeUncoming,
  className,
  showTooltip,
}) => {
  const { data, loading } = useSelector((state) => state.CurriculumReducer);
  const { date, tooltipTitle } = useMemo(() => {
    if (!isActiveCurriculumDate) {
      return getLeftEndDateTitle({
        canBeUncoming,
        startDate,
        endDate,
      });
    }

    // If the user opens the resource from the curriculum list, the resource will use the start and end dates of the curriculum.
    return getLeftEndDateTitle({
      endDate,
      parentStartDate: data.activeCurriculumStartDate,
      parentEndDate: data.activeCurriculumEndDate,
    });
  }, [endDate]);

  if (!endDate || hideDate) {
    return <div></div>;
  }
  return (
    <TooltipComponent title={showTooltip && tooltipTitle}>
      <ChipComponent title={date} loading={loading} className={className} />
    </TooltipComponent>
  );
};

export default DateChipComponent;
