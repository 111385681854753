import * as React from "react";

const NotFoundIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    viewBox="0 0 208 207"
    {...props}
  >
    <path
      d="M68.498 119.729a3.884 3.884 0 0 0 .637-4.677 3.893 3.893 0 0 0-1.126-1.242l-1.951-12.894-8.011 2.528 4.389 11.505a3.901 3.901 0 0 0 .914 5.09 3.872 3.872 0 0 0 5.148-.31Z"
      style={{
        fill: "#ffb8b8",
        fillRule: "nonzero",
      }}
      transform="translate(-.5)"
    />
    <path
      d="m60.245 113.663-4.942-23.147a13.281 13.281 0 0 1 3.774-12.394c6.326-6.113 14.456-13.819 17.264-15.812a14.45 14.45 0 0 1 9.059-2.682l.067.008 4.251 4.029-9.26 15.288L66.4 87.787l1.564 22.686-7.719 3.19Z"
      className="fill-primary"
      transform="translate(-.5)"
    />
    <path
      d="M77.549 82.209H56.81c-.898 0-1.626.73-1.626 1.631s.728 1.631 1.626 1.631h20.739a1.63 1.63 0 0 0 1.627-1.631 1.63 1.63 0 0 0-1.627-1.631ZM77.549 117.688H56.81a1.63 1.63 0 0 0-1.626 1.632c0 .901.728 1.631 1.626 1.631h20.739a1.63 1.63 0 0 0 1.627-1.631 1.63 1.63 0 0 0-1.627-1.632Z"
      style={{
        fill: "#ccc",
        fillRule: "nonzero",
      }}
      transform="translate(-.5)"
    />
    <path
      d="M67.384 206.592a.81.81 0 0 1-.81-.743L56.206 83.912a.816.816 0 0 1 .739-.885.814.814 0 0 1 .882.741l9.546 112.659L76.647 83.77a.807.807 0 0 1 .565-.708.82.82 0 0 1 .62.054.81.81 0 0 1 .401.478.824.824 0 0 1 .035.316L68.195 205.847a.818.818 0 0 1-.81.745h-.001Z"
      style={{
        fill: "#ccc",
        fillRule: "nonzero",
      }}
      transform="translate(-.5)"
    />
    <path
      d="M90.658 54.747c5.893 0 10.67-4.79 10.67-10.7 0-5.91-4.777-10.701-10.67-10.701s-10.67 4.791-10.67 10.701 4.777 10.7 10.67 10.7ZM58.859 201.702l-5.074-1.622 3.823-20.401 7.49 2.394-6.239 19.629Z"
      style={{
        fill: "#ffb8b8",
        fillRule: "nonzero",
      }}
      transform="translate(-.5)"
    />
    <path
      d="m58.578 206.216-16.336-5.222.135-.426a6.453 6.453 0 0 1 3.176-3.767 6.42 6.42 0 0 1 4.902-.417l10.21 3.263-2.087 6.569Z"
      style={{
        fill: "#2f2e41",
        fillRule: "nonzero",
      }}
      transform="translate(-.5)"
    />
    <path
      d="M95.778 201.596h-5.325l-2.535-20.602h7.86v20.602Z"
      style={{
        fill: "#ffb8b8",
        fillRule: "nonzero",
      }}
      transform="translate(-.5)"
    />
    <path
      d="M80.383 206.352h16.332v-6.078h-10.31a6.02 6.02 0 0 0-4.256 1.771 6.055 6.055 0 0 0-1.766 4.268v.039ZM95.626 194.91l-1.798-.269c-5.452-.814-5.778-.863-6.644-1.244-.442-.195-.473-3.138-.207-19.68.194-12.142.437-27.254-.034-35.366a1.425 1.425 0 0 0-2.094-1.171 1.43 1.43 0 0 0-.632.689c-4.056 9.395-10.862 30.419-15.366 44.331-1.566 4.84-2.803 8.662-3.586 10.953a1.84 1.84 0 0 1-2.177 1.182c-3.706-.929-3.458-1.324-8.737-2.693a1.825 1.825 0 0 1-1.099-.808 1.838 1.838 0 0 1-.236-1.346c.955-4.516 18.427-77.73 19.765-84.69a1.833 1.833 0 0 1 2.157-1.456c3.934.795 6.327 1.638 10.214 2.453 4.195.88 8.533 1.79 12.763 2.64.421.085.799.317 1.065.654 1.047 1.326 2.6 2.071 4.243 2.859 1.108.531-1.403.483-.438 1.249 3.908 3.103-1.711 66.102-5.107 80.331a1.832 1.832 0 0 1-2.052 1.382ZM95.207 53.41c-.623-2.651 2.526-8.354 1.903-11.005-.421-1.796-4.631-.575-5.613-2.134-.982-1.56-2.628-2.823-4.467-2.844-2.113-.023-4.193 1.549-6.196.869-2.024-.687-2.671-3.467-1.776-5.414.895-1.946 2.823-3.186 4.728-4.158 3.244-1.656 7.021-2.864 10.493-1.769 2.099.662 3.854 2.096 5.552 3.499 1.548 1.279 3.122 2.587 4.205 4.28 2.12 3.318 1.992 7.788.18 11.284-1.812 3.496-5.113 6.062-8.75 7.552"
      style={{
        fill: "#2f2e41",
        fillRule: "nonzero",
      }}
      transform="translate(-.5)"
    />
    <path
      d="M69.005 115.099c1.151-1.929 2.9-10.969 5.056-26.142 1.468-10.358 2.747-20.883 3.382-26.259.049-.435.207-.851.461-1.207.253-.357.592-.644.986-.833.412-.199.821-.388 1.228-.566 5.255-2.292 10.581-3.052 15.828-2.257a28.684 28.684 0 0 1 14.2 6.488l.048.046.012.064c.023.12 2.227 12.03-2.287 20.077-4.453 7.939-4.504 28.965-4.504 29.177v.27l-.26-.074c-6.926-1.985-33.68 1.487-33.949 1.523l-.417.054.216-.361Z"
      className="fill-primary"
      transform="translate(-.5)"
    />
    <path
      d="M109.272 87.51H88.533a1.63 1.63 0 0 0-1.627 1.631 1.63 1.63 0 0 0 1.627 1.632h20.739a1.63 1.63 0 0 0 1.626-1.632c0-.9-.728-1.631-1.626-1.631ZM109.272 122.99H88.533a1.63 1.63 0 0 0-1.627 1.631 1.63 1.63 0 0 0 1.627 1.632h20.739a1.63 1.63 0 0 0 1.626-1.632c0-.901-.728-1.631-1.626-1.631Z"
      style={{
        fill: "#ccc",
        fillRule: "nonzero",
      }}
      transform="translate(-.5)"
    />
    <path
      d="M99.103 207a.81.81 0 0 1-.81-.744L87.925 89.214a.828.828 0 0 1 .187-.596.812.812 0 0 1 1.433.451l9.546 107.766 9.275-107.764a.815.815 0 0 1 .88-.742.813.813 0 0 1 .741.883L99.913 206.254a.813.813 0 0 1-.809.746h-.001Z"
      style={{
        fill: "#ccc",
        fillRule: "nonzero",
      }}
      transform="translate(-.5)"
    />
    <path
      d="M92.407 123.507a3.87 3.87 0 0 1 3.617-4.702l8.624-9.763 5.355 6.489-9.917 7.266a3.887 3.887 0 0 1-3.525 3.776 3.877 3.877 0 0 1-2.658-.742 3.892 3.892 0 0 1-1.496-2.324Z"
      style={{
        fill: "#ffb8b8",
        fillRule: "nonzero",
      }}
      transform="translate(-.5)"
    />
    <path
      d="m97.426 116.712 13.605-18.192-7.016-15.077.51-17.881 5.751-1.066.061.031a14.508 14.508 0 0 1 6.152 7.188c1.278 3.203 3.928 14.102 5.926 22.681a13.282 13.282 0 0 1-3.547 12.462l-16.69 16.738-4.752-6.884Z"
      className="fill-primary"
      transform="translate(-.5)"
    />
    <path
      d="m40.398 201.941 18.613 4.494a1.593 1.593 0 0 0 1.852-.867l15.989-42.633a1.326 1.326 0 0 0 .002-1.039 1.492 1.492 0 0 0-.852-.799l-13.995-5.36a1.675 1.675 0 0 0-1.372.076 1.424 1.424 0 0 0-.748.918l-.001.002-9.877 37.576-.147.009c-.056.004-5.719.39-8.265 3.455a5.44 5.44 0 0 0-1.199 4.168Z"
      style={{
        fill: "#ccc",
        fillRule: "nonzero",
      }}
      transform="translate(-.5)"
    />
    <path
      d="M202.195 206.592c0 .054-.01.107-.031.156a.396.396 0 0 1-.22.221.395.395 0 0 1-.156.031H.907a.408.408 0 1 1 0-.816h200.881a.413.413 0 0 1 .288.119.396.396 0 0 1 .088.133.398.398 0 0 1 .031.156Z"
      style={{
        fill: "#3f3d56",
        fillRule: "nonzero",
      }}
      transform="translate(-.5)"
    />
    <path
      d="m53.875 178.042-.086.811 16.97 1.802.086-.811-16.97-1.802ZM52.396 184.761l-.333.744 13.656 6.162.334-.744-13.657-6.162ZM72.631 174.131l-16.096-5.346.334-.744 16.096 5.346-.334.744ZM74.26 170.461l-16.096-7.794.334-.744 16.096 7.794-.334.744ZM50.977 191.147l-.528.62 11.605 9.943.529-.62-11.606-9.943ZM47.682 194.443l-.741.337 4.66 10.306.741-.337-4.66-10.306Z"
      style={{
        fill: "#b3b3b3",
        fillRule: "nonzero",
      }}
      transform="translate(-.5)"
    />
    <path
      d="M156.789 194.93V9.047c0-2.51.048-5.026 0-7.536-.002-.109 0-.219 0-.329 0-1.575-2.44-1.577-2.44 0v185.884c0 2.51-.047 5.025 0 7.535.002.11 0 .22 0 .329 0 1.575 2.44 1.577 2.44 0Z"
      style={{
        fill: "#3f3d56",
        fillRule: "nonzero",
      }}
      transform="translate(-.5)"
    />
    <path
      d="M170.281 53.135h-29.278a2.645 2.645 0 0 1-1.869-.777 2.662 2.662 0 0 1-.775-1.874V14.8a2.66 2.66 0 0 1 .775-1.873 2.646 2.646 0 0 1 1.869-.778h29.278a2.644 2.644 0 0 1 1.868.778 2.66 2.66 0 0 1 .775 1.873v35.684a2.662 2.662 0 0 1-.775 1.874 2.643 2.643 0 0 1-1.868.777Z"
      style={{
        fill: "#3f3d56",
        fillRule: "nonzero",
      }}
      transform="translate(-.5)"
    />
    <path
      d="M205.519 185.235c-2.562 5.334-7.283 9.302-12.232 12.528a77.088 77.088 0 0 1-17.347 8.421 67.28 67.28 0 0 1-2.533.816h-34.366c-.24-.261-.467-.534-.683-.816-2.301-2.956-3.546-6.745-3.094-10.456.581-4.767 4.192-9.147 8.868-10.159 4.681-1.015 10.016 1.787 11.439 6.375.781-8.842 1.684-18.05 6.368-25.579 4.246-6.814 11.594-11.692 19.556-12.564a24.55 24.55 0 0 1 13.614 2.5c.387.195.769.399 1.143.611a22.81 22.81 0 0 1 6.563 5.616c4.965 6.305 6.181 15.468 2.704 22.707Z"
      className="fill-primary"
      transform="translate(-.5)"
    />
    <path
      d="M195.997 157.471A129.278 129.278 0 0 0 158.822 207h-1.619c.114-.273.228-.546.346-.816a129.204 129.204 0 0 1 5.193-10.786 131.24 131.24 0 0 1 14.196-21.068 129.08 129.08 0 0 1 17.998-17.924.807.807 0 0 1 .17-.106.84.84 0 0 1 .363-.068.838.838 0 0 1 .635.34.83.83 0 0 1 .145.34.605.605 0 0 1-.252.559Z"
      style={{
        fill: "#fff",
        fillOpacity: 0.2,
        fillRule: "nonzero",
      }}
      transform="translate(-.5)"
    />
  </svg>
);
export default NotFoundIcon;
