const STATUS_LIST = {
  CANCELED: "canceled", // This status is used only in the frontend part of the app, for the curriculum component. When the curriculum is inactive
  INACTIVE: "inactive", // Curriculum is inactive
  UPCOMING: "upcoming", // Single item start date is in the future or the user has not finished the previous item
  ACTIVE: "active", // Single item is active
  IN_PROGRESS: "in_progress", // User currently performs current item
  FINISHED: "finished", // Single item is finished by the user
  DISABLED: "disabled", // This status is used only in the frontend part of the app, for the date component. When the date is upcoming
  ENDED: "ended", // Single item end date is in the past, user has not finished it
  ALERT: "alert", // This status is used only in the frontend part of the app, for the date component. When the date is expired
  TRY_AGAIN: "try_again", // Single item with try_again status can be retaken N times
  FAILED: "failed", // Single item with failed status can be retaken
  PRIMARY: "primary", // This status is used only in the frontend part of the app, for the date component. When the date is not expired and not upcoming
  DEFAULT: "default", // Currently not using. Was used in the old version of the app
};

export default STATUS_LIST;
