import React from "react";

const DashboardIcon = ({ color, props }) => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8.125 0.125H0.625V10.125H8.125V0.125Z" fill={color} />
      <path d="M8.125 13.875H0.625V18.875H8.125V13.875Z" fill={color} />
      <path d="M19.375 0.125H11.875V6.375H19.375V0.125Z" fill={color} />
      <path d="M19.375 10.125H11.875V18.875H19.375V10.125Z" fill={color} />
    </svg>
  );
};

export default DashboardIcon;
