import PropTypes from "prop-types";
import React, { useContext, useMemo } from "react";
import { SingleItemCardOrListContext } from "../../contexts/SingleItemCardOrListProvider";

import PingComponent from "../Ping/PingComponent";
import IconJobCodeAndExpirationDate from "../SingleItem/MetaData/IconJobCodeAndExpirationDate";
import SummaryElementContainer from "../SummarElementContainer/SummaryElementContainer";

const SingleItemCardBodyComponent = ({ children, Status }) => {
  const { title, smaller, contentType, showIndicator, pingColor, tags } =
    useContext(SingleItemCardOrListContext);

  const { bodyHeight, maxClamp } = useMemo(() => {
    if (smaller) {
      return {
        bodyHeight: "min-h-[90px]",
        maxClamp: "line-clamp-2",
      };
    }

    return {
      bodyHeight: "min-h-[115px]",
      maxClamp: "line-clamp-3",
    };
  }, [smaller]);

  return (
    <div className={`py-3 px-4 border-y border-gray ${bodyHeight} flex-1`}>
      <div className="flex items-center gap-2">
        <PingComponent show={showIndicator} pingColor={pingColor} />
        <h6 className={`font-bold text-base leading-5 break-words ${maxClamp}`}>
          {title}
        </h6>
        {Status}
      </div>
      {children}
      <IconJobCodeAndExpirationDate
        contentType={contentType}
        className="mt-1"
      />
      <SummaryElementContainer elements={tags} containerClass="mt-3" />
    </div>
  );
};

SingleItemCardBodyComponent.propTypes = {
  children: PropTypes.object,
  StatusChildren: PropTypes.object,
};
export default React.memo(SingleItemCardBodyComponent);
