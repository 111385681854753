import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import searchIcon from "../../assets/icons/search.svg";

export function SearchInput({
  value,
  placeholder,
  makeWiderOnHover,
  autoFocus,
  fullWidth,
  className,
  onKeyDown,
  onChange,
  onBlur,
  testId,
}) {
  const sectionCssClasses = classNames("w-full flex justify-center", className);

  const wrapperCssClasses = classNames(
    "flex gap-2 items-center px-3 py-2.5 bg-background rounded-full duration-default w-full",
    {
      "lg:hover:w-full 2xl:hover:w-1/2": makeWiderOnHover,
      "lg:w-1/2 2xl:w-1/3": !fullWidth,
    },
  );

  return (
    <section className={sectionCssClasses}>
      <div className={wrapperCssClasses}>
        <div className="w-3.5">
          <img src={searchIcon} alt="Icon: Search" title="Icon: Search" />
        </div>
        <input
          className="placeholder-dark outline-0 bg-transparent w-full text-xs lg:text-sm"
          placeholder={placeholder}
          autoFocus={!!autoFocus}
          onKeyDown={onKeyDown}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          data-testid={testId}
        />
      </div>
    </section>
  );
}

SearchInput.propTypes = {
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  makeWiderOnHover: PropTypes.bool,
  autoFocus: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  onKeyDown: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  testId: PropTypes.string,
};
