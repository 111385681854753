import React from "react";
import { NETWORK_ERROR_IMAGE_BASE64 } from "../../constants/constants";
import TEST_IDS from "../../constants/testIds";
import CommonErrorPage from "./components/CommonErrorPage";

export default function NetworkErrorPage() {
  const image = {
    src: NETWORK_ERROR_IMAGE_BASE64,
    alt: "Network issue",
  };

  const description = {
    header: "Oops, no internet connection",
    texts: [
      "It seems our wires got crossed! We're untangling them as we speak.",
      "You can help by checking your network connection!",
    ],
  };

  const testIds = {
    container: TEST_IDS.NETWORK_ERROR_PAGE.CONTAINER,
    wrapper: TEST_IDS.NETWORK_ERROR_PAGE.CONTENT_WRAPPER,
  };

  return (
    <CommonErrorPage
      title="Network error"
      image={image}
      description={description}
      testIds={testIds}
    />
  );
}
