import { Auth } from "aws-amplify";
import { useCallback, useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { LOGIN_REDIRECT_STORAGE } from "../constants/storageNames";
import { AuthContext } from "../contexts/AuthProvider";
import { HOME_ROUTE, LOGIN_ROUTE } from "../settings/routes";
import setInitialCookies from "../utils/setInitialCookies";

const useUserAuthorization = () => {
  const authContext = useContext(AuthContext);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleRoute = useCallback(() => {
    const redirectRoute = localStorage.getItem(LOGIN_REDIRECT_STORAGE);

    // uses the route the user tried to go before being redirected to the login page.
    if (redirectRoute) {
      localStorage.removeItem(LOGIN_REDIRECT_STORAGE);
      navigate(redirectRoute);
      return;
    }

    navigate(HOME_ROUTE);
  }, [navigate]);

  const handleUserAuthorization = useCallback(async () => {
    try {
      // sets the token from Amplify
      const res = await Auth.currentSession();
      const accessToken = res.getAccessToken().getJwtToken();
      await authContext.setAccessToken(accessToken);

      // sets cookies that allow you to display graphical content
      await setInitialCookies();

      handleRoute();
      window.location.reload();
    } catch (_) {
      navigate(LOGIN_ROUTE);
    }
  }, [dispatch, navigate, authContext, Auth, handleRoute]);

  return handleUserAuthorization;
};

export default useUserAuthorization;
