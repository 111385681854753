import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NAVBAR_HEIGHT_CLASS_NAMES } from "../../../constants/classNamesConstants";
import useShortSideMenuClasses from "../../../hooks/useShortSideMenuClasses";
import { UPDATE_MAIN_ITEM_REDUCER } from "../../../store/reducers/MainReducer";
import ShortSideMenuComponent from "./ShortSideMenuComponent";
import SideMenuIconComponent from "./SideMenuIconComponent";

const ShortSideMenuButtonComponent = () => {
  const dispatch = useDispatch();
  const containerClassNames = useShortSideMenuClasses(
    "gap-4 pr-6 pl-2",
    "justify-center",
  );

  const { shortSideMenu } = useSelector((state) => state.MainReducer);

  const handleToggleShortMenu = useCallback(() => {
    dispatch(
      UPDATE_MAIN_ITEM_REDUCER({ id: "shortSideMenu", value: !shortSideMenu }),
    );
  }, [dispatch, shortSideMenu]);

  return (
    <ShortSideMenuComponent isControl>
      <div className={`flex ${containerClassNames}`}>
        <div
          className={`${NAVBAR_HEIGHT_CLASS_NAMES} flex items-center justify-center mb-[-1px]`}
        >
          <SideMenuIconComponent onClick={handleToggleShortMenu} />
        </div>
      </div>
    </ShortSideMenuComponent>
  );
};

export default React.memo(ShortSideMenuButtonComponent);
