import React, { useContext, useMemo } from "react";

import { useSelector } from "react-redux";
import { LMS_LAYOUT_TYPE, USER_ROLES } from "../../constants/constants";
import CUSTOM_ICON_KEYS from "../../constants/customIconKeys";
import { BRANDS_NAME_STORAGE } from "../../constants/storageNames";
import { CompanyDataContext } from "../../contexts/CompanyDataProvider";
import useShortSideMenuClasses from "../../hooks/useShortSideMenuClasses";
import {
  CONTESTS_ROUTE,
  HOME_ROUTE,
  MY_TEAM_ROUTE,
  NEWS_ROUTE,
} from "../../settings/routes";
import PoweredByComponent from "./components/PoweredByComponent";
import ShortSideMenuComponent from "./components/ShortSideMenuComponent";
import SideMenuHeaderComponent from "./components/SideMenuHeaderComponent";
import SideMenuItemComponent from "./components/SideMenuItemComponent";

// A shortened version of the menu can be run for some pages.
// The shortened version of the menu displays only icons with tooltip and has two levels.
const SideMenuComponent = () => {
  const borderClassName = "border-r border-gray";
  const containerClassNames = useShortSideMenuClasses(borderClassName, "");
  const listClassNames = useShortSideMenuClasses(
    "sm:mt-4 xl:mt-6",
    borderClassName,
  );
  const { data: userData } = useSelector((state) => state.UserReducer);

  const companyContextData = useContext(CompanyDataContext);
  const { brandsList, data: companyData } = companyContextData;
  const {
    showContests,
    customBrandName,
    importantLinks,
    customImportantLinksName,
    customContestsName,
  } = companyContextData.data;

  // Titles of brands, contests, important links fields can be changed in the admin panel.
  const menuItems = useMemo(() => {
    const menuItems = [
      {
        name: "My Dashboard",
        path: HOME_ROUTE,
        iconKey: CUSTOM_ICON_KEYS.DASHBOARD,
      },
      {
        name: customBrandName,
        namespace: localStorage.getItem(BRANDS_NAME_STORAGE),
        items: brandsList || [],
        iconKey: CUSTOM_ICON_KEYS.BRANDS,
      },
    ];
    // Administrator can hide the contest functionality in the admin panel
    if (showContests) {
      menuItems.push({
        name: customContestsName,
        path: CONTESTS_ROUTE,
        iconKey: CUSTOM_ICON_KEYS.CONTESTS,
      });
    }

    menuItems.push({
      name: "News",
      path: NEWS_ROUTE,
      iconKey: CUSTOM_ICON_KEYS.NEWS,
    });

    // The My team functionality should only be visible to the LMS layout
    if (companyData?.layout === LMS_LAYOUT_TYPE) {
      menuItems.push({
        name: "My Team",
        path: MY_TEAM_ROUTE,
        inactive: true,
        iconKey: CUSTOM_ICON_KEYS.MY_TEAM,
      });
    }

    if (importantLinks?.length) {
      const importantLinksList = importantLinks.map(
        ({ title, url, internal_link }) => ({
          path: url,
          external: true,
          title,
          internal_link,
        }),
      );

      menuItems.push({
        name: customImportantLinksName,
        items: importantLinksList,
        iconKey: CUSTOM_ICON_KEYS.IMPORTANT_LINKS,
      });
    }

    if (userData?.user_role === USER_ROLES.ADMIN) {
      menuItems.push({
        name: "Admin Panel",
        path: process.env.REACT_APP_ADMIN_PANEL,
        external: true,
        inactive: true,
        iconKey: CUSTOM_ICON_KEYS.ADMIN_PANEL,
      });
    }

    return menuItems;
  }, [brandsList, userData, showContests, companyData]);

  return (
    <div
      className={`h-full w-full flex flex-col shadow-sidebar z-1 ${containerClassNames}`}
    >
      <SideMenuHeaderComponent />
      <ol
        className={`m-0 p-0 overflow-y-auto overflow-x-hidden flex-grow select-none ${listClassNames}`}
      >
        {menuItems.map(
          ({ name, path, items, namespace, external, iconKey }, id) => (
            <SideMenuItemComponent
              name={name}
              path={path}
              items={items}
              key={id}
              external={external}
              namespace={namespace}
              iconKey={iconKey}
            />
          ),
        )}
      </ol>
      <ShortSideMenuComponent>
        <PoweredByComponent isColumn />
      </ShortSideMenuComponent>
    </div>
  );
};

export default SideMenuComponent;
