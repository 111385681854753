import * as React from "react";
const ServiceWorkIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    viewBox="0 0 877 662"
    {...props}
  >
    <path
      d="M685.834 138.655c7.516 15.995-8.41 20.525-26.655 29.099-18.244 8.573-31.898 17.942-39.414 1.947-7.516-15.995-13.615-47.743 12.195-59.871 26.7-12.546 46.358 12.829 53.874 28.825Z"
      style={{
        fill: "#2f2e41",
        fillRule: "nonzero",
      }}
      transform="translate(-180.864 -106.46)"
    />
    <circle
      cx={467.746}
      cy={39.089}
      r={24.561}
      style={{
        fill: "#ffb8b8",
      }}
    />
    <path
      d="M770.29 254.473c-.85-1.11-1.7-2.2-2.56-3.26a136.813 136.813 0 0 0-3.35-4.01c-12.73-14.69-26.27-23.94-41.179-24.93h-.02l-.221-.02-29.71 28.96-10.1 9.84.22.54 5.45 13.57h95.49a234.468 234.468 0 0 0-14.02-20.69ZM621.902 155.438l8.792-4.132-1.225-12.683 5.879 10.496 4.203-1.974-.715-7.399 3.43 6.123 31.224-14.673c-6.066-12.909-21.68-18.54-34.589-12.474l-4.525 2.127c-12.909 6.066-18.54 21.68-12.474 34.589Z"
      style={{
        fill: "#2f2e41",
        fillRule: "nonzero",
      }}
      transform="translate(-180.864 -106.46)"
    />
    <path
      d="M825.39 601.261H312.552c-9.048-.011-16.49-7.453-16.5-16.5V276.607c.007-6.629 5.459-12.081 12.088-12.089h521.453c6.743.008 12.29 5.555 12.297 12.298v307.945c-.01 9.047-7.452 16.489-16.5 16.5Z"
      style={{
        fill: "#f2f2f2",
        fillRule: "nonzero",
      }}
      transform="translate(-180.864 -106.46)"
    />
    <path
      d="M328.552 584.761H809.39c8.778 0 16-7.223 16-16V307.213c0-8.778-7.222-16-16-16H328.552c-8.778 0-16 7.222-16 16v261.548c0 8.777 7.222 16 16 16Z"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-180.864 -106.46)"
    />
    <path
      d="M841.66 275.663H295.82v-8.4c.013-9.078 7.482-16.543 16.56-16.55H825.1c9.078.007 16.547 7.472 16.56 16.55v8.4Z"
      className="fill-primary"
      transform="translate(-180.864 -106.46)"
    />
    <circle
      cx={145.051}
      cy={156.752}
      r={4.283}
      style={{
        fill: "#fff",
      }}
    />
    <circle
      cx={161.31}
      cy={156.752}
      r={4.283}
      style={{
        fill: "#fff",
      }}
    />
    <circle
      cx={177.569}
      cy={156.752}
      r={4.283}
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M583.974 528.04h-43c-5.209-.006-9.494-4.291-9.5-9.5v-43c.006-5.21 4.291-9.495 9.5-9.5h43c5.21.005 9.494 4.29 9.5 9.5v43c-.006 5.209-4.29 9.494-9.5 9.5Zm-43-60c-4.112.004-7.495 3.387-7.5 7.5v43c.005 4.112 3.388 7.495 7.5 7.5h43c4.113-.005 7.496-3.388 7.5-7.5v-43c-.004-4.113-3.387-7.496-7.5-7.5h-43Z"
      style={{
        fill: "#ccc",
        fillRule: "nonzero",
      }}
      transform="translate(-180.864 -106.46)"
    />
    <path
      d="M575.474 516.04h-26a3.018 3.018 0 0 1-3-3v-32a3.017 3.017 0 0 1 3-3h18.84c.683 0 1.346.234 1.879.661l7.16 5.754a2.984 2.984 0 0 1 1.121 2.338v26.247a3.017 3.017 0 0 1-3 3Zm-26-36c-.548 0-.999.451-1 1v32c.001.548.452.999 1 1h26c.549-.001 1-.452 1-1v-26.247a.996.996 0 0 0-.373-.779l-7.161-5.754a1 1 0 0 0-.626-.22h-18.84Z"
      style={{
        fill: "#ccc",
        fillRule: "nonzero",
      }}
      transform="translate(-180.864 -106.46)"
    />
    <path
      d="M570.474 492.04h-16c-.548 0-1-.452-1-1 0-.549.452-1 1-1h16c.549 0 1 .451 1 1 0 .548-.451 1-1 1ZM570.474 498.04h-16c-.548 0-1-.452-1-1 0-.549.452-1 1-1h16c.549 0 1 .451 1 1 0 .548-.451 1-1 1ZM570.474 504.04h-16c-.548 0-1-.452-1-1 0-.549.452-1 1-1h16c.549 0 1 .451 1 1 0 .548-.451 1-1 1ZM685.974 528.04h-43c-5.209-.006-9.494-4.291-9.5-9.5v-43c.006-5.21 4.291-9.495 9.5-9.5h43c5.21.005 9.494 4.29 9.5 9.5v43c-.006 5.209-4.29 9.494-9.5 9.5Zm-43-60c-4.112.004-7.495 3.387-7.5 7.5v43c.005 4.112 3.388 7.495 7.5 7.5h43c4.113-.005 7.496-3.388 7.5-7.5v-43c-.004-4.113-3.387-7.496-7.5-7.5h-43Z"
      style={{
        fill: "#ccc",
        fillRule: "nonzero",
      }}
      transform="translate(-180.864 -106.46)"
    />
    <path
      d="M678.474 508.04h-28a3.018 3.018 0 0 1-3-3v-16a3.017 3.017 0 0 1 3-3h28a3.016 3.016 0 0 1 3 3v16a3.017 3.017 0 0 1-3 3Zm-28-20c-.548 0-.999.451-1 1v16c.001.548.452.999 1 1h28c.549-.001 1-.452 1-1v-16c0-.549-.451-1-1-1h-28Z"
      style={{
        fill: "#ccc",
        fillRule: "nonzero",
      }}
      transform="translate(-180.864 -106.46)"
    />
    <path
      d="M664.474 499.678a2.997 2.997 0 0 1-1.589-.453l-14.941-9.337a1.002 1.002 0 0 1-.468-.847c0-.549.451-1.001 1-1.001.187 0 .37.053.529.151l14.94 9.338c.324.201.736.201 1.06 0l14.939-9.338c.159-.098.342-.151.529-.151.548 0 1 .452 1 1.001 0 .343-.177.664-.468.847l-14.94 9.337a3 3 0 0 1-1.591.453ZM481.974 528.04h-43c-5.209-.006-9.494-4.291-9.5-9.5v-43c.006-5.21 4.291-9.495 9.5-9.5h43c5.21.005 9.494 4.29 9.5 9.5v43c-.006 5.209-4.29 9.494-9.5 9.5Zm-43-60c-4.112.004-7.495 3.387-7.5 7.5v43c.005 4.112 3.388 7.495 7.5 7.5h43c4.113-.005 7.496-3.388 7.5-7.5v-43c-.004-4.113-3.387-7.496-7.5-7.5h-43Z"
      style={{
        fill: "#ccc",
        fillRule: "nonzero",
      }}
      transform="translate(-180.864 -106.46)"
    />
    <path
      d="M474.474 508.04h-28a3.018 3.018 0 0 1-3-3v-16a3.017 3.017 0 0 1 3-3h28a3.016 3.016 0 0 1 3 3v16a3.017 3.017 0 0 1-3 3Zm-28-20c-.548 0-.999.451-1 1v16c.001.548.452.999 1 1h28c.549-.001 1-.452 1-1v-16c0-.549-.451-1-1-1h-28Z"
      style={{
        fill: "#ccc",
        fillRule: "nonzero",
      }}
      transform="translate(-180.864 -106.46)"
    />
    <path
      d="M470.036 508.04h-20.124c-1.097 0-2-.903-2-2 0-.351.093-.696.268-1l6.062-10.5a2.002 2.002 0 0 1 3.464 0l3.915 6.781 2.268-3.929a2.003 2.003 0 0 1 3.465 0l4.415 7.648c.175.304.268.648.268 1 0 1.097-.903 2-2 2h-.001Zm0-2-4.415-7.648-3.133 5.429a1.003 1.003 0 0 1-1.733 0l-4.781-8.281-6.062 10.5h20.124Z"
      style={{
        fill: "#ccc",
        fillRule: "nonzero",
      }}
      transform="translate(-180.864 -106.46)"
    />
    <circle
      cx={290.61}
      cy={385.579}
      r={2}
      style={{
        fill: "#ccc",
      }}
    />
    <path
      d="m768.825 648.15-12.26-.001-5.832-47.288 18.094.001-.002 47.288Z"
      style={{
        fill: "#ffb8b8",
        fillRule: "nonzero",
      }}
    />
    <path
      d="m952.816 766.494-39.531-.001v-.5c0-8.441 6.946-15.386 15.386-15.386l24.145.001v15.886Z"
      style={{
        fill: "#2f2e41",
        fillRule: "nonzero",
      }}
      transform="translate(-180.864 -106.46)"
    />
    <path
      d="m818.825 648.15-12.26-.001-5.832-47.288 18.094.001-.002 47.288Z"
      style={{
        fill: "#ffb8b8",
        fillRule: "nonzero",
      }}
    />
    <path
      d="m1002.82 766.494-39.535-.001v-.5c0-8.441 6.946-15.386 15.386-15.386l24.149.001v15.886ZM996.014 734.111l-11.355-3.454a4.502 4.502 0 0 1-3.165-3.833l-10.586-100.326a.482.482 0 0 0-.479-.448.471.471 0 0 0-.51.409l-17.731 97.2a4.515 4.515 0 0 1-4.427 3.692 4.47 4.47 0 0 1-.908-.093l-14.511-2.989a4.53 4.53 0 0 1-3.582-4.712l10.785-159.312 73.135-7.892-10.86 177.727c-.15 2.357-2.127 4.221-4.489 4.227-.446 0-.89-.066-1.317-.196Z"
      style={{
        fill: "#2f2e41",
        fillRule: "nonzero",
      }}
      transform="translate(-180.864 -106.46)"
    />
    <circle
      cx={815.08}
      cy={290.487}
      r={24.561}
      style={{
        fill: "#ffb8b8",
      }}
    />
    <path
      d="m939.433 561.18 15.858-99.851c2.908-18.306 18.853-31.918 37.388-31.918 3.594 0 7.168.511 10.621 1.519 18.59 5.378 30.19 24.238 26.6 43.262l-16.59 89.172-73.877-2.184Z"
      className="fill-primary"
      transform="translate(-180.864 -106.46)"
    />
    <path
      d="M1002.73 579.046a10.074 10.074 0 0 0 4.51-8.391c0-2.579-.99-5.06-2.77-6.929l16.4-31.751-18.47 1.97-12.736 29.85a10.115 10.115 0 0 0-2.675 6.85c0 5.547 4.564 10.111 10.111 10.111 2.002 0 3.96-.595 5.63-1.71Z"
      style={{
        fill: "#ffb8b8",
        fillRule: "nonzero",
      }}
      transform="translate(-180.864 -106.46)"
    />
    <path
      d="M1006.24 558.126a4.498 4.498 0 0 1-2.43-1.151l-6.274-5.797a4.524 4.524 0 0 1-1.092-5.054l12.536-29.718-1.78-58.72c.97-7.156 7.15-12.551 14.37-12.551 7.95 0 14.5 6.544 14.5 14.497 0 .66-.05 1.319-.14 1.973l3.04 36.912a46.327 46.327 0 0 1-8.94 31.397l-19.55 26.432a4.5 4.5 0 0 1-4.24 1.78Z"
      className="fill-primary"
      transform="translate(-180.864 -106.46)"
    />
    <path
      d="M854.505 383.566c.879 4.747 5.059 8.225 9.888 8.225a10.07 10.07 0 0 0 3.539-.643l22.845 27.478 5.385-17.772-22.52-23.369a10.127 10.127 0 0 0-9.208-5.935c-5.546 0-10.11 4.564-10.11 10.111 0 .639.061 1.277.181 1.905Z"
      style={{
        fill: "#ffb8b8",
        fillRule: "nonzero",
      }}
      transform="translate(-180.864 -106.46)"
    />
    <path
      d="M872.401 394.955a4.503 4.503 0 0 1 2.006-1.785l7.784-3.517a4.525 4.525 0 0 1 5.08.965l22.478 23.132 54.766 21.257a14.51 14.51 0 0 1 7.106 12.471c0 7.953-6.544 14.497-14.496 14.497-2.612 0-5.175-.706-7.419-2.042l-35.177-11.592a46.38 46.38 0 0 1-25.427-20.472l-16.717-28.31a4.504 4.504 0 0 1 .016-4.604Z"
      className="fill-primary"
      transform="translate(-180.864 -106.46)"
    />
    <path
      d="m835.002 375.652-1 32-18.422 32.011 15.382-35.673 4.04-28.338Z"
      style={{
        fillOpacity: 0.1,
        fillRule: "nonzero",
      }}
    />
    <path
      d="M993.13 401.563a6.444 6.444 0 0 1-7.03-4.702 9.775 9.775 0 0 0-6.951-7.274c-4.505-1.029-10.012 1.092-13.313-2.322a6.643 6.643 0 0 1-1.576-5.842c.608-3.318 2.981-5.824 5.564-7.828a34.064 34.064 0 0 1 18.866-7.051c3.548-.102 7.1.074 10.62.529 3.69.232 7.33.899 10.86 1.985a25.62 25.62 0 0 1 16.01 18.68 29.56 29.56 0 0 1-6.06 24.131 31.717 31.717 0 0 1-12.16 9.259 4.756 4.756 0 0 1-3.61.182c-2.96-1.175-2.47-4.839-1.12-7.282 1.44-2.615 3.66-5.147 2.5-8.329a6.297 6.297 0 0 0-3.13-3.366c-2.938-1.499-6.226-1.144-9.387-.779l-.083.009Z"
      style={{
        fill: "#2f2e41",
        fillRule: "nonzero",
      }}
      transform="translate(-180.864 -106.46)"
    />
    <path
      d="M846.297 415.433h-304c-9.047-.01-16.489-7.452-16.5-16.5v-65c.011-9.048 7.453-16.49 16.5-16.5h304c9.048.011 16.49 7.452 16.5 16.5v65c-.01 9.048-7.452 16.49-16.5 16.5Z"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-180.864 -106.46)"
    />
    <path
      d="M846.297 415.933h-304c-9.322-.01-16.989-7.678-17-17v-65c.011-9.322 7.678-16.989 17-17h304c9.322.011 16.99 7.678 17 17v65c-.01 9.322-7.678 16.99-17 17Zm-304-97c-8.225.01-14.99 6.775-15 15v65c.01 8.225 6.775 14.991 15 15h304c8.225-.009 14.991-6.775 15-15v-65c-.009-8.225-6.775-14.99-15-15h-304Z"
      style={{
        fill: "#3f3d56",
        fillRule: "nonzero",
      }}
      transform="translate(-180.864 -106.46)"
    />
    <path
      d="M809.297 399.433h-230c-9.048-.01-16.49-7.452-16.5-16.5v-34c.01-9.048 7.452-16.49 16.5-16.5h230c9.048.01 16.49 7.452 16.5 16.5v34c-.01 9.048-7.452 16.49-16.5 16.5Z"
      style={{
        fill: "#f2f2f2",
        fillRule: "nonzero",
      }}
      transform="translate(-180.864 -106.46)"
    />
    <circle
      cx={447.928}
      cy={254.915}
      r={19.732}
      style={{
        fill: "#ff6584",
      }}
    />
    <path
      d="m795.004 397.442-27.852-23.36-27.372-22.956a4.013 4.013 0 0 0-5.153.01l-27.283 23.062-6.664 5.633-10.048-8.428-30.537-25.61a4.013 4.013 0 0 0-5.153.009l-30.438 25.729-30.917 26.133c-.224.19-.354.47-.354.764 0 .549.451 1 1 1h.002l63.386-.122 20.064-.039-.196.166 59.808-.115 57.067-.11a1 1 0 0 0 .64-1.766Z"
      style={{
        fill: "#3f3d56",
        fillRule: "nonzero",
      }}
      transform="translate(-180.864 -106.46)"
    />
    <path
      d="M1056.14 767.933H864.136c-.549 0-1-.451-1-1 0-.548.451-1 1-1h192.004c.54 0 1 .452 1 1 0 .549-.46 1-1 1Z"
      style={{
        fill: "#ccc",
        fillRule: "nonzero",
      }}
      transform="translate(-180.864 -106.46)"
    />
    <path
      d="M784.92 238.373a11.22 11.22 0 0 0-7.25-5.1 10.283 10.283 0 0 0-1.659-.21l-76.191-68.12a9.015 9.015 0 0 0-5.63-2.69 7.587 7.587 0 0 0-1.27-.04c-4.807.218-8.644 4.233-8.644 9.045a9.06 9.06 0 0 0 2.684 6.435l29.17 25.43 47.9 41.75c.04.786.157 1.567.35 2.33a11.287 11.287 0 0 0 9.67 8.37 11.09 11.09 0 0 0 6.05-1 11.404 11.404 0 0 0 3.88-2.97c.11-.13.21-.25.31-.39a11.313 11.313 0 0 0 .63-12.84ZM654.6 304.023l-.71-28.86-.6-23.95-.41-16.76-.81-32.67c-.424-4.633-4.356-8.221-9.008-8.221a9.049 9.049 0 0 0-6.702 2.971 6.826 6.826 0 0 0-.75.96 8.936 8.936 0 0 0-1.56 6.05l1.78 47.67.89 23.95 1.14 30.55c-.329.449-.626.92-.89 1.41a11.258 11.258 0 0 0-.71 8.84 11.336 11.336 0 0 0 10.725 7.715c1.672 0 3.323-.371 4.835-1.085a11.317 11.317 0 0 0 6.47-10.217c0-3.178-1.34-6.213-3.69-8.353Z"
      style={{
        fill: "#ffb8b8",
        fillRule: "nonzero",
      }}
      transform="translate(-180.864 -106.46)"
    />
    <path
      d="m723.201 222.273-7.071-19.15-11.44-31.02a16.014 16.014 0 0 0-9.95-9.68c-.18-.06-.37-.12-.55-.17a15.99 15.99 0 0 0-13.239 1.82l-38.531 24.44c-7.29 4.613-9.64 14.311-5.27 21.75a276.59 276.59 0 0 0 15.73 24.19c4.49 6.11 12.85 6.76 14.301 16.76h28.409l28.76-25.82-1.149-3.12Z"
      style={{
        fill: "#ccc",
        fillRule: "nonzero",
      }}
      transform="translate(-180.864 -106.46)"
    />
    <circle
      cx={101.165}
      cy={326.682}
      r={23.172}
      style={{
        fill: "#a0616a",
      }}
    />
    <path
      d="M338.916 417.193c.228.181.449.374.663.578a10.38 10.38 0 0 1-.417 15.483c-.26.219-.532.423-.813.614l1.568 52.944a8.08 8.08 0 0 1-5.545 7.867l-.096.021-42.988 4.578c-.313.033-.627.05-.941.05-4.882 0-8.899-4.017-8.899-8.899 0-4.587 3.548-8.461 8.117-8.864l30.645-2.699 5.299-45.851a9.866 9.866 0 0 1-.971-.99 10.38 10.38 0 0 1-2.452-6.699c0-5.694 4.686-10.38 10.38-10.38 2.342 0 4.615.792 6.45 2.247Z"
      style={{
        fill: "#a0616a",
        fillRule: "nonzero",
      }}
      transform="translate(-180.864 -106.46)"
    />
    <path
      d="M139.328 649.174h11.567l5.502-44.613h-17.071l.002 44.613Z"
      style={{
        fill: "#a0616a",
        fillRule: "nonzero",
      }}
    />
    <path
      d="m317.242 751.858 22.78-.001c7.963 0 14.515 6.553 14.515 14.516v.472l-37.294.001-.001-14.988Z"
      style={{
        fill: "#2f2e41",
        fillRule: "nonzero",
      }}
      transform="translate(-180.864 -106.46)"
    />
    <path
      d="m24.366 633.182 9.938 5.917 27.553-35.517-14.668-8.733-22.823 38.333Z"
      style={{
        fill: "#a0616a",
        fillRule: "nonzero",
      }}
    />
    <path
      d="m204.627 734.889 19.572 11.653h.001a14.533 14.533 0 0 1 7.089 12.472c0 2.615-.706 5.181-2.044 7.427l-.241.405-32.044-19.079 7.667-12.878ZM334.488 739.877h-12.952a4.253 4.253 0 0 1-4.221-3.797l-13.057-122.737a1.415 1.415 0 0 0-2.697-.433l-23.556 52.159-41.703 68.002a4.28 4.28 0 0 1-5.329 1.666l-16.661-7.33a4.248 4.248 0 0 1-1.934-6.064l38.337-64.192 11.757-54.085c-3.698-23.455 12.155-58.326 12.316-58.676l.079-.171 43.667-17.068.219.269c17.221 56.513 27.715 117.332 19.967 208.567a4.29 4.29 0 0 1-4.232 3.89Z"
      style={{
        fill: "#2f2e41",
        fillRule: "nonzero",
      }}
      transform="translate(-180.864 -106.46)"
    />
    <path
      d="m273.107 549.934-.203-.427c-.097-.205-9.807-20.684-17.292-42.572a32.298 32.298 0 0 1 2.239-25.873 32.647 32.647 0 0 1 20.812-16.005c15.675-3.757 31.906 4.618 37.928 19.57 5.937 14.847 4.045 30.695 2.264 43.157l-.035.25-.227.109-45.486 21.791Z"
      style={{
        fill: "#3f3d56",
        fillRule: "nonzero",
      }}
      transform="translate(-180.864 -106.46)"
    />
    <path
      d="M310.124 420.054H273.33v-16.039c8.076-3.208 15.978-5.937 20.755 0 8.799 0 16.039 7.24 16.039 16.039Z"
      style={{
        fill: "#2f2e41",
        fillRule: "nonzero",
      }}
      transform="translate(-180.864 -106.46)"
    />
    <path
      d="M271.293 401.185c-21.996 0-28.153 27.571-28.153 43.125 0 8.675 3.923 11.777 10.088 12.827l2.177-11.611 5.099 12.111c1.731.009 3.551-.025 5.436-.06l1.729-3.56 3.856 3.496c15.44.023 27.92 2.274 27.92-13.203 0-15.554-5.399-43.125-28.152-43.125Z"
      style={{
        fill: "#2f2e41",
        fillRule: "nonzero",
      }}
      transform="translate(-180.864 -106.46)"
    />
    <path
      d="M373.864 767.933h-192c-.548 0-1-.451-1-1 0-.548.452-1 1-1h192c.549 0 1 .452 1 1 0 .549-.451 1-1 1Z"
      style={{
        fill: "#ccc",
        fillRule: "nonzero",
      }}
      transform="translate(-180.864 -106.46)"
    />
    <path
      d="M377.474 438.933h-43c-5.209-.006-9.494-4.29-9.5-9.5v-43c.006-5.209 4.291-9.494 9.5-9.5h43c5.21.006 9.494 4.291 9.5 9.5v43c-.006 5.21-4.29 9.494-9.5 9.5Z"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-180.864 -106.46)"
    />
    <path
      d="M377.474 438.933h-43c-5.209-.006-9.494-4.29-9.5-9.5v-43c.006-5.209 4.291-9.494 9.5-9.5h43c5.21.006 9.494 4.291 9.5 9.5v43c-.006 5.21-4.29 9.494-9.5 9.5Zm-43-60c-4.112.005-7.495 3.388-7.5 7.5v43c.005 4.113 3.388 7.496 7.5 7.5h43c4.113-.004 7.496-3.387 7.5-7.5v-43c-.004-4.112-3.387-7.495-7.5-7.5h-43Z"
      style={{
        fill: "#3f3d56",
        fillRule: "nonzero",
      }}
      transform="translate(-180.864 -106.46)"
    />
    <path
      d="M369.974 421.433h-28a3.018 3.018 0 0 1-3-3v-21a3.018 3.018 0 0 1 3-3h7.883a2.986 2.986 0 0 1 2.555 1.427l2.198 3.573h15.364a3.017 3.017 0 0 1 3 3v16a3.017 3.017 0 0 1-3 3Z"
      className="fill-primary"
      transform="translate(-180.864 -106.46)"
    />
    <path
      d="M369.064 432.042c.14.256.269.52.387.791.586 1.323.889 2.754.889 4.201 0 4.527-2.961 8.556-7.281 9.907a9.652 9.652 0 0 1-.988.25l-19.1 49.405a8.08 8.08 0 0 1-8.163 5.098l-.096-.018-41.397-12.462a8.919 8.919 0 0 1-6.333-8.521c0-4.881 4.017-8.898 8.899-8.898.923 0 1.841.144 2.72.426l29.291 9.404 22.676-40.202a9.93 9.93 0 0 1-.511-1.289 10.366 10.366 0 0 1-.473-3.098c0-5.694 4.686-10.38 10.38-10.38 3.784 0 7.279 2.069 9.1 5.386Z"
      style={{
        fill: "#a0616a",
        fillRule: "nonzero",
      }}
      transform="translate(-180.864 -106.46)"
    />
  </svg>
);
export default ServiceWorkIcon;
