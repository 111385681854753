import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import axios from "axios";

import { endpoints } from "../../settings/endpoints";
import { GET, PATCH } from "../../utils/apiRequests";

export const FETCH_CONTEST_REDUCER = createAsyncThunk(
  "ContestReducer/fetchContestReducer",
  async (slug, { rejectWithValue }) => {
    try {
      const response = await GET(`${endpoints.contest.list}/${slug}`);
      return response.data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        return rejectWithValue(e?.response?.data);
      }
    }
  },
);

export const CLOSE_CONTEST_INTRO_VIDEO_REDUCER = createAsyncThunk(
  "ContestReducer/closeContestIntroVideoReducer",
  async (slug, { rejectWithValue }) => {
    try {
      const response = await PATCH(`${endpoints.contest.list}/${slug}`, {
        show_intro_video: false,
      });
      return response.data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        return rejectWithValue(e?.response?.data);
      }
    }
  },
);

const initialData = {
  error: null,
  data: {},
  updateLeaderboard: false,
  loading: false,
};

export const ContestReducer = createSlice({
  name: "ContestReducer",
  initialState: { ...initialData },
  reducers: {
    RESET_CONTEST_REDUCER: (state) => {
      state.error = initialData.error;
      state.data = initialData.data;
    },
    UPDATE_CONTEST_LEADERBOARD_REDUCER: (state, { payload }) => {
      state.updateLeaderboard = payload;
    },
    UPDATE_CONTEST_DATA_ITEM_REDUCER: (state, { payload }) => {
      const { id, value } = payload;
      if (!id) {
        return;
      }

      state.data[id] = value;
    },
    UPDATE_CONTEST_DATA_ITEM_REDUCERS: (state, { payload = {} }) => {
      state.data = {
        ...state.data,
        ...payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(FETCH_CONTEST_REDUCER.pending, (state) => {
      state.error = null;
      state.loading = true;
      state.data = {};
    });
    builder.addCase(CLOSE_CONTEST_INTRO_VIDEO_REDUCER.fulfilled, (state) => {
      state.data = {
        ...state.data,
        user_contest_settings: {
          ...state.data.user_contest_settings,
          show_intro_video: false,
        },
      };
      state.loading = false;
    });
    builder.addCase(FETCH_CONTEST_REDUCER.fulfilled, (state, { payload }) => {
      state.data = {
        ...payload,
      };
      state.loading = false;
    });
    builder.addMatcher(
      isAnyOf(
        FETCH_CONTEST_REDUCER.rejected,
        CLOSE_CONTEST_INTRO_VIDEO_REDUCER.rejected,
      ),
      (state, { payload }) => {
        state.loading = false;
        state.error = payload || {};
      },
    );
  },
});

export const {
  UPDATE_CONTEST_DATA_ITEM_REDUCER,
  UPDATE_CONTEST_LEADERBOARD_REDUCER,
  UPDATE_CONTEST_DATA_ITEM_REDUCERS,
  RESET_CONTEST_REDUCER,
} = ContestReducer.actions;
export default ContestReducer.reducer;
