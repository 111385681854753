import React from "react";
import CrossIcon from "../../assets/icons/component-icons/CrossIcon";

export default function BadgeSimple({
  tag,
  title,
  onClick,
  budgeClass = "rounded-full bg-primary-extra-light px-2.5 py-1 text-sm text-primary cursor-pointer",
}) {
  return (
    <div
      className={`inline-flex items-center ${budgeClass}`}
      onClick={() => onClick?.(tag)}
      onKeyDown={() => {}}
      title={title}
    >
      {tag.name}
      {onClick && (
        <CrossIcon
          data-testid="cross-icon"
          className="stroke-primary w-2 h-2 ml-1"
        />
      )}
    </div>
  );
}
