import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import BookmarkedIcon from "../../assets/icons/component-icons/resource-card/BookmarkedIcon";
import BookmarksIcon from "../../assets/icons/component-icons/resource-card/BookmarksIcon";
import { endpoints } from "../../settings/endpoints";
import { DELETE, POST } from "../../utils/apiRequests";
import { GA_ACTIONS, GA_CATEGORIES, gaEventPush } from "../../utils/gaEvents";
import IconResourceButtonComponent from "../Icons/IconResourceButtonComponent";
import TooltipComponent from "../Tooltip/TooltipComponent";

// This component displays a resource button that allows the user to add a resource to or remove it from the bookmarks list.
const BookmarkButtonComponent = ({
  id,
  defaultBookmarked,
  hideBookmarkButton,
  useCssFlex,
}) => {
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleToggleBookmark = useCallback(
    async (event) => {
      // Required to avoid opening a page with the resource details when clicking on the icon on the Resources List
      event.stopPropagation();

      setLoading(true);
      const updatedEndpoint = `${endpoints.dict.resources.index}/${id}${endpoints.dict.resources.bookmarks}`;
      setIsBookmarked(!isBookmarked);
      // it sends every addition and removal of a resource from the bookmarks list to GA.
      if (isBookmarked) {
        gaEventPush(
          GA_CATEGORIES.RESOURCES,
          GA_ACTIONS.REMOVED_FROM_BOOKMARK,
          id,
        );
        await DELETE(updatedEndpoint);
        setLoading(false);
        return;
      }
      gaEventPush(GA_CATEGORIES.RESOURCES, GA_ACTIONS.ADDED_TO_BOOKMARK, id);
      await POST(updatedEndpoint);
      setLoading(false);
    },
    [isBookmarked, id],
  );

  useEffect(() => {
    setIsBookmarked(defaultBookmarked);
  }, [defaultBookmarked]);

  if (hideBookmarkButton) {
    return null;
  }
  return (
    <TooltipComponent
      title={isBookmarked ? "Remove from bookmarks" : "Add to bookmarks"}
    >
      <IconResourceButtonComponent
        onClick={handleToggleBookmark}
        disabled={loading}
        Icon={isBookmarked ? BookmarkedIcon : BookmarksIcon}
        useCssFlex={useCssFlex}
      />
    </TooltipComponent>
  );
};

BookmarkButtonComponent.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  defaultBookmarked: PropTypes.bool.isRequired,
  hideBookmarkButton: PropTypes.bool,
  useCssFlex: PropTypes.bool,
};

export default React.memo(BookmarkButtonComponent);
