import React from "react";

const SingleItemSkeletonComponent = ({ loading }) => {
  if (!loading) {
    return null;
  }
  return (
    <div className="h-full bg-gray absolute left-0 right-0 bottom-0 top-0" />
  );
};

export default SingleItemSkeletonComponent;
