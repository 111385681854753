import { useMemo } from "react";
import { useSelector } from "react-redux";

const useShortSideMenuClasses = (
  defaultSideClasses = "",
  shortSideClasses = "",
  withControls,
) => {
  const { shortSideMenu, shortSideMenuControls } = useSelector(
    (state) => state.MainReducer,
  );

  const classes = useMemo(() => {
    const condition = withControls ? shortSideMenuControls : shortSideMenu;
    const className = condition ? shortSideClasses : defaultSideClasses;

    return `duration-default ${className}`;
  }, [
    shortSideMenuControls,
    withControls,
    shortSideMenu,
    shortSideClasses,
    defaultSideClasses,
  ]);

  return classes;
};

export default useShortSideMenuClasses;
