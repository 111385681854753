import { Auth } from "aws-amplify";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AuthorizationContainerComponent from "../../../components/Containers/AuthorizationContainerComponent";
import FormValidationComponent from "../../../components/FormValidation/FormValidationComponent";
import InputComponent from "../../../components/Inputs/InputComponent";
import {
  CURRENT_AUTHORIZATION_FORM,
  NEW_USER_AUTHORIZATION_FORM,
} from "../../../constants/constants";
import { PASSWORD_FIELD_VALIDATION } from "../../../constants/formFieldValidations";
import TEST_IDS from "../../../constants/testIds";
import {
  UPDATE_LOGIN_CURRENT_FORM_STEP_REDUCER,
  UPDATE_LOGIN_ERROR_REDUCER,
  UPDATE_LOGIN_LOADING_REDUCER,
  UPDATE_LOGIN_PASSWORD_REDUCER,
} from "../../../store/reducers/LoginReducer";

const NewPasswordFormComponent = () => {
  const { data } = useSelector((state) => state.LoginReducer);

  const dispatch = useDispatch();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [passwordValue, setPasswordValue] = useState("");
  const [confirmPasswordValue, setConfirmPasswordValue] = useState("");

  const onSubmit = useCallback(async () => {
    if (passwordValue !== confirmPasswordValue) {
      dispatch(UPDATE_LOGIN_ERROR_REDUCER("Passwords must match"));
      return;
    }

    dispatch(UPDATE_LOGIN_ERROR_REDUCER());
    dispatch(UPDATE_LOGIN_LOADING_REDUCER(true));

    try {
      await Auth.completeNewPassword(data.user, passwordValue);
      dispatch(UPDATE_LOGIN_PASSWORD_REDUCER(passwordValue));
      dispatch(
        UPDATE_LOGIN_CURRENT_FORM_STEP_REDUCER(
          CURRENT_AUTHORIZATION_FORM.SUCCESS_RESET_PASSWORD,
        ),
      );
    } catch (error) {
      dispatch(UPDATE_LOGIN_ERROR_REDUCER(error.message));
    }

    setPasswordValue("");
    setConfirmPasswordValue("");
    dispatch(UPDATE_LOGIN_LOADING_REDUCER());
  }, [passwordValue, confirmPasswordValue, data]);

  const handleUpdateSubmitStatus = useCallback((newVal) => {
    setSubmitDisabled(newVal);
  }, []);
  return (
    <AuthorizationContainerComponent
      formStep={NEW_USER_AUTHORIZATION_FORM.NEW_PASSWORD_REQUIRED}
      title="Change Password"
      submitDisabled={submitDisabled}
      subtitle="Please enter your new password below."
      submitButton={{
        title: "Send",
        onSubmit,
      }}
    >
      <div className="flex flex-col sm:flex-row md:flex-col gap-5">
        <InputComponent
          required
          placeholder="Enter your password"
          label="New password"
          type="password"
          value={passwordValue}
          onChange={(value) => setPasswordValue(value)}
          testId={TEST_IDS.INPUTS.PASSWORD}
        />
        <InputComponent
          required
          placeholder="Enter new password"
          label="Enter New Password Again"
          type="password"
          autoComplete="new-password"
          value={confirmPasswordValue}
          onChange={(value) => setConfirmPasswordValue(value)}
          testId={TEST_IDS.INPUTS.CONFIRM_PASSWORD}
        />
        <FormValidationComponent
          onUpdateSubmitStatus={handleUpdateSubmitStatus}
          value={passwordValue}
          validationParameters={PASSWORD_FIELD_VALIDATION}
        />
      </div>
    </AuthorizationContainerComponent>
  );
};

export default React.memo(NewPasswordFormComponent);
