import React, { useRef } from "react";

import TEST_IDS from "../../constants/testIds";
import useUpdateItemRefHeight from "../../hooks/useUpdateItemRefHeight";
import VersionComponent from "../Version/VersionComponent";

const FooterComponent = () => {
  const footerRef = useRef();
  useUpdateItemRefHeight(footerRef, "footerHeight");

  return (
    <div
      ref={footerRef}
      data-testid={TEST_IDS.FOOTER}
      className="border-t border-gray text-right text-cement text-[8px] lg:text-minimum lg:pt-1 pb-safe px-6 bg-white flex justify-between"
    >
      <VersionComponent />
      <span>
        The information presented on this site is confidential and proprietary.
        Please do not share or distribute outside of your organization.
      </span>
    </div>
  );
};

export default FooterComponent;
