import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo } from "react";

import { RESOURCE_REDIRECT_TYPES } from "../../constants/singleItemTypes";
import { GA_ACTIONS, GA_CATEGORIES, gaEventPush } from "../../utils/gaEvents";
import markCompleted from "../../utils/markCompleted";

const RedirectContainerComponent = ({
  url,
  isResource,
  isChallenge,
  children,
  className = "",
  onClick = () => {},
  contentType,
  id,
  userDataVersion,
  internalLink,
}) => {
  const isMakeComplete = useMemo(
    () =>
      (isResource || isChallenge) &&
      RESOURCE_REDIRECT_TYPES.includes(contentType),
    [isResource, isChallenge, contentType],
  );
  const target = useMemo(
    () => (internalLink ? "_self" : "_blank"),
    [internalLink],
  );

  const handleReload = useCallback(() => {
    window.location.reload();
  }, []);

  const handleOpenUrl = useCallback(async () => {
    if (isMakeComplete) {
      gaEventPush(
        isResource ? GA_CATEGORIES.RESOURCES : GA_CATEGORIES.CHALLENGES,
        GA_ACTIONS.OPENED,
        id,
      );
      await markCompleted(id, userDataVersion);
    }

    onClick();

    if (isMakeComplete) {
      if (!internalLink) {
        handleReload();
      }
    }
  }, [
    isMakeComplete,
    onClick,
    isResource,
    id,
    isChallenge,
    url,
    userDataVersion,
    internalLink,
  ]);

  useEffect(() => {
    const handlePageShow = (event) => {
      if (event.persisted && url && isMakeComplete) {
        handleReload();
      }
    };

    window.addEventListener("pageshow", handlePageShow);

    return () => {
      window.removeEventListener("pageshow", handlePageShow);
    };
  }, [url, isMakeComplete]);

  if (!url) {
    return children;
  }

  return (
    <a
      className={`${className} cursor-pointer group`}
      onClick={handleOpenUrl}
      href={url}
      target={target}
    >
      <div className="pointer-events-none">{children}</div>
    </a>
  );
};
RedirectContainerComponent.propTypes = {
  id: PropTypes.string,
  contentType: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
  isChallenge: PropTypes.bool,
  isResource: PropTypes.bool,
  url: PropTypes.string,
  internalLink: PropTypes.bool,
  userDataVersion: PropTypes.number,
};

export default React.memo(RedirectContainerComponent);
