import { Auth } from "aws-amplify";
import React, { useCallback, useEffect, useMemo } from "react";
import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import AuthorizationContainerComponent from "../../../components/Containers/AuthorizationContainerComponent";
import InputComponent from "../../../components/Inputs/InputComponent";
import {
  CURRENT_AUTHORIZATION_FORM,
  NEW_USER_AUTHORIZATION_FORM,
} from "../../../constants/constants";
import TEST_IDS from "../../../constants/testIds";
import { AuthContext } from "../../../contexts/AuthProvider";
import useUserAuthorization from "../../../hooks/useUserAuthorization";
import { endpoints } from "../../../settings/endpoints";
import {
  UPDATE_LOGIN_CURRENT_FORM_STEP_REDUCER,
  UPDATE_LOGIN_EMAIL_REDUCER,
  UPDATE_LOGIN_ERROR_REDUCER,
  UPDATE_FORGOT_PASSWORD_LOADING_REDUCER,
  UPDATE_LOGIN_LOADING_REDUCER,
  UPDATE_LOGIN_USER_REDUCER,
} from "../../../store/reducers/LoginReducer";
import { NOT_AUTH_POST } from "../../../utils/apiRequests";
import getCredentialsFromUrl from "../../../utils/getCredentialsFromUrl";
import getErrorMessage from "../../../utils/getErrorMessage";

const LoginFormComponent = () => {
  const dispatch = useDispatch();
  const handleUserAuthorization = useUserAuthorization();
  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const location = useLocation();
  const submitButtonIsDisabled = useMemo(
    () => !passwordValue || !emailValue,
    [emailValue, passwordValue],
  );

  const authContext = useContext(AuthContext);

  const handleResetData = useCallback(() => {
    setPasswordValue("");
    setEmailValue("");
  }, []);

  const handleNewUser = useCallback(
    (user, email) => {
      dispatch(UPDATE_LOGIN_USER_REDUCER(user));
      dispatch(UPDATE_LOGIN_EMAIL_REDUCER(email));
      dispatch(
        UPDATE_LOGIN_CURRENT_FORM_STEP_REDUCER(
          NEW_USER_AUTHORIZATION_FORM.NEW_PASSWORD_REQUIRED,
        ),
      );
      dispatch(UPDATE_LOGIN_LOADING_REDUCER());
      setPasswordValue("");
    },
    [dispatch],
  );

  const onSubmit = useCallback(
    async (evt) => {
      evt.preventDefault();
      dispatch(UPDATE_LOGIN_ERROR_REDUCER());
      dispatch(UPDATE_LOGIN_LOADING_REDUCER(true));

      try {
        const updatedEmail = emailValue.toLowerCase().replace(/\s/g, "");
        const user = await Auth.signIn(updatedEmail, passwordValue);

        if (
          user?.challengeName ===
          NEW_USER_AUTHORIZATION_FORM.NEW_PASSWORD_REQUIRED
        ) {
          handleNewUser(user, updatedEmail);
          return;
        }

        await handleUserAuthorization();
        await handleResetData();
      } catch (error) {
        console.log(error);
        dispatch(UPDATE_LOGIN_ERROR_REDUCER(getErrorMessage(error)));
      }

      dispatch(UPDATE_LOGIN_LOADING_REDUCER());
    },
    [
      emailValue,
      passwordValue,
      authContext,
      handleUserAuthorization,
      handleNewUser,
    ],
  );

  const handleAmplifyReset = useCallback(async () => {
    try {
      await Auth.forgotPassword(emailValue);
      dispatch(UPDATE_LOGIN_EMAIL_REDUCER(emailValue));
      dispatch(
        UPDATE_LOGIN_CURRENT_FORM_STEP_REDUCER(
          CURRENT_AUTHORIZATION_FORM.RESET_PASSWORD,
        ),
      );
      handleResetData();
    } catch (error) {
      dispatch(UPDATE_LOGIN_ERROR_REDUCER(error.message));
    }
  }, [dispatch, emailValue]);

  const handleForgotPassword = useCallback(async () => {
    if (!emailValue) {
      dispatch(UPDATE_LOGIN_ERROR_REDUCER("User email is required"));
      return;
    }
    dispatch(UPDATE_FORGOT_PASSWORD_LOADING_REDUCER(true));
    dispatch(UPDATE_LOGIN_ERROR_REDUCER());

    try {
      await NOT_AUTH_POST(endpoints.triggerResendCode, {
        email: emailValue,
      });
      await handleAmplifyReset();
    } catch (_) {
      dispatch(
        UPDATE_LOGIN_ERROR_REDUCER(
          "This user has not yet activated their account. We have sent a new password to your email.",
        ),
      );
    }

    dispatch(UPDATE_FORGOT_PASSWORD_LOADING_REDUCER());
  }, [emailValue, dispatch]);

  useEffect(() => {
    // is used when the user first authorizes
    const { email, password } = getCredentialsFromUrl(location.search);

    if (!email) {
      return;
    }

    setEmailValue(email);
    setPasswordValue(password);
  }, [location]);

  return (
    <AuthorizationContainerComponent
      title="Log in"
      subtitle="Welcome back! Please enter your details."
      formStep={CURRENT_AUTHORIZATION_FORM.LOGIN}
      submitDisabled={submitButtonIsDisabled}
      submitButton={{
        title: "Sign in",
        onSubmit,
      }}
      helpButton={{
        title: "Forgot password",
        onClick: handleForgotPassword,
      }}
    >
      <div className="flex flex-col sm:flex-row md:flex-col gap-5 mb-6">
        <InputComponent
          placeholder="Enter your email"
          label="Email"
          value={emailValue}
          required
          testId={TEST_IDS.INPUTS.EMAIL}
          onChange={(value) => setEmailValue(value)}
        />
        <InputComponent
          placeholder="Enter your password"
          label="Password"
          type="password"
          testId={TEST_IDS.INPUTS.PASSWORD}
          value={passwordValue}
          required
          onChange={(value) => setPasswordValue(value)}
        />
      </div>
    </AuthorizationContainerComponent>
  );
};

export default LoginFormComponent;
