const getBrowser = () => {
  const ua = navigator.userAgent.toLowerCase();

  switch (true) {
    // check edge regulax expression
    case /edge\/\d+/.test(ua):
      return "Microsoft Edge (Legacy)";
    case ua.includes("edg"):
      return "Microsoft Edge (Chromium)";
    // check internet explorer regulax expressions
    case /iemobile[/]?[\w.]+/.test(ua):
      return "Internet Explorer Mobile";
    case /msie[ ]\w+/.test(ua):
      return "Internet Explorer";
    // check opera regulax expression
    case /opr[/]?[\w.]+/.test(ua):
      return "Opera";
    // check chrome regulax expression
    case /chrome[/]?[\w.]+/.test(ua):
      return "Google Chrome";
    // check mozilla regulax expression
    case /firefox[/][\w.]+/.test(ua):
      return "Mozilla Firefox";
    // check safari regulax expressions
    case /version[ /]\w+.*?safari[ /][\w.]+/.test(ua):
    case /webkit[ /][\w.]+.*?safari[ /][\w.]+/.test(ua):
      return "Safari";

    default:
      return "Unknown Browser";
  }
};

export default getBrowser;
