import PropTypes from "prop-types";
import React from "react";
import { useMemo } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import TEST_IDS from "../../constants/testIds";
import getInitialsTitle from "../../utils/getInitialsTitle";

const AvatarComponent = ({ imageUrl, userName, className = "w-7 h-7" }) => {
  // if the avatar does not exist, the component displays the initials
  const initials = useMemo(() => getInitialsTitle(userName), [userName]);
  const updatedClassName = useMemo(() => {
    let updatedClassName = `overflow-hidden text-primary-dark rounded-full font-medium flex items-center justify-center text-xs ring-2 ring-white `;
    updatedClassName += `${className} `;
    updatedClassName += imageUrl ? "bg-cover" : "bg-primary";
    return updatedClassName;
  }, [className, imageUrl]);

  return (
    <div className={updatedClassName}>
      {imageUrl ? (
        <LazyLoadImage
          src={imageUrl}
          alt={userName}
          title={userName}
          data-testid={TEST_IDS.HEADER.AVATAR}
          className={"h-full w-full object-cover"}
          wrapperClassName={"w-full h-full"}
        />
      ) : (
        <span
          data-testid={TEST_IDS.HEADER.INITIALS}
          className="text-white font-medium text-normal text-sm"
        >
          {initials}
        </span>
      )}
    </div>
  );
};

AvatarComponent.propTypes = {
  className: PropTypes.string,
  userName: PropTypes.string,
  imageUrl: PropTypes.string,
};
export default AvatarComponent;
