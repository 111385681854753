import React from "react";

import TEST_IDS from "../../constants/testIds";
import CardContainerComponent from "../Cards/CardContainerComponent";
import SingleItemSkeletonComponent from "../SingleItem/SingleItemSkeletonComponent";
import TextSkeletonComponent from "../Skeletons/TextSkeletonComponent";

const SingleItemCardSkeletonComponent = () => {
  return (
    <CardContainerComponent
      paddingClassName=""
      className="overflow-hidden"
      testId={TEST_IDS.SKELETON}
    >
      <div className="w-full relative">
        <div className="h-[180px]">
          <SingleItemSkeletonComponent loading />
        </div>
      </div>
      <div className={`py-3 px-4 border-y border-gray min-h-[115px]`}>
        <TextSkeletonComponent loading />
        <TextSkeletonComponent loading />
      </div>
      <div className="h-9 bg-gray-light"></div>
    </CardContainerComponent>
  );
};

export default React.memo(SingleItemCardSkeletonComponent);
