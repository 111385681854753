import PropTypes from "prop-types";
import React from "react";

const HelpTextComponent = ({ message, className = "", testId }) => {
  return (
    <p
      className={`text-xs my-2 rounded-small  ${className}`}
      data-testid={testId}
    >
      {message}
    </p>
  );
};

HelpTextComponent.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
  testId: PropTypes.string,
};
export default React.memo(HelpTextComponent);
