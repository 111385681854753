import React from "react";

import TEST_IDS from "../../constants/testIds";
import HelpTextComponent from "../HelpText/HelpTextComponent";

const ValidationErrorComponent = ({ message }) => {
  if (!message) {
    return null;
  }
  return (
    <HelpTextComponent
      className="text-alert-dark bg-alert-light py-2 px-4"
      testId={TEST_IDS.CONTAINER}
      message={message}
    />
  );
};

export default ValidationErrorComponent;
