import React, { useContext, useMemo } from "react";
import ServiceWorkIcon from "../../assets/icons/component-icons/ServiceWorkIcon";
import { DEFAULT_PADDING_CLASS_NAMES } from "../../constants/classNamesConstants";
import { ERRORS } from "../../constants/errors";
import { CompanyDataContext } from "../../contexts/CompanyDataProvider";
import ErrorBoundaryComponent from "../ErrorBoundary/ErrorBoundaryComponent";

const ServiceWorkComponent = () => {
  const { error } = useContext(CompanyDataContext);
  const showMessage = useMemo(
    () => error?.code === ERRORS.ERR_BAD_RESPONSE_CODE,
    [error],
  );

  if (!showMessage) {
    return null;
  }
  return (
    <section
      className={`fixed left-0 top-0 right-0 bottom-0 flex justify-center items-center bg-white z-50 ${DEFAULT_PADDING_CLASS_NAMES}`}
    >
      <ErrorBoundaryComponent
        subtitle={"We Apologize for the Inconvenience"}
        description={
          "Oops! It seems like our service is currently undergoing maintenance or experiencing technical issues. Please bear with us as we work diligently to get things up and running smoothly. We appreciate your patience and understanding during this time. Feel free to check back later, and thank you for choosing us!"
        }
        title={"Service Unavailable"}
        Icon={ServiceWorkIcon}
        iconWidth="w-52 md:w-96"
        containerClassName="max-w-[1200px] text-center"
      />
    </section>
  );
};

export default React.memo(ServiceWorkComponent);
