import { combineReducers, configureStore } from "@reduxjs/toolkit";

import ChallengesReducer from "./reducers/ChallengesReducer";
import ContestReducer from "./reducers/ContestReducer";
import CurriculumReducer from "./reducers/CurriculumReducer";
import LoginReducer from "./reducers/LoginReducer";
import MainReducer from "./reducers/MainReducer";
import MetersReducer from "./reducers/MetersReducer";
import MilestoneReducer from "./reducers/MilestoneReducer";
import MyTeamReducer from "./reducers/MyTeamReducer";
import NewsReducer from "./reducers/NewsReducer";
import UserReducer from "./reducers/UserReducer";
import ErrorReducer from "./reducers/error/reducer";
import SecurityReducer from "./reducers/security/reducer";
import ViewTypeReducer from "./reducers/viewType/reducer";
import { servicesApi } from "./services";

const rootReducer = combineReducers({
  MetersReducer,
  LoginReducer,
  CurriculumReducer,
  MyTeamReducer,
  MainReducer,
  NewsReducer,
  ContestReducer,
  UserReducer,
  ChallengesReducer,
  MilestoneReducer,
  Security: SecurityReducer,
  Error: ErrorReducer,
  ViewType: ViewTypeReducer,
  [servicesApi.reducerPath]: servicesApi.reducer,
});

export const setupStore = (preloadedState) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(servicesApi.middleware),
  });
};
