import React from "react";

import getGeneratedImgUrl from "../../utils/getGeneratedImgUrl";
import BrandCardComponent from "../Cards/BrandCardComponent";

const BrandsListComponent = ({ list = [] }) => {
  if (!list?.length) {
    return null;
  }
  return (
    <>
      {list.map((brand, key) => (
        <BrandCardComponent
          key={key}
          name={brand.brand_name}
          previewImage={getGeneratedImgUrl(brand?.preview_image)}
          slug={brand.slug}
          url={brand.url}
          internalLink={brand.internal_link}
          index={key}
          brandIcon={getGeneratedImgUrl(brand?.icon)}
          changeIconColor={brand?.icon?.change_color}
        />
      ))}
    </>
  );
};

export default React.memo(BrandsListComponent);
