import React, { useEffect, useRef, useState } from "react";
import useScreenWidth from "../../hooks/useScreenWidth";
import useVisibleElements from "../../hooks/useVisibleElements";
import BadgeSimple from "../Badge/BadgeSimple";

const SummaryElementContainer = ({ elements, containerClass }) => {
  const [containerWidth, setContainerWidth] = useState();
  const elementRef = useRef(null);
  const { visibleElements, hiddenElementsCount } = useVisibleElements(
    elements,
    containerWidth,
    "text-nowrap px-2 text-xs leading-5 font-bold",
  );
  const screenWidth = useScreenWidth();

  useEffect(() => {
    if (elementRef.current) {
      setContainerWidth(elementRef.current.offsetWidth);
    }
  }, [elements, screenWidth]);

  return (
    elements?.length > 0 && (
      <div
        ref={elementRef}
        className={`flex items-center gap-1 ${containerClass}`}
      >
        {visibleElements.map((element) => (
          <BadgeSimple
            key={element.id}
            tag={element}
            title="Display element"
            budgeClass="rounded-3xl shrink-0 text-nowrap px-2 py-0.5 text-xs leading-5 font-bold text-info bg-info/10"
          />
        ))}
        {hiddenElementsCount > 0 && (
          <BadgeSimple
            tag={{ name: `+${hiddenElementsCount}` }}
            title="Display number of hidden elements"
            budgeClass="rounded-3xl px-2 py-0.5 text-xs leading-5 font-bold text-info bg-info/10"
          />
        )}
      </div>
    )
  );
};

export default SummaryElementContainer;
