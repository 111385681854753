import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useWindowSize } from "react-use";
import { RESPONSIVE_BREAKPOINTS } from "../../constants/constants";
import TEST_IDS from "../../constants/testIds";
import { SEARCH_RESULTS_ROUTE } from "../../settings/routes";
import { SearchInput } from "../Inputs/SearchInput";

const SearchBoxComponent = () => {
  const [searchPhrase, setSearchPhrase] = useState("");
  const { width } = useWindowSize();

  const navigate = useNavigate();

  const onChange = useCallback((evt) => {
    setSearchPhrase(evt.target.value);
  }, []);

  const handleSubmit = useCallback(() => {
    if (searchPhrase) {
      navigate(`${SEARCH_RESULTS_ROUTE}?query=${searchPhrase}`);
    }
  }, [navigate, searchPhrase]);

  const onKeyDown = useCallback(
    (evt) => {
      evt.stopPropagation();
      if (evt.key === "Enter") {
        handleSubmit();
      }
    },
    [handleSubmit],
  );

  const onBlur = useCallback(() => {
    if (width < RESPONSIVE_BREAKPOINTS.SM) {
      handleSubmit();
    }
  }, [handleSubmit, width]);

  return (
    <SearchInput
      value={searchPhrase}
      placeholder="Search for..."
      makeWiderOnHover={true}
      className="px-4"
      onKeyDown={onKeyDown}
      onChange={onChange}
      onBlur={onBlur}
      testId={TEST_IDS.INPUTS.SEARCH}
    />
  );
};

export default SearchBoxComponent;
