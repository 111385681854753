import createDOMPurify from "dompurify";
import React, { useContext } from "react";
import { Link } from "react-router-dom";

import FullScreenLoaderComponent from "../../components/Loaders/FullScreenLoaderComponent";
import ServiceWorkComponent from "../../components/ServiceWork/ServiceWorkComponent";
import PoweredByComponent from "../../components/SideMenu/components/PoweredByComponent";
import { CompanyDataContext } from "../../contexts/CompanyDataProvider";

import StaticBackgroundContainerComponent from "./StaticBackgroundContainerComponent";

const StaticContainerComponent = ({ children }) => {
  const companyContextData = useContext(CompanyDataContext);
  const { logoUrl, loginPageTitle, shortDescription, footerHTML } =
    companyContextData.data;

  if (!companyContextData || companyContextData.isLoading) {
    return <FullScreenLoaderComponent />;
  }

  return (
    <>
      <div className="min-h-screen sm:min-h-0 sm:h-custom-screen w-screen flex flex-col md:flex-row font-inter">
        <div className="h-fit bg-background-light text-dark flex flex-col items-center relative p-4 justify-start overflow-auto md:h-full sm:p-6 md:w-2/3 xl:w-1/2">
          <div className="flex-1 w-full flex flex-col items-center justify-center">
            <Link to="/" title="Go to dashboard">
              <div
                className="w-60 h-24 bg-contain bg-no-repeat bg-center"
                style={{ backgroundImage: `url("${logoUrl}")` }}
              />
            </Link>
            {children}
          </div>
          <div className=" bottom-0 mt-8">
            <PoweredByComponent isColumn imageHeightClassName="h-20" />
          </div>
        </div>
        <StaticBackgroundContainerComponent>
          <div className="relative z-20 w-full flex-grow flex flex-col justify-center p-4 sm:p-6 md:w-auto ">
            <div
              dangerouslySetInnerHTML={{
                __html: createDOMPurify.sanitize(loginPageTitle),
              }}
            />
            <div className="h-[8px] w-[14em] bg-white mt-10 ml-auto mr-auto"></div>
            <div
              className="mt-10"
              dangerouslySetInnerHTML={{
                __html: createDOMPurify.sanitize(shortDescription),
              }}
            />
          </div>
          <div
            className="bg-primary-light z-20 bottom-0 left-0 w-full py-3.5 px-7"
            dangerouslySetInnerHTML={{
              __html: createDOMPurify.sanitize(footerHTML),
            }}
          />
        </StaticBackgroundContainerComponent>
        <ServiceWorkComponent />
      </div>
    </>
  );
};

export default StaticContainerComponent;
