import React from "react";
import { Link } from "react-router-dom";

import { LIST_ITEM_CLASS_NAMES } from "../../../constants/constants";
import TEST_IDS from "../../../constants/testIds";

const UserMenuItemComponent = ({ path, title, onClick = () => {} }) => {
  return (
    <Link to={path} key={path} title={title}>
      <li
        data-testid={TEST_IDS.HEADER.LIST_ITEM}
        className={LIST_ITEM_CLASS_NAMES}
        onClick={onClick}
      >
        {title}
      </li>
    </Link>
  );
};

export default React.memo(UserMenuItemComponent);
