import React from "react";

const GradientComponent = ({ className = "" }) => {
  return (
    <div
      className={`absolute w-full h-full left-0 top-0 duration-default bg-gradient-to-l  from-[#00000045] to-primary opacity-3 group-hover:opacity-[0.25] ${className}`}
    />
  );
};

export default React.memo(GradientComponent);
