import PropTypes from "prop-types";
import React from "react";
import DashboardCardContainerComponent from "../Containers/DashboardCardContainerComponent";
import SingleItemCardSkeletonComponent from "../Skeletons/SingleItemCardSkeletonComponent";

const ResourceCardListSkeletonComponent = ({ loading, children }) => {
  const cards = Array.from(Array(4).keys());
  if (!loading) {
    return <>{children}</>;
  }
  return (
    <>
      {cards?.map((_, key) => (
        <DashboardCardContainerComponent key={key}>
          <SingleItemCardSkeletonComponent />
        </DashboardCardContainerComponent>
      ))}
    </>
  );
};

ResourceCardListSkeletonComponent.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
};
export default React.memo(ResourceCardListSkeletonComponent);
