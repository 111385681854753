import {
  RESOURCE_DOC_TYPES,
  RESOURCE_REDIRECT_TYPES,
  RESOURCE_TYPES,
} from "../constants/singleItemTypes";
import { endpoints } from "../settings/endpoints";
import {
  APPLICATIONS_ROUTE,
  GAMES_QUIZ_ROUTE,
  HTMLS_ROUTE,
  SCORMS_ROUTE,
} from "../settings/routes";

const generateText = (text) => {
  const div = document.createElement("div");
  div.innerHTML = text;
  const newText = div.textContent || div.innerText || "";
  div.remove();
  return `data:text/plain;charset=utf-8,${encodeURIComponent(newText)}`;
};

// the item type can be resource or challenge
//  the content type is type of resource/challenge which can be: zip, html, video, text, game_quiz, link, direct_link and others
// if justRedirect is true, the function returns the url value if the resource/challenge type is in RESOURCE_REDIRECT_TYPES
const generateSingleItemUrl = ({
  id,
  contentType,
  resourceData,
  previewFile,
  slug,
  itemType,
  justRedirect,
}) => {
  const urlStart = `${process.env.REACT_APP_RESOURCES_PLATFORM}/${id}`;
  let url = `${urlStart}/${resourceData?.name}`;
  // using for types of resources/challenges that need additional url for displaying content and url for downloading
  let additionalUrl = "";

  // using for types of resources/challenges that need to show just preview image
  let showPreview = false;

  // using for url
  let internalLink = false;

  switch (true) {
    case Boolean(contentType === RESOURCE_TYPES.APPLICATION):
      additionalUrl = url;
      url = `${APPLICATIONS_ROUTE}/${itemType}/${slug}`;
      break;
    case Boolean(
      [RESOURCE_TYPES.HTML, RESOURCE_TYPES.DIRECT_HTML_LINK].includes(
        contentType,
      ),
    ):
      additionalUrl = url;
      url = `${HTMLS_ROUTE}/${itemType}/${slug}`;
      break;
    case contentType === RESOURCE_TYPES.ZIP:
      url = `${SCORMS_ROUTE}/${itemType}/${slug}`;
      additionalUrl = `${endpoints.getScormUrl()}/${id}/${resourceData?.name}`;
      break;
    case Boolean(contentType === RESOURCE_TYPES.TEXT && resourceData?.text):
      url = generateText(resourceData?.text);
      break;
    case Boolean(
      contentType === RESOURCE_TYPES.GAME_QUIZ && resourceData?.integration_id,
    ):
      url = `${GAMES_QUIZ_ROUTE}/${itemType}/${id}/${resourceData?.integration_id}`;
      break;
    case Boolean(
      [RESOURCE_TYPES.DIRECT_LINK, RESOURCE_TYPES.LINK].includes(contentType) &&
        resourceData?.link,
    ):
      url = resourceData.link;
      internalLink = resourceData.internal_link;
      break;
    case Boolean(RESOURCE_DOC_TYPES.includes(contentType)):
      if (previewFile?.name) {
        additionalUrl = `${process.env.REACT_APP_RESOURCES_PLATFORM}/${id}/${previewFile?.name}`;
      } else {
        showPreview = true;
      }
      break;
    case Boolean(
      contentType === RESOURCE_TYPES.VIDEO && resourceData?.mpd_name,
    ):
      additionalUrl = `${urlStart}/${resourceData.mpd_name}`;
      break;
    case Boolean(contentType === RESOURCE_TYPES.VIDEO && resourceData?.name):
      additionalUrl = `${urlStart}/${resourceData.name}`;
      break;
  }

  if (justRedirect && !RESOURCE_REDIRECT_TYPES.includes(contentType)) {
    url = "";
  }

  return {
    url,
    additionalUrl,
    showPreview,
    internalLink,
  };
};

export default generateSingleItemUrl;
