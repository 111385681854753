import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import TEST_IDS from "../../constants/testIds";

const IconResourceButtonComponent = ({
  iconHeightClassName = "h-4",
  Icon,
  title,
  disabled,
  onClick = () => {},
  useCssFlex,
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      data-testid={TEST_IDS.BUTTONS.ICON}
      className={classNames(
        "gap-2 text-sm font-semibold hover:text-primary group-hover:text-primary stroke-cement hover:stroke-primary",
        {
          "flex items-center": useCssFlex,
          block: !useCssFlex,
        },
      )}
    >
      <Icon className={`${iconHeightClassName}`} />
      {!!title && <span className="block">{title}</span>}
    </button>
  );
};

IconResourceButtonComponent.propTypes = {
  iconHeightClassName: PropTypes.string,
  Icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  useCssFlex: PropTypes.bool,
};

export default React.memo(IconResourceButtonComponent);
