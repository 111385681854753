// Types of related materials

export const SINGLE_ITEM_TYPES = {
  RESOURCE: "resource",
  CHALLENGE: "challenge",
  NEWS: "news",
};

// Resource types

export const RESOURCE_TYPES = {
  VIDEO: "video",
  QUIZ: "quiz",
  LINK: "link",
  EXCEL: "excel",
  WORD: "word",
  PPT: "ppt",
  PDF: "pdf",
  AUDIO: "audio",
  IMAGE: "image",
  TEXT: "text",
  HTML: "html",
  DIRECT_HTML_LINK: "direct_html_link",
  APPLICATION: "application",
  ZIP: "zip",
  DIRECT_LINK: "direct_link",
  OTHER: "other",
  OFFLINE: "offline",
  GAME_QUIZ: "game_quiz",
  FEEDBACK: "feedback",
};

export const RESOURCE_MARK_COMPLETED_TYPES = [];

export const RESOURCE_GO_BUTTON_COMPLETED_TYPES = [
  RESOURCE_TYPES.APPLICATION,
  RESOURCE_TYPES.LINK,
];

export const RESOURCE_FULL_SCREEN_BUTTON_TYPES = [];
export const RESOURCE_GO_BUTTON_TYPES = [
  ...RESOURCE_MARK_COMPLETED_TYPES,
  ...RESOURCE_GO_BUTTON_COMPLETED_TYPES,
  RESOURCE_TYPES.QUIZ,
  RESOURCE_TYPES.HTML,
  RESOURCE_TYPES.ZIP,
  RESOURCE_TYPES.GAME_QUIZ,
];
export const RESOURCE_DOC_TYPES = [
  RESOURCE_TYPES.EXCEL,
  RESOURCE_TYPES.WORD,
  RESOURCE_TYPES.PPT,
  RESOURCE_TYPES.OTHER,
];

export const RESOURCE_PREVIEW_IMAGE = [
  ...RESOURCE_GO_BUTTON_COMPLETED_TYPES,
  RESOURCE_TYPES.QUIZ,
  RESOURCE_TYPES.OFFLINE,
  RESOURCE_TYPES.AUDIO,
  RESOURCE_TYPES.ZIP,
  RESOURCE_TYPES.GAME_QUIZ,
  RESOURCE_TYPES.FEEDBACK,
];

// if the variable changes, the developer must change the same variable in the admin panel
export const RESOURCE_DOWNLOAD_TYPES = [
  ...RESOURCE_DOC_TYPES,
  RESOURCE_TYPES.PDF,
  RESOURCE_TYPES.AUDIO,
  RESOURCE_TYPES.VIDEO,
  RESOURCE_TYPES.IMAGE,
  RESOURCE_TYPES.TEXT,
];

export const RESOURCE_UPLOAD_TYPES = [RESOURCE_TYPES.FEEDBACK];

export const RESOURCE_IFRAME_TYPES = [
  RESOURCE_TYPES.HTML,
  RESOURCE_TYPES.DIRECT_HTML_LINK,
];
export const RESOURCE_TEXT_TYPES = [RESOURCE_TYPES.TEXT];
export const RESOURCE_REDIRECT_TYPES = [
  RESOURCE_TYPES.DIRECT_LINK,
  RESOURCE_TYPES.DIRECT_HTML_LINK,
];

export const RESOURCE_PDF_TYPES = [...RESOURCE_DOC_TYPES, RESOURCE_TYPES.PDF];

export const RESOURCE_PLAYER_TYPES = [RESOURCE_TYPES.VIDEO];
export const RESOURCE_AUDIO_PLAYER_TYPES = [RESOURCE_TYPES.AUDIO];
export const RESOURCE_IMAGE_TYPES = [RESOURCE_TYPES.IMAGE];
