import classNames from "classnames";
import React, { useContext, useMemo } from "react";
import { SingleItemCardOrListContext } from "../../contexts/SingleItemCardOrListProvider";

import "react-lazy-load-image-component/src/effects/blur.css";
import SingleItemCardEmptyBackground from "./SingleItemCardEmptyBackground";

const SingleItemCardImageComponent = () => {
  const { title, previewImg, smaller } = useContext(
    SingleItemCardOrListContext,
  );
  const imageHeight = useMemo(
    () => (smaller ? "h-[120px]" : "h-[180px]"),
    [smaller],
  );

  return (
    <div className="w-full relative">
      <SingleItemCardEmptyBackground
        title={title}
        previewImg={previewImg}
        imageWrapperClassName={classNames("w-full", imageHeight)}
        className="rounded-t-medium"
        useCssGradientBackground={true}
      />
    </div>
  );
};

export default React.memo(SingleItemCardImageComponent);
