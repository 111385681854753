import { endpoints } from "../settings/endpoints";
import { POST } from "./apiRequests";

// is used to complete different types of resources and challenges
const markCompleted = async (id, version, body = {}) => {
  if (!id) {
    return;
  }

  try {
    await POST(
      `${endpoints.dict.resources.index}/${id}${endpoints.dict.resources.markCompleted}`,
      { version, ...body },
    );
  } catch (error) {
    console.error(error);
  }
};

export default markCompleted;
