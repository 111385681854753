import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useContext } from "react";
import { useParams } from "react-router-dom";

import NEWS_ORIENTATION from "../../constants/newsOrientation";
import newsOrientationPropTypes from "../../constants/types/newsOrientationPropTypes";
import { CompanyDataContext } from "../../contexts/CompanyDataProvider";
import { useGetNewsArticleQuery } from "../../store/services";
import ArticleComponent from "../Article/ArticleComponent";
import CardListComponent from "../Cards/CardListComponent";

const ArticlesComponent = ({
  _end = 10,
  title,
  iconSrc,
  path,
  removeActiveArticle,
  orientation = NEWS_ORIENTATION.HORIZONTAL,
}) => {
  const { isLoading, data: response } = useGetNewsArticleQuery({
    _start: 0,
    _end,
  });
  const companyContextData = useContext(CompanyDataContext);
  const { id } = useParams();

  const lastNews = useMemo(() => {
    if (!response?.data?.length) {
      return [];
    }

    let news = response.data.map((news) => ({
      createdAt: news?.news_date,
      title: news?.title,
      brand: companyContextData?.brands[news?.brand_slug],
      path: news?.id,
      id: news?.id,
    }));

    // Remove opened article from the list
    if (removeActiveArticle && id) {
      news = news.filter((article) => article.id !== id);
    }

    return news;
  }, [companyContextData?.brands, response, removeActiveArticle, id]);

  return (
    <CardListComponent
      title={title}
      icon={iconSrc}
      path={path}
      listClassName={classNames(
        "flex gap-2 sm:gap-0 lg:gap-4 overflow-auto flex-grow lg:mt-2",
        { "flex-col": orientation !== NEWS_ORIENTATION.HORIZONTAL },
      )}
      loading={isLoading}
      show={lastNews?.length}
    >
      {lastNews.map((article, id) => (
        <ArticleComponent
          parentPath={path}
          data={{
            ...article,
            companyName: companyContextData?.data?.name,
            companyIconSrc: companyContextData?.data?.favicon,
          }}
          key={id}
          orientation={orientation}
        />
      ))}
    </CardListComponent>
  );
};

ArticlesComponent.propTypes = {
  _end: PropTypes.number,
  iconSrc: PropTypes.string,
  orientation: newsOrientationPropTypes,
  path: PropTypes.string.isRequired,
  removeActiveArticle: PropTypes.bool,
  title: PropTypes.string,
};

export default ArticlesComponent;
