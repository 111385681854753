import React, { useCallback, useContext, useMemo } from "react";
import { SingleItemCardOrListContext } from "../../contexts/SingleItemCardOrListProvider";
import generateSingleItemUrl from "../../utils/generateSingleItemUrl";
import openScorm from "../../utils/openScorm";
import LinkComponent from "../Links/LinkComponent";
import RedirectContainerComponent from "../Redirect/RedirectContainerComponent";

const SingleItemLinkComponent = ({ children, className, testId }) => {
  const {
    onClick,
    title,
    to,
    resourceData,
    id,
    contentType,
    slug,
    itemType,
    userDataVersion,
  } = useContext(SingleItemCardOrListContext);

  const { url, internalLink, scormUrl } = useMemo(
    () =>
      generateSingleItemUrl({
        id,
        resourceData,
        contentType,
        slug,
        itemType,
        justRedirect: true,
      }),
    [resourceData, id, contentType, slug, itemType],
  );

  const handleClick = useCallback(() => {
    onClick();

    openScorm({ id, resourceData, contentType, slug }, itemType);
  }, [id, contentType, scormUrl, slug, itemType]);

  const linkTitle = useMemo(() => `Go to: ${title}`, [title]);

  if (url) {
    return (
      <RedirectContainerComponent
        url={url}
        isResource
        className={className}
        onClick={handleClick}
        contentType={contentType}
        id={id}
        internalLink={internalLink}
        userDataVersion={userDataVersion}
      >
        {children}
      </RedirectContainerComponent>
    );
  }

  return (
    <LinkComponent
      to={to}
      title={linkTitle}
      onClick={handleClick}
      className={className}
      data-testid={testId}
    >
      {children}
    </LinkComponent>
  );
};

export default React.memo(SingleItemLinkComponent);
