import moment from "moment";

import { EMPTY_ROW } from "../constants/constants";
import { DATE_FORMAT } from "../constants/date";

const getFormattedDate = (date) => {
  if (!date) {
    return EMPTY_ROW;
  }

  return moment(date).format(DATE_FORMAT);
};

export default getFormattedDate;
