import { useContext, useMemo } from "react";
import { CompanyDataContext } from "../contexts/CompanyDataProvider";
import capitalizeFirstLetter from "../utils/capitalizeFirstLetter";

// icons can be from assets by default
// or the user can add their own icons in the admin panel

const useGetCustomIcon = (iconKey, canBeComponent) => {
  if (!iconKey) {
    return [null, () => {}];
  }

  const { customIcons = {} } = useContext(CompanyDataContext);

  const Icon = useMemo(() => {
    if (!canBeComponent) {
      return () => {};
    }

    const updatedKey = capitalizeFirstLetter(iconKey);
    return require(`../assets/icons/component-icons/side-menu/${updatedKey}Icon`)
      .default;
  }, [canBeComponent]);

  const src = useMemo(() => {
    const customIcon = customIcons[iconKey];
    if (customIcon?.src) {
      return customIcon.src;
    }

    if (canBeComponent) {
      return null;
    }

    return require(`../assets/icons/default/${iconKey}.svg`);
  }, [iconKey, customIcons, canBeComponent]);

  return [src, Icon];
};

export default useGetCustomIcon;
