import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import iconUserArrow from "../../assets/icons/user-arrow.svg";
import { LIST_PER_PAGE_LAZY_LOADING } from "../../constants/constants";
import { CompanyDataContext } from "../../contexts/CompanyDataProvider";
import getFormattedResources from "../../utils/getFormattedResources";
import getPaginationBody from "../../utils/getPaginationBody";
import ResourceCardListComponent from "../Cards/ResourceCardListComponent";

const ResourcesColumnComponent = ({
  containerClassName,
  emptyListText,
  title,
  testId,
  smallerCards,
  disableInfiniteScroll,
  getResourcesQuery = () => {},
  perPage,
}) => {
  const [params, setParams] = useState(
    getPaginationBody(0, perPage || LIST_PER_PAGE_LAZY_LOADING),
  );
  const { isFetching, data: response, isLoading } = getResourcesQuery(params);
  const [items, setItems] = useState([]);
  const companyDataContext = useContext(CompanyDataContext);
  const assessmentsEnabled = useMemo(
    () => companyDataContext.data.assessments?.length > 0,
    [companyDataContext],
  );

  useEffect(() => {
    if (!response?.data?.length) {
      return;
    }

    const continueLearningItemsFormatted = getFormattedResources(
      response.data,
      companyDataContext.brands,
    );
    setItems(continueLearningItemsFormatted);
  }, [response]);

  const updatePaginationParameters = useCallback((start, end) => {
    setParams((prevValue) => ({
      ...prevValue,
      _start: start,
      _end: end,
    }));
  }, []);

  return (
    <div className={containerClassName} data-testid={testId}>
      <ResourceCardListComponent
        isFetching={isFetching}
        title={title}
        disableInfiniteScroll={disableInfiniteScroll}
        smallerCards={smallerCards}
        iconUserArrow={iconUserArrow}
        emptyListText={emptyListText}
        responsive={assessmentsEnabled}
        withArrow={false}
        loading={isLoading}
        items={items}
        totalCount={response?.meta?.total_count}
        updatePaginationParameters={updatePaginationParameters}
        startAssessmentEnabled={assessmentsEnabled}
      />
    </div>
  );
};

export default ResourcesColumnComponent;
