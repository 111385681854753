import _ from "lodash";

const UNKNOWN_ERROR = "UNKNOWN_ERROR";

const extractErrorMessage = (error) => {
  if (_.isString(error)) {
    return error;
  }

  if (_.isObject(error) && _.has(error, "message")) {
    return error.message;
  }

  return UNKNOWN_ERROR;
};

export default extractErrorMessage;
