import { Auth } from "aws-amplify";
import React, { useMemo } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AuthorizationContainerComponent from "../../../components/Containers/AuthorizationContainerComponent";
import { CURRENT_AUTHORIZATION_FORM } from "../../../constants/constants";
import useUserAuthorization from "../../../hooks/useUserAuthorization";
import {
  UPDATE_LOGIN_CURRENT_FORM_STEP_REDUCER,
  UPDATE_LOGIN_LOADING_REDUCER,
} from "../../../store/reducers/LoginReducer";

const SuccessResetPasswordComponent = () => {
  const handleUserAuthorization = useUserAuthorization();
  const { data } = useSelector((state) => state.LoginReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isGoToLogin = useMemo(() => !data?.email || !data?.password, [data]);

  const onSubmit = useCallback(async () => {
    dispatch(UPDATE_LOGIN_LOADING_REDUCER(true));

    if (isGoToLogin) {
      dispatch(
        UPDATE_LOGIN_CURRENT_FORM_STEP_REDUCER(
          CURRENT_AUTHORIZATION_FORM.LOGIN,
        ),
      );
      return;
    }

    try {
      await Auth.signIn(data.email, data.password);
      await handleUserAuthorization();
    } catch (_) {
      dispatch(
        UPDATE_LOGIN_CURRENT_FORM_STEP_REDUCER(
          CURRENT_AUTHORIZATION_FORM.LOGIN,
        ),
      );
    }
    dispatch(UPDATE_LOGIN_LOADING_REDUCER(false));
  }, [data, navigate, isGoToLogin]);

  return (
    <AuthorizationContainerComponent
      formStep={CURRENT_AUTHORIZATION_FORM.SUCCESS_RESET_PASSWORD}
      title="Password Reset Successful"
      subtitle="Your password has been reset. You can now sign in using your new password."
      submitButton={{
        title: `Go to ${isGoToLogin ? "Sign in" : "Platform"}`,
        onSubmit,
      }}
    ></AuthorizationContainerComponent>
  );
};

export default SuccessResetPasswordComponent;
