import { RESOURCES_ROUTE } from "../settings/routes";
import getDefaultSingleData from "./getDefaultSingleData";

import getGeneratedImgUrl from "./getGeneratedImgUrl";

const getFormattedResourceItem = (resource, brand) => {
  if (!resource) {
    return {};
  }

  const defaultSingleData = getDefaultSingleData(resource);
  const brandIcon = getGeneratedImgUrl(brand?.icon);

  return {
    ...defaultSingleData,
    path: `${RESOURCES_ROUTE}/${resource.slug}`,
    brandIcon,
    brandChangeColor: brand?.icon?.change_color,
    brandType: brand?.brand_name,
    subcategories: resource.subcategories,
    created_at: resource.created_at,
    startDate: resource.start_date,
    end_date: resource.end_date,
    bookmarked: resource.bookmarked,
    informAboutAssignment: resource.inform_about_assignment,
    id: resource?.id || resource?.resource_id,
    showDownloadButton: resource.show_download_button,
    customOrder: resource.custom_order,
    slug: resource.slug,
    tags: resource.tags,
  };
};

export default getFormattedResourceItem;
