import { useEffect, useState } from "react";

const useVisibleElements = (elements, containerWidth, classBehavior) => {
  const [visibleElements, setVisibleElements] = useState([]);
  const [hiddenElementsCount, setHiddenElementsCount] = useState(0);

  const SPACE = 4;

  useEffect(() => {
    if (containerWidth != null) {
      let totalWidth = 0;
      const newVisibleTags = [];

      elements.forEach((element) => {
        const newElement = document.createElement("span");
        newElement.innerText = element.name;
        newElement.className = classBehavior;
        document.body.appendChild(newElement);

        const elementWidth = newElement.offsetWidth + SPACE;

        if (totalWidth + elementWidth < containerWidth) {
          totalWidth += elementWidth;
          newVisibleTags.push(element);
        }

        document.body.removeChild(newElement);
      });

      setVisibleElements(newVisibleTags);
      setHiddenElementsCount(elements.length - newVisibleTags.length);
    }
  }, [elements, containerWidth]);

  return { visibleElements, hiddenElementsCount };
};

export default useVisibleElements;
