import React from "react";
import ReactDOM from "react-dom/client";

import "./styles/index.scss";
import App from "./App";

import { SentryHelper } from "./utils/sentry/sentryHelper";

SentryHelper.init();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
