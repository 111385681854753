import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import PoweredByComponent from "../../../components/SideMenu/components/PoweredByComponent";
import { PROJECT_IMAGE_BASE64 } from "../../../constants/constants";

function TitleAndImage({ title, image }) {
  return (
    <>
      <h1 className="text-3xl sm:text-4xl md:text-5xl text-info-super-dark font-semibold mt-2">
        {title}
      </h1>
      <div className="flex justify-center mt-10">
        <img src={image.src} alt={image.alt} className="w-[435px]" />
      </div>
    </>
  );
}

function Description({ description }) {
  return (
    <>
      <h2 className="text-xl sm:text-2xl text-darker-gray font-medium mt-10">
        {description.header}
      </h2>

      {description.texts &&
        description.texts.map((text, index) => {
          const isFirst = index === 0;
          const isLast = index === description.texts.length - 1;

          const cssClasses = classNames("font-light text-sm sm:text-base", {
            "mt-2": isFirst,
            "pb-5": isLast,
          });

          return (
            <p className={cssClasses} key={index}>
              {text}
            </p>
          );
        })}
    </>
  );
}

export default function CommonErrorPage({
  title,
  image,
  description,
  testIds,
  belowDescription,
}) {
  const projectImage = {
    show: true,
    src: PROJECT_IMAGE_BASE64,
  };

  return (
    <div
      className="grid h-screen place-items-center text-center pt-5 px-5"
      data-testid={testIds.container}
    >
      <div data-testid={testIds.wrapper}>
        <PoweredByComponent image={projectImage} />
        <TitleAndImage title={title} image={image} />
        <Description description={description} />
        {belowDescription}
      </div>
    </div>
  );
}

CommonErrorPage.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
  description: PropTypes.shape({
    header: PropTypes.string.isRequired,
    texts: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  testIds: PropTypes.shape({
    container: PropTypes.string.isRequired,
    wrapper: PropTypes.string.isRequired,
  }).isRequired,
  belowDescription: PropTypes.element,
};
