import React, { useMemo } from "react";

import { useSelector } from "react-redux";
import getGeneratedImgUrl from "../../../utils/getGeneratedImgUrl";
import AvatarComponent from "../../Avatar/AvatarComponent";

const UserProfileAvatarComponent = () => {
  const { data } = useSelector((state) => state.UserReducer);
  const avatarUrl = useMemo(
    () => getGeneratedImgUrl(data?.avatar),
    [data?.avatar?.src],
  );

  return (
    <AvatarComponent
      imageUrl={avatarUrl}
      userName={data?.user_name}
      className="border border-gray min-w-[36px] min-h-[36px] w-[36px] h-[36px] sm:mr-4"
    />
  );
};

export default React.memo(UserProfileAvatarComponent);
