export const PASSWORD_FIELD_VALIDATION = [
  {
    title: "Password must contain a lower case letter",
    parameter: /(?=.*[a-z])/,
  },
  {
    title: "Password must contain an upper case letter",
    parameter: /(?=.*[A-Z])/,
  },
  {
    title: "Password must contain a number",
    parameter: /(?=.*\d)/,
  },
  {
    title: "Password must contain at least 8 characters",
    parameter: /.{8,}/,
  },
  {
    title: "Password must contain a special character or a space",
    parameter: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/? ]/,
  },
  {
    title: "Password must not contain a leading or trailing space",
    parameter: /^\S(.*\S)?$/,
  },
];
