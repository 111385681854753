import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import TEST_IDS from "../../constants/testIds";

const smallSize = "h-5 w-5";
const mediumSize = "h-7 w-7";
const largeSize = "h-9 w-9";

const SpinnerComponent = ({ mode, size }) => {
  const sizeStyles = classNames("", {
    [smallSize]: size === "small",
    [mediumSize]: size === "medium",
    [largeSize]: size === "large",
  });

  return (
    <svg
      data-testid={TEST_IDS.SPINNER}
      className={`animate-spin text-white ${sizeStyles || smallSize}`}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-10"
        cx="12"
        cy="12"
        r="10"
        stroke={mode === "light" ? "white" : "#646A85"}
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-25"
        fill={mode === "light" ? "white" : "#646A85"}
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};

SpinnerComponent.defaultProps = {
  mode: "light",
  size: "small",
};

SpinnerComponent.propTypes = {
  mode: PropTypes.oneOf(["light", "dark"]).isRequired,
  size: PropTypes.oneOf(["small", "medium", "large"]).isRequired,
};

export default SpinnerComponent;
