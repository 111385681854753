import { useMemo } from "react";
import { DISABLED_CLASS_NAMES } from "../../constants/classNamesConstants";
import { isUncomingResource } from "../../utils/getLeftEndDateTitle";

export default function useDisabledClassName(canBeUncoming, startDate) {
  return useMemo(() => {
    if (canBeUncoming && isUncomingResource(startDate)) {
      return DISABLED_CLASS_NAMES;
    }

    return "";
  }, [canBeUncoming, startDate]);
}
