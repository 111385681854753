import React from "react";

import TEST_IDS from "../../constants/testIds";
import DashboardCardContainerComponent from "../Containers/DashboardCardContainerComponent";
import InfiniteScrollComponent from "../InfiniteScroll/InfiniteScrollComponent";
import CardListComponent from "./CardListComponent";
import ResourceCardComponent from "./ResourceCardComponent";
import ResourceCardListSkeletonComponent from "./ResourceCardListSkeletonComponent";

const ResourceCardListComponent = ({
  title,
  items = [],
  emptyListText,
  responsive = true,
  loading,
  withArrow = true,
  totalCount = 0,
  smallerCards,
  isFetching,
  disableInfiniteScroll,
  updatePaginationParameters = () => {},
}) => {
  return (
    <CardListComponent
      emptyListText={emptyListText}
      responsive={responsive}
      title={title}
      withArrow={withArrow}
      show={loading || items?.length}
    >
      <InfiniteScrollComponent
        length={items.length}
        loading={isFetching}
        totalCount={totalCount}
        disableInfiniteScroll={disableInfiniteScroll}
        updatePaginationParameters={updatePaginationParameters}
      >
        <ResourceCardListSkeletonComponent loading={loading}>
          {items.map((item, id) => (
            <DashboardCardContainerComponent
              key={id}
              testId={TEST_IDS.SINGLE_ITEM}
            >
              <ResourceCardComponent
                smaller={smallerCards}
                previewImg={item.image}
                resourceData={item.resourceData}
                jobCode={item.jobCode}
                id={item.id}
                slug={item.slug}
                externalLink={item.externalLink}
                title={item.title}
                defaultBookmarked={item.bookmarked}
                type={item.contentType}
                brandImage={item.brandIcon}
                canBeUncoming
                endDate={item.endDate}
                startDate={item.startDate}
                path={item.path}
                onClick={item.onClick}
                showDownloadButton={item.showDownloadButton}
                expirationDate={item.expirationDate}
                userDataVersion={item.userDataVersion}
              />
            </DashboardCardContainerComponent>
          ))}
        </ResourceCardListSkeletonComponent>
      </InfiniteScrollComponent>
    </CardListComponent>
  );
};

export default ResourceCardListComponent;
