import getFormattedResourceItem from "./getFormattedResourceItem";

// is used to format the list of resources.  Used on pages such as category, search, bookmarks, latest, not found, dashboard.
const getFormattedResources = (resources = [], brands = []) => {
  if (!resources?.length) {
    return [];
  }

  return resources.map((resource) => {
    const brand = Object.keys(brands)?.length
      ? brands[resource.brand_slug]
      : {};

    const formattedResource = getFormattedResourceItem(resource, brand);
    return {
      ...formattedResource,
    };
  });
};

export default getFormattedResources;
