const getCredentialsFromUrl = (url) => {
  const emailRegex = /[?&]email=([^&]+)/;
  const passwordRegex = /[?&]password=([^&]+)/;
  const emailMatch = url.match(emailRegex);
  const passwordMatch = url.match(passwordRegex);
  const email = emailMatch ? decodeURIComponent(emailMatch[1]) : null;
  const password = passwordMatch ? decodeURIComponent(passwordMatch[1]) : null;

  return { email, password };
};

export default getCredentialsFromUrl;
