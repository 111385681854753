import React, { useContext } from "react";

import { LMS_LAYOUT_TYPE } from "../../constants/constants";
import { CompanyDataContext } from "../../contexts/CompanyDataProvider";

// The administrator can choose CMS, LMS and brand layout.
// If the layout is not an LMS platform, hide some pages and elements on the platform.
const LMSHideComponent = ({ children }) => {
  const { data } = useContext(CompanyDataContext);

  if (data?.layout !== LMS_LAYOUT_TYPE) {
    return null;
  }

  return <>{children}</>;
};

export default React.memo(LMSHideComponent);
