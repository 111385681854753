import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  networkErrorOccurred: false,
};

export default createSlice({
  name: "Error",
  initialState,
  reducers: {
    networkError: (state) => {
      state.networkErrorOccurred = true;
    },
  },
});
