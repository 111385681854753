import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import NEWS_ORIENTATION from "../../constants/newsOrientation";
import TEST_IDS from "../../constants/testIds";
import imagePropTypes from "../../constants/types/imagePropTypes";
import newsOrientationPropTypes from "../../constants/types/newsOrientationPropTypes";
import getFormattedDate from "../../utils/getFormattedDate";
import getGeneratedImgUrl from "../../utils/getGeneratedImgUrl";

const ArticleComponent = ({ data = {}, orientation, parentPath }) => {
  const { title, createdAt, path, brand, companyName, companyIconSrc } = data;

  // The platform uses brands as subcategories of news.
  // if the news has a brand, the brand icon is displayed.
  // if the news does not have a brand, the company icon is displayed.
  const brandIcon = useMemo(
    () => getGeneratedImgUrl(brand?.icon) || companyIconSrc,
    [brand],
  );
  const name = useMemo(
    () => brand?.brand_name || companyName,
    [brand, companyName],
  );

  // the article has different styles for the horizontal and vertical sides of the ipad and iphone screenes.
  const containerClassName = useMemo(() => {
    const isHorizontal = orientation === NEWS_ORIENTATION.HORIZONTAL;

    return classNames("lg:px-0 min-w-[34%] border-gray", {
      "border-r flex flex-col justify-between px-3": isHorizontal,
      "border-b pb-5 mb-1": !isHorizontal,
    });
  }, [orientation]);

  return (
    <div data-testid={TEST_IDS.CONTAINER} className={containerClassName}>
      <Link to={`${parentPath}/${path}`} title={`Go to ${title}`}>
        <span className="text-sm hover:text-primary duration-default text-dark line-clamp-2">
          {title}
        </span>
      </Link>
      <div className="mt-6 lg:mt-3 gap-1 flex justify-between items-center font-semibold">
        <div className="text-xs flex items-center gap-2">
          <img
            src={brandIcon}
            alt={name}
            title={name}
            className="w-4 max-h-4"
          />
          <span className="line-clamp-1">{name}</span>
        </div>
        <span className="ml-1 text-xs cursor-default whitespace-nowrap">
          {getFormattedDate(createdAt)}
        </span>
      </div>
    </div>
  );
};

ArticleComponent.propTypes = {
  orientation: newsOrientationPropTypes,
  parentPath: PropTypes.string,
  data: PropTypes.shape({
    brand: PropTypes.shape({
      brand_name: PropTypes.string,
      icon: imagePropTypes,
    }),
    companyIconSrc: PropTypes.string,
    companyName: PropTypes.string,
    createdAt: PropTypes.string,
    id: PropTypes.string,
    path: PropTypes.string,
    title: PropTypes.string,
  }),
};

export default ArticleComponent;
