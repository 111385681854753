import getFormattedScore from "./getFormattedScore";
import getGeneratedImgUrl from "./getGeneratedImgUrl";

// The part of data is the same for single challenge and single resource so we can use this function for both of them
const getDefaultSingleData = (singleItem, withScore) => {
  const image = getGeneratedImgUrl(singleItem?.preview_image);
  const score = withScore
    ? `${getFormattedScore(singleItem?.user_score)}/${getFormattedScore(
        singleItem?.max_score,
      )}`
    : undefined;

  return {
    image,
    id: singleItem?.resource_id,
    slug: singleItem?.slug,
    title: singleItem?.resource_name || "",
    description: singleItem?.short_description,
    endDate: singleItem?.end_date,
    expirationDate: singleItem?.expiration_date,
    contentType: singleItem?.content_type,
    resourceData: singleItem?.resource_data,
    previewFile: singleItem?.preview_file,
    jobCode: singleItem?.job_code,
    showDownloadButton: singleItem?.show_download_button,
    userDataVersion: singleItem?.user_data_version,
    status: singleItem?.status,
    maxRepetitions: singleItem?.max_repetitions,
    repetitionsLeft: singleItem?.repetitions_left,
    cautionText: singleItem?.caution_text,
    score,
  };
};

export default getDefaultSingleData;
