import { Auth } from "aws-amplify";
import React, { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import StaticContainerComponent from "../../components/Containers/StaticContainerComponent";
import TEST_IDS from "../../constants/testIds";
import useMetaTitle from "../../hooks/useMetaTitle";
import { HOME_ROUTE } from "../../settings/routes";
import { gaPageViewEvent } from "../../utils/gaEvents";

import LoginFormComponent from "./components/LoginFormComponent";
import NewPasswordFormComponent from "./components/NewPasswordFormComponent";
import ResetPasswordFormComponent from "./components/ResetPasswordFormComponent";
import SuccessResetPasswordComponent from "./components/SuccessResetPasswordComponent";

const LoginPage = () => {
  const metaTitle = useMetaTitle("Login");
  const navigate = useNavigate();
  const { search } = useLocation();

  const handleCheckAuthorization = useCallback(async () => {
    try {
      if (search) {
        return true;
      }
      await Auth.currentSession();
      navigate(HOME_ROUTE);
    } catch (_) {
      // messages do not matter
    }
  }, [Auth, search]);

  useEffect(() => {
    document.title = metaTitle;
    gaPageViewEvent(metaTitle);
  }, []);

  useEffect(() => {
    handleCheckAuthorization();
  }, []);
  return (
    <StaticContainerComponent>
      <div
        className="py-6 w-full lg:w-2/3 xl:w-1/2"
        data-testid={TEST_IDS.LOGIN_PAGE}
      >
        <LoginFormComponent />
        <ResetPasswordFormComponent />
        <SuccessResetPasswordComponent />
        <NewPasswordFormComponent />
      </div>
    </StaticContainerComponent>
  );
};

export default LoginPage;
