import { lazy } from "react";
import retry from "../utils/retry";
import {
  BOOKMARKS_ROUTE,
  BRAND_ROUTE,
  BRANDS_ROUTE,
  CATEGORY_ROUTE,
  CONTESTS_ROUTE,
  CURRICULUMS_SINGLE_RESOURCE_ROUTE,
  CURRICULUMS_USER_ROUTE,
  HOME_ROUTE,
  LATEST_ROUTE,
  LOGIN_ROUTE,
  LOGOUT_ROUTE,
  MILESTONE_ROUTE,
  MY_PERFORMANCE_ROUTE,
  MY_TEAM_ROUTE,
  NEWS_ITEM_ROUTE,
  NEWS_ROUTE,
  PERSONALIZED_CURRICULUMS_ROUTE,
  PERSONALIZED_LEARNING_ASSESSMENT_ROUTE,
  SEARCH_RESULTS_ROUTE,
  SETTINGS_ROUTE,
  SINGLE_APPLICATION_ROUTE,
  SINGLE_CHALLENGE_ROUTE,
  SINGLE_CONTEST_ROUTE,
  SINGLE_GAME_QUIZ_ROUTE,
  SINGLE_HTML_ROUTE,
  SINGLE_RESOURCE_ROUTE,
  SINGLE_SCORM_ROUTE,
  USER_PERFORMANCE_ROUTE,
} from "./routes";

const generateRoute = (path, importedComponent) => ({
  path: path,
  Component: lazy(() => retry(() => importedComponent)),
});

export default [
  generateRoute(HOME_ROUTE, import("../pages/Company/CompanyPage")),
  generateRoute(
    SINGLE_APPLICATION_ROUTE,
    import("../pages/SingleApplication/SingleApplicationPage.js"),
  ),
  generateRoute(
    SINGLE_HTML_ROUTE,
    import("../pages/SingleHTML/SingleHTMLPage.js"),
  ),
  generateRoute(
    SINGLE_SCORM_ROUTE,
    import("../pages/SingleScorm/SingleScormPage.js"),
  ),
  generateRoute(BOOKMARKS_ROUTE, import("../pages/Bookmarks/BookmarksPage.js")),
  generateRoute(BRANDS_ROUTE, import("../pages/Brands/BrandsPage")),
  generateRoute(BRAND_ROUTE, import("../pages/Brand/BrandPage")),
  generateRoute(CATEGORY_ROUTE, import("../pages/Category/CategoryPage")),
  generateRoute(NEWS_ROUTE, import("../pages/News/NewsPage")),
  generateRoute(NEWS_ITEM_ROUTE, import("../pages/SingleNews/SingleNewsPage")),
  generateRoute(
    SINGLE_RESOURCE_ROUTE,
    import("../pages/SingleResource/SingleResourcePage"),
  ),
  generateRoute(
    CURRICULUMS_SINGLE_RESOURCE_ROUTE,
    import("../pages/SingleResource/SingleResourcePage"),
  ),
  generateRoute(CONTESTS_ROUTE, import("../pages/Contests/ContestsPage")),
  generateRoute(
    SINGLE_CONTEST_ROUTE,
    import("../pages/SingleContest/SingleContestPage"),
  ),
  generateRoute(MILESTONE_ROUTE, import("../pages/Milestone/MilestonePage")),
  generateRoute(
    MY_PERFORMANCE_ROUTE,
    import("../pages/MyLearningAndDevelopment/MyLearningAndDevelopmentPage"),
  ),
  generateRoute(
    USER_PERFORMANCE_ROUTE,
    import("../pages/MyLearningAndDevelopment/MyLearningAndDevelopmentPage"),
  ),
  generateRoute(
    PERSONALIZED_LEARNING_ASSESSMENT_ROUTE,
    import(
      "../pages/PersonalizedLearningAssessment/PersonalizedLearningAssessmentPage"
    ),
  ),
  generateRoute(
    SINGLE_CHALLENGE_ROUTE,
    import("../pages/SingleChallenge/SingleChallengePage"),
  ),
  generateRoute(
    SINGLE_GAME_QUIZ_ROUTE,
    import("../pages/SingleGameQuiz/SingleGameQuizPage"),
  ),
  generateRoute(
    CURRICULUMS_USER_ROUTE,
    import("../pages/Curriculum/CurriculumPage"),
  ),
  generateRoute(
    PERSONALIZED_CURRICULUMS_ROUTE,
    import("../pages/Curriculum/CurriculumPage"),
  ),
  generateRoute(MY_TEAM_ROUTE, import("../pages/MyTeam/MyTeamPage")),
  generateRoute(
    SEARCH_RESULTS_ROUTE,
    import("../pages/SearchResults/SearchResultsPage"),
  ),
  generateRoute(LATEST_ROUTE, import("../pages/Latest/LatestPage")),
  generateRoute(SETTINGS_ROUTE, import("../pages/Settings/SettingsPage")),
  generateRoute(LOGIN_ROUTE, import("../pages/Login/LoginPage")),
  generateRoute(LOGOUT_ROUTE, import("../pages/Logout/LogoutPage")),
];
