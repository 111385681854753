// is used to display initials instead of avatar
const getLetter = (word) => (word ? word[0].toUpperCase() : "");

const getInitialsTitle = (name) => {
  if (!name) {
    return "";
  }
  const nameParts = name.split(" ");
  return `${getLetter(nameParts[0])}${getLetter(nameParts[1])}`;
};

export default getInitialsTitle;
