import React, { useMemo } from "react";
import usePathActive from "../../../hooks/usePathActive";

const ActiveMenuItemTitleComponent = ({ className = "", path, title }) => {
  const isActive = usePathActive(path);
  const containerClassName = useMemo(() => {
    const textColor = isActive ? "text-primary" : "";

    return `${className} ${textColor}`;
  }, [isActive, className]);

  return <span className={containerClassName}>{title}</span>;
};

export default React.memo(ActiveMenuItemTitleComponent);
