import getContactSupportMail from "../utils/getContactSupportMail";

export const ERRORS = {
  ERR_BAD_REQUEST_CODE: "ERR_BAD_REQUEST",
  ERR_BAD_RESPONSE_CODE: "ERR_BAD_RESPONSE",
  INVALID_PARAMETER_EXCEPTION: {
    code: "InvalidParameterException",
    message:
      "Your password does not meet AGILIFY LABS password policy recommendations. Please remember to use capital letters, numbers and special characters like !@#$%^&*()",
  },
  EXPIRED_PASSWORD: {
    code: "PasswordResetRequiredException",
    message:
      "This password has expired. Please press “Forgot Password” to receive a new temporary password.",
  },
};

export const ERROR_CODES = {
  NOT_FOUND: 404,
  IN_ACTIVE: 451,
  FORBIDDEN: 403,
};

export const ERROR_MESSAGES = {
  NOT_FOUND: "not found",
};

export const MILESTONE_ERROR_TEXTS = {
  UPCOMING:
    "This milestone will be available to users who have completed previous milestones as of",
  PREVIOUS: "You have not completed the previous milestones",
  NOT_AVAILABLE_TITLE: "This milestone is not available yet",
};

export const CHALLENGE_ERROR_TEXTS = {
  NOT_AVAILABLE: {
    TITLE: "This challenge is not available yet",
    DESCRIPTION: "You have not completed the previous challenges",
  },
  IN_ACTIVE: {
    TITLE: "Inactive Challenge",
    DESCRIPTION: `Oops! This challenge is no longer active. If you have any questions, please reach out to <a href='${getContactSupportMail()}'>help@agilifylabs.com</a>.`,
  },
};

export const RESOURCE_ERROR_TEXTS = {
  IN_ACTIVE: {
    TITLE: "Inactive Resource",
    DESCRIPTION: `Oops! This resource is no longer active. If you have any questions, please reach out to <a href='${getContactSupportMail()}'>help@agilifylabs.com</a>.`,
  },
};

export const CHANGE_PASSWORD_ERROR_TEXTS = {
  CURRENT_PASSWORD_REQUIRED: "Current password cannot be empty.",
  NEW_PASSWORD_REQUIRED: "New password cannot be empty.",
  SAME_VALUE: "New password fields should contain the same value.",
};
