import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { endpoints } from "../../settings/endpoints";
import { GET, POST } from "../../utils/apiRequests";

export const FETCH_MILESTONE_REDUCER = createAsyncThunk(
  "MilestoneReducer/fetchMilestoneReducer",
  async (slug, { rejectWithValue }) => {
    try {
      const response = await GET(`${endpoints.contest.milestones}/${slug}`);
      const data = response.data;

      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        return rejectWithValue(e?.response?.data);
      }
    }
  },
);

export const MARK_MILESTONE_MESSAGE_REDUCER = createAsyncThunk(
  "MilestoneReducer/markMilestoneMessageReducer",
  async (ids = [], { rejectWithValue }) => {
    try {
      const response = await POST(endpoints.contest.markAsViewed, {
        message_ids: ids,
      });
      const data = response.data;

      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        return rejectWithValue(e?.response?.data);
      }
    }
  },
);

const initialData = {
  error: null,
  data: {},
  loading: false,
};

export const MilestoneReducer = createSlice({
  name: "MilestoneReducer",
  initialState: { ...initialData },
  extraReducers: (builder) => {
    builder.addCase(FETCH_MILESTONE_REDUCER.pending, (state) => {
      state.error = null;
      state.loading = true;
    });
    builder.addCase(FETCH_MILESTONE_REDUCER.fulfilled, (state, { payload }) => {
      state.data = {
        ...payload,
      };
      state.loading = false;
    });
    builder.addCase(
      MARK_MILESTONE_MESSAGE_REDUCER.fulfilled,
      (state, { payload }) => {
        state.data = {
          ...state.data,
          ...payload,
        };
      },
    );
    builder.addCase(FETCH_MILESTONE_REDUCER.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload || {};
    });
  },
});

export default MilestoneReducer.reducer;
