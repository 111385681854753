import classNames from "classnames";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import EmptyBackgroundComponent from "../EmptyBackground/EmptyBackgroundComponent";

export default function SingleItemCardEmptyBackground({
  title,
  previewImg,
  className,
  imageWrapperClassName,
  useCssImageContain = false,
  useCssFlex = false,
  useCssGradientBackground = false,
}) {
  return (
    <EmptyBackgroundComponent className={className} hide={previewImg}>
      <LazyLoadImage
        src={previewImg}
        alt={title}
        effect="blur"
        title={title}
        wrapperProps={{
          style: {
            display: useCssFlex ? "flex" : "block",
          },
        }}
        className={classNames("w-full h-full", {
          "object-cover object-top rounded-t-medium": !useCssImageContain,
          "object-contain": useCssImageContain,
        })}
        wrapperClassName={imageWrapperClassName}
      />
      <div
        className={classNames(
          "duration-default absolute top-0 left-0 w-full h-full from-black via-black to-white rounded-t-medium opacity-[0.40] group-hover:opacity-0",
          {
            "bg-gradient-to-r": useCssGradientBackground,
          },
        )}
      />
    </EmptyBackgroundComponent>
  );
}
