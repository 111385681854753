import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import axios from "axios";

import { endpoints } from "../../settings/endpoints";
import { GET, POST } from "../../utils/apiRequests";

export const FETCH_METERS_REDUCER = createAsyncThunk(
  "MetersReducer/fetchMetersReducer",
  async (_, { rejectWithValue }) => {
    try {
      const response = await GET(endpoints.assessment.current);

      return response.data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        return rejectWithValue(e?.response?.data);
      }
    }
  },
);

export const FETCH_SCORING_METERS_REDUCER = createAsyncThunk(
  "MetersReducer/fetchScoringMetersReducer",
  async ({ userId, assessmentSlug }, { rejectWithValue }) => {
    try {
      const response = await POST(endpoints.assessment.scoring, {
        assessment: assessmentSlug,
        user: userId,
      });
      return {
        data: response.data,
        userId: userId,
      };
    } catch (e) {
      if (axios.isAxiosError(e)) {
        return rejectWithValue(e?.response?.data);
      }
    }
  },
);

const initialData = {
  error: null,
  data: {
    rendered: false,
    assessmentSlug: "",
    startAssessmentEnabled: false,
    userId: "",
    scoring: [],
  },
  loading: false,
  scoringLoading: true,
};

export const MetersReducer = createSlice({
  name: "MetersReducer",
  initialState: { ...initialData },
  reducers: {
    HIDE_SCORING_LOADING_METERS_REDUCER: (state) => {
      state.scoringLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(FETCH_METERS_REDUCER.pending, (state) => {
      state.error = null;
      state.loading = true;
    });
    builder.addCase(FETCH_SCORING_METERS_REDUCER.pending, (state) => {
      state.error = null;
      state.scoringLoading = true;
    });
    builder.addCase(
      FETCH_SCORING_METERS_REDUCER.fulfilled,
      (state, { payload }) => {
        const { data, userId } = payload;
        const startAssessmentEnabled = !data.length;
        state.data.userId = !startAssessmentEnabled ? userId : "";
        state.data.scoring = data || [];
        state.data.startAssessmentEnabled = startAssessmentEnabled;
        state.scoringLoading = false;
      },
    );
    builder.addCase(FETCH_METERS_REDUCER.fulfilled, (state, { payload }) => {
      const { slug } = payload;
      state.data.assessmentSlug = slug || "";
      state.data.rendered = true;
      state.loading = false;
    });
    builder.addMatcher(
      isAnyOf(
        FETCH_METERS_REDUCER.rejected,
        FETCH_SCORING_METERS_REDUCER.rejected,
      ),
      (state, { payload }) => {
        state.scoringLoading = false;
        state.loading = false;
        state.error = payload || {};
      },
    );
  },
});

export const { HIDE_SCORING_LOADING_METERS_REDUCER } = MetersReducer.actions;
export default MetersReducer.reducer;
