import React, { useMemo } from "react";
import { SINGLE_ITEM_TYPES } from "../../constants/singleItemTypes";
import TEST_IDS from "../../constants/testIds";
import viewButtonTitle from "../../constants/viewButtonTitle";
import { SingleItemCardOrListProvider } from "../../contexts/SingleItemCardOrListProvider";
import useDisabledClassName from "../../hooks/resource/useDisabledClassName";
import BadgeComponent from "../Badge/BadgeComponent";
import DownloadResourceButtonComponent from "../DownloadResourceButton/DownloadResourceButtonComponent";

import SingleItemCardBodyComponent from "../SingleItemCard/SingleItemCardBodyComponent";
import SingleItemCardFooterComponent from "../SingleItemCard/SingleItemCardFooterComponent";
import SingleItemCardHeaderComponent from "../SingleItemCard/SingleItemCardHeaderComponent";
import SingleItemCardImageComponent from "../SingleItemCard/SingleItemCardImageComponent";
import SingleItemLinkComponent from "../SingleItemLink/SingleItemLinkComponent";
import BookmarkButtonComponent from "./BookmarkButtonComponent";
import CardContainerComponent from "./CardContainerComponent";

const ResourceCardComponent = ({
  previewImg,
  title,
  type = "",
  brandImage,
  canBeUncoming,
  hideDate,
  isActiveCurriculumDate,
  defaultBookmarked,
  endDate,
  startDate,
  path,
  smaller,
  id,
  resourceData,
  jobCode,
  showDownloadButton,
  canBeKeyResource,
  informAboutAssignment,
  slug,
  tags,
  expirationDate,
  userDataVersion,
  onClick = () => {},
}) => {
  const isDisabledClassName = useDisabledClassName(canBeUncoming, startDate);

  const hideKeyResource = useMemo(
    () => !canBeKeyResource || !informAboutAssignment,
    [canBeKeyResource, informAboutAssignment],
  );
  return (
    <SingleItemCardOrListProvider
      to={path}
      title={title}
      jobCode={jobCode}
      onClick={onClick}
      resourceData={resourceData}
      id={id}
      contentType={type}
      slug={slug}
      previewImg={previewImg}
      smaller={smaller}
      itemType={SINGLE_ITEM_TYPES.RESOURCE}
      expirationDate={expirationDate}
      userDataVersion={userDataVersion}
      tags={tags}
    >
      <CardContainerComponent
        testId={TEST_IDS.RESOURCE_CARD}
        paddingClassName=""
        className={`flex flex-col group w-full relative ${isDisabledClassName}`}
      >
        <SingleItemLinkComponent
          className={`flex-1 flex flex-col ${isDisabledClassName}`}
          testId={TEST_IDS.LINK}
        >
          <SingleItemCardImageComponent />
          <SingleItemCardHeaderComponent
            image={brandImage}
            canBeUncoming={canBeUncoming}
            endDate={endDate}
            startDate={startDate}
            hideDate={hideDate}
            isActiveCurriculumDate={isActiveCurriculumDate}
            className={isDisabledClassName}
          >
            <BadgeComponent
              text={"Key resource"}
              withIcon={false}
              hide={hideKeyResource}
            />
          </SingleItemCardHeaderComponent>
          <SingleItemCardBodyComponent />
        </SingleItemLinkComponent>
        <SingleItemCardFooterComponent
          buttonTitle={viewButtonTitle.RESOURCE}
          isDisabledClassName={isDisabledClassName}
        >
          <div className="flex items-center gap-4">
            <BookmarkButtonComponent
              id={id}
              defaultBookmarked={defaultBookmarked}
            />
            <DownloadResourceButtonComponent
              id={id}
              contentType={type}
              resourceData={resourceData}
              defaultShow={showDownloadButton}
            />
          </div>
        </SingleItemCardFooterComponent>
      </CardContainerComponent>
    </SingleItemCardOrListProvider>
  );
};

export default React.memo(ResourceCardComponent);
