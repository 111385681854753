import React, { useMemo } from "react";

import {
  CARD_CLASS_NAMES,
  DEFAULT_PADDING_CLASS_NAMES,
} from "../../constants/classNamesConstants";
import PageLoaderComponent from "../Loaders/PageLoaderComponent";

const CardContainerComponent = ({
  children,
  paddingClassName = DEFAULT_PADDING_CLASS_NAMES,
  className = "",
  loading,
  minHeightClassName = "",
  cardRef,
  withShadow,
  testId,
  cardClassName = CARD_CLASS_NAMES,
  style,
  onClick = () => {},
}) => {
  const containerClassName = useMemo(
    () =>
      `${cardClassName} ${minHeightClassName} ${paddingClassName} ${className} ${
        withShadow ? "shadow" : ""
      }`,
    [minHeightClassName, paddingClassName, className, withShadow],
  );
  return (
    <section
      className={containerClassName}
      ref={cardRef}
      data-testid={testId}
      style={style}
      onClick={onClick}
    >
      {children}
      <PageLoaderComponent loading={loading} className={minHeightClassName} />
    </section>
  );
};

export default CardContainerComponent;
