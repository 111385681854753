import PropTypes from "prop-types";
import React, { useContext, useMemo } from "react";

import {
  PROJECT_IMAGE,
  PROJECT_LANDING_URL,
  PROJECT_NAME,
} from "../../../constants/constants";
import { CompanyDataContext } from "../../../contexts/CompanyDataProvider";
import useShowMobile from "../../../hooks/useShowMobile";

const PoweredByComponent = ({
  image,
  textColor,
  isColumn,
  imageHeightClassName,
}) => {
  const isMobile = useShowMobile();

  const imageClassName = useMemo(() => {
    let className = "object-contain max-w-full opacity-70 hover:opacity-[1]";
    if (imageHeightClassName) {
      return `${imageHeightClassName} ${className}`;
    }
    const height = isMobile ? "h-16 sm:h-12" : "h-16";
    return `${height} ${className}`;
  }, [imageHeightClassName, isMobile]);

  const { data } = useContext(CompanyDataContext);
  const additionalClassNames = useMemo(() => {
    let classNames = textColor;

    if (isColumn) {
      classNames += " flex-col mb-3";
    }

    return classNames;
  }, [textColor]);

  if (!data.projectLogo) {
    return null;
  }

  const projectImageSrc = image.src ?? PROJECT_IMAGE;

  return (
    <section
      className={`flex items-center justify-center text-xs ${additionalClassNames}`}
    >
      <a
        href={PROJECT_LANDING_URL}
        target="_blank"
        rel="noreferrer"
        title={PROJECT_NAME}
      >
        {image.show ? (
          <img
            className={imageClassName}
            src={projectImageSrc}
            alt={PROJECT_NAME}
          />
        ) : (
          <p className="ml-1 mb-3 hover:underline">{PROJECT_NAME}</p>
        )}
      </a>
    </section>
  );
};

PoweredByComponent.propTypes = {
  image: PropTypes.shape({
    show: PropTypes.bool,
    src: PropTypes.string,
  }),
  textColor: PropTypes.string,
  isColumn: PropTypes.bool,
  imageHeightClassName: PropTypes.string,
};

PoweredByComponent.defaultProps = {
  image: {
    show: true,
  },
  textColor: "text-cement",
};

export default React.memo(PoweredByComponent);
