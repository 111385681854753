const TEST_IDS = {
  TOOGLE_BUTTONS_GROUP: "toggle-buttons-group",
  BACKGROUND_CONTAINER: "background-container",
  VIDEO_PLAYER: {
    MP4: "mp4-video-player",
    MPD: "mpd-video-player",
  },
  PING_ANIMATE: "ping-animate",
  PING: "ping",
  PIN: "pin",
  SETTINGS: {
    ACCTOUNT_CONTAINER: "account-container",
    PASSWORD_CONTAINER: "password-container",
  },
  ASSESSMENT_CONTAINER: "assessment-container",
  CIRCLE: "circle",
  SPINNER: "spinner",
  BACKGROUND: "background",
  TOGGLE: "toggle",
  STATUS: "status",
  LIST_ITEM: "list-item",
  WIDGET_HEADER: "widget-header",
  SKELETON: "skeleton",
  EMPTY_TITLE: "empty-title",
  WARNING_MESSAGE: "warning-message",
  REDIRECT_CONTAINER: "redirect-container",
  GROUP_SIZE: "group-size",
  TABS: "tabs",
  TAB: "tab",
  DESCRIPTION: "description",
  TIMER: "timer",
  LOGIN_PAGE: "login-page",
  DESKTOP_MAIN_CONTAINER: "desktop-main-container",
  DESKTOP_LAYOUT: "desktop-layout",
  MOBILE_LAYOUT: "mobile-layout",
  LOADING: "loading",
  FOOTER: "footer",
  CMS: "CMS",
  LMS: "LMS",
  BRANDS: "BRANDS",
  BRAND_CARD_DESCRIPTION: "brand-card-description",
  BRAND_CARD_ICON: "brand-card-icon",
  BRAND_PREVIEW_IMAGE: "brand-preview-image",
  BRAND_ICON: "brand-icon",
  CONTENT_BAR: "content-bar",
  PAGINATION_CONTAINER: "pagination-container",
  PAGINATION_FROM: "pagination-from",
  PAGINATION_TO: "pagination-to",
  PAGINATION_TOTAL_COUNT: "pagination-total-count",
  ACTIVE_QUESTION_DESCRIPTION: "active-question-description",
  CHECKBOX: "checkbox",
  RELATED_MATERIAL: "related-material",
  RELATED_MATERIAL_LINK: "related-material-link",
  RELATED_MATERIAL_DATE: "related-material-date",
  SINGLE_ITEM_ATTEMPT: "single-item-attempt",
  SINGLE_ITEM_IMAGE: "single-item-image",
  SINGLE_ITEM_IFRAME: "single-item-iframe",
  SINGLE_ITEM_FORM: "single-item-form",
  SINGLE_ITEM_PLAYER: "single-item-player",
  SINGLE_ITEM_AUDIO_PLAYER: "single-item-audio-player",
  SINGLE_ITEM_TEXT: "single-item-text",
  SINGLE_ITEM: "single-item",
  SINGLE_ITEM_PAGE: "single-item-page",
  LINK: "link",
  CONTAINER: "container",
  FULL_MODE_CONTAINER: "full-mode-container",
  SELECT: "select",
  SELECT_ITEM: "select-item",
  FILTER_ITEM: "filter-item",
  METERS: "meters",
  MOBILE_MENU_CONTAINER: "mobile-menu-container",
  RESET_PASSWORD_CONTAINER: "reset-password-container",
  CONTENT_HEADER: "content-header",
  DROPDOWN: "dropdown",
  // input test ids
  INPUTS: {
    SEARCH: "search-field",
    USER_ROLE: "user-role-field",
    USER_NAME: "user-name-field",
    EMAIL: "email-field",
    OLD_PASSWORD: "old-password-field",
    PASSWORD: "password-field",
    CONFIRM_PASSWORD: "confirm-password-field",
    VERIFICATION_CODE: "verification-code-field",
  },
  // button test ids
  BUTTONS: {
    TOOGLE_BUTTON: "toggle-button",
    START_ASSESSMENT: "start-assessment-button",
    SUBMIT: "submit-button",
    FORGOT_PASSWORD: "forgot-button",
    PAGINATION_NEXT: "pagination-next-button",
    PAGINATION_PREVIOUS: "pagination-previous-button",
    NEXT: "next-button",
    PREVIOUS: "previous-button",
    SINGLE_ITEM: "single-item-button",
    DOWNLOAD: "download-button",
    APPLY: "apply-button",
    RESET: "reset-button",
    DOWNLOAD_PDF: "download-pdf-button",
    DEFAULT: "default-button",
    ICON: "icon-button",
    FULL_MODE: "full-mode-button",
    INTRO: "intro-button",
    LOGOUT: "logout-button",
    UPLOAD: "upload-button",
    CLOSE: "close-button",
  },
  HEADER: {
    BUTTON: "header-button",
    MENU: "header-menu",
    INITIALS: "header-initials",
    AVATAR: "header-avatar",
    LIST_ITEM: "header-list-item",
    ICON: "header-icon",
  },
  SIDE_MENU: {
    ICON: "side-menu-icon",
    MAIN_ICON: "side-menu-main-icon",
    MAIN_ICON_IMAGE: "side-menu-main-icon-image",
    CATEGORY_ITEM: "side-menu-category-item",
    FIRST_LEVEL_ITEM: "side-menu-first-level-item",
    SECOND_LEVEL_ITEM: "side-menu-second-level-item",
    SECOND_LEVEL_LIST: "side-menu-second-level-list",
    THIRD_LEVEL_ITEM: "side-menu-third-level-item",
  },
  NOT_FOUND: "not-found",
  FORM: "form",
  TITLE: "title",
  INITIALS_TITLE: "initials-title",
  RESOURCE_CARD: "resource-card",
  CHALLENGE_CARD: "challenge-card",
  CONTEST: {
    DEFAULT_TYPE: "contest-default-type",
    JOURNEY_TYPE: "contest-journey-type",
    WIN_POPUP: "win-popup",
    JOURNEY_MAP: "contest-journey-map",
    MAP_FILTERS: "map-filters",
  },
  BADGE: "badge",
  BADGE_ICON: "badge-icon",
  IMAGE: "image",
  LEADERBOARD: {
    INDIVIDUAL: "individual-leaderboard",
    TEAM: "individual-team",
  },
  FILTERS_LIST: "filters-list",
  TABLE: {
    TABLE: "table",
    HEADER: "header",
    BODY: "body",
    ROW: "row",
    CELL: "cell",
    HEADER_CELL: "header-cell",
    SORTED_ASC: "sorted-asc",
    SORTED_DESC: "sorted-desc",
  },
  UPLOAD_STEPS: {
    UPLOAD: "upload-step",
    SUCCESS_UPLOAD: "success-upload-step",
  },
  WITHOUT_LINK: "without-link",
  EXTERNAL_LINK: "external-link",
  NETWORK_ERROR_PAGE: {
    CONTAINER: "network-error-page",
    CONTENT_WRAPPER: "content",
  },
  MISSING_ACCESS_TOKEN_PAGE: {
    CONTAINER: "missing-access-token-page",
    CONTENT_WRAPPER: "content",
    CTA: "call-to-action",
  },
};

export default TEST_IDS;
