const changeToDefaultDomain = (src) => {
  if (!src) {
    return undefined;
  }

  // remove domain from image url
  const domainRegex = /^(https?:\/\/)?[^/]+/;
  const path = src.replace(domainRegex, "") || `/${src}`;

  return `${process.env.REACT_APP_ASSETS_DOMAIN}${path}`;
};

export default changeToDefaultDomain;
