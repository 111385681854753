import PropTypes from "prop-types";
import React, { useMemo } from "react";

import STATUS_LIST from "../../constants/statusList";
import { expiredTitle, upcomingTitle } from "../../utils/getLeftEndDateTitle";
import BadgeComponent from "../Badge/BadgeComponent";

const ChipComponent = ({ title, loading, className }) => {
  const status = useMemo(() => {
    switch (true) {
      case title === expiredTitle:
        return STATUS_LIST.ALERT;
      case title === upcomingTitle:
        return STATUS_LIST.DISABLED;
      default:
        return STATUS_LIST.PRIMARY;
    }
  }, [title]);

  if (loading) {
    return null;
  }
  return (
    <BadgeComponent
      text={title}
      status={status}
      withIcon={false}
      className={className}
    />
  );
};

ChipComponent.propTypes = {
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

export default ChipComponent;
