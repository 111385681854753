import React, { useMemo } from "react";

import {
  DEFAULT_GAP_CLASS_NAMES,
  DEFAULT_PADDING_CLASS_NAMES,
} from "../../constants/classNamesConstants";
import useShowMobile from "../../hooks/useShowMobile";
import CardContainerComponent from "../Cards/CardContainerComponent";
import EmptyContainerComponent from "../Containers/EmptyContainerComponent";
import SpinnerComponent from "../Spinner/SpinnerComponent";
import WidgetHeaderComponent from "../WidgetHeader/WidgetHeaderComponent";

// Card that displays a list of cards such as key resources, brands and other lists
const CardListComponent = ({
  emptyListText,
  loading,
  title,
  withArrow,
  show,
  children,
  icon,
  path,
  listClassName,
}) => {
  const isMobile = useShowMobile();
  const updatedListClassName = useMemo(() => {
    if (listClassName) {
      return `${DEFAULT_PADDING_CLASS_NAMES} ${listClassName}`;
    }
    const columnClassName = isMobile ? "flex-row" : "sm:flex-col";
    return `${DEFAULT_PADDING_CLASS_NAMES} ${columnClassName} ${DEFAULT_GAP_CLASS_NAMES} flex flex-grow items-center justify-start overflow-y-auto`;
  }, [isMobile, listClassName]);

  return (
    <CardContainerComponent
      withShadow
      className="flex flex-col sm:h-full"
      minHeightClassName="min-h-[200px]"
      paddingClassName="pt-mobile sm:pt-tablet 2xl:pt-desktop"
    >
      <div className="flex flex-col h-full min-h-[200px]">
        <WidgetHeaderComponent
          icon={icon}
          title={title}
          withArrow={withArrow}
          classNameContainer="px-mobile sm:px-tablet 2xl:px-desktop"
          path={path}
        />
        {loading ? (
          <div className="flex-grow flex justify-center items-center">
            <SpinnerComponent mode="dark" size="medium" />
          </div>
        ) : (
          <div className={updatedListClassName}>
            <EmptyContainerComponent emptyText={emptyListText} show={!show}>
              {children}
            </EmptyContainerComponent>
          </div>
        )}
      </div>
    </CardContainerComponent>
  );
};

export default CardListComponent;
