import * as React from "react";
const HelpIcon = ({ className, color = "#212121" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width="16"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeMiterlimit: 10,
    }}
    className={className}
    viewBox="0 0 16 16"
  >
    <path
      d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: color,
        strokeWidth: "1.5px",
      }}
      transform="translate(-.25 -.25)"
    />
    <path
      d="M8 11.267a3.267 3.267 0 1 0 0-6.535 3.267 3.267 0 0 0 0 6.535ZM10.309 5.691l2.641-2.64M10.309 10.309l2.641 2.641M5.691 10.309l-2.64 2.641M5.691 5.691l-2.64-2.64"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: color,
        strokeWidth: "1.5px",
      }}
      transform="translate(-.25 -.25)"
    />
  </svg>
);
export default HelpIcon;
