import { createApi } from "@reduxjs/toolkit/query/react";

import { LATEST_FILTERS } from "../constants/constants";
import { SINGLE_ITEM_TYPES } from "../constants/singleItemTypes";
import { endpoints } from "../settings/endpoints";
import { SERVICES_GET } from "../utils/apiRequests";

const merge = (currentCache, newItems) => {
  if (newItems?.meta?.start < 1) {
    currentCache.data = newItems.data;
    return;
  }

  currentCache.meta = { ...newItems.meta };
  currentCache.data.push(...newItems.data);
};

const infiniteItemsFetching = (url, defaultParams = {}) => ({
  query: (params) => ({
    url,
    params: {
      ...defaultParams,
      ...params,
    },
  }),
  serializeQueryArgs: ({ endpointName }) => {
    return endpointName;
  },
  merge,
  forceRefetch({ currentArg, previousArg }) {
    return currentArg !== previousArg;
  },
});

export const servicesApi = createApi({
  reducerPath: "servicesApi",
  baseQuery: SERVICES_GET,
  endpoints: (builder) => ({
    getContest: builder.query({
      query: ({ slug }) => ({
        url: `${endpoints.contest.list}/${slug}`,
      }),
    }),

    getCategories: builder.query({
      query: ({ brand }) => ({
        url: endpoints.dict.categories,
        params: { brand },
      }),
    }),

    getCategoryResources: builder.query(
      infiniteItemsFetching(endpoints.dict.resources.index),
    ),

    getLatests: builder.query(
      infiniteItemsFetching(endpoints.dict.resources.index, {
        ommit_key_resources_sort: true,
      }),
    ),
    getBookmarks: builder.query(
      infiniteItemsFetching(endpoints.dict.resources.index, {
        bookmarked: true,
        ommit_key_resources_sort: true,
      }),
    ),
    getChallenge: builder.query({
      query: ({ slug }) => ({
        url: `${endpoints.contest.challenges}/${slug}`,
        params: {
          related_materials: true,
        },
      }),
      keepUnusedDataFor: 0,
    }),
    getResource: builder.query({
      query: ({ slug, assessment }) => ({
        url: `${endpoints.dict.resources.index}/${slug}`,
        params: {
          assessment: assessment,
          related_materials: !assessment,
        },
      }),
    }),
    getSingleItem: builder.query({
      query: ({ itemType, slug }) => ({
        url:
          itemType === SINGLE_ITEM_TYPES.RESOURCE
            ? `${endpoints.dict.resources.index}/${slug}`
            : `${endpoints.contest.challenges}/${slug}`,
      }),
    }),
    getLatestResource: builder.query({
      query: () => ({
        url: endpoints.dict.resources.index,
        params: {
          not_viewed: LATEST_FILTERS.NOT_VIEWED,
          _end: 1,
        },
      }),
    }),
    getNewsArticle: builder.query({
      query: (params) => ({
        url: endpoints.dict.news,
        params,
      }),
    }),
    getBrandsWithNews: builder.query({
      query: () => ({
        url: endpoints.dict.brands,
        params: {
          with_news: true,
        },
      }),
    }),
    getNewlyAssigned: builder.query({
      query: ({ _end, _start }) => ({
        url: endpoints.dict.resources.index,
        params: {
          _order: "DESC",
          active: true,
          _sort: "created_at",
          _start,
          _end,
          ommit_key_resources_sort: true,
        },
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    getKeyResources: builder.query({
      query: ({ _end, _start }) => ({
        url: endpoints.dict.resources.index,
        params: {
          _order: "DESC",
          _sort: "created_at",
          _start,
          _end,
          inform_about_assignment: true,
        },
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    getUsersLeaderboard: builder.query({
      query: ({ slug, _start, _end, _sort, _order }) => ({
        url: `${endpoints.contest.list}/${slug}${endpoints.usersLeaderboard}`,
        params: {
          _start,
          _end,
          _sort,
          _order,
        },
      }),
      keepUnusedDataFor: 0,
    }),
    getTeamsLeaderboard: builder.query({
      query: ({ slug, _start, _end, _sort, _order }) => ({
        url: `${endpoints.contest.list}/${slug}${endpoints.teamsLeaderboard}`,
        params: {
          _start,
          _end,
          _sort,
          _order,
        },
      }),
      keepUnusedDataFor: 0,
    }),
    getGameQuiz: builder.query({
      query: ({ gameId, resourceId }) => ({
        url: `${endpoints.contest.gameQuiz}/${gameId}?resource_id=${resourceId}`,
      }),
    }),
  }),
});

export const {
  useGetBrandsWithNewsQuery,
  useGetNewsArticleQuery,
  useGetKeyResourcesQuery,
  useGetNewlyAssignedQuery,
  useGetSingleItemQuery,
  useGetChallengeQuery,
  useGetContestQuery,
  useGetBookmarksQuery,
  useGetLatestsQuery,
  useGetCategoryResourcesQuery,
  useGetLatestResourceQuery,
  useGetTeamsLeaderboardQuery,
  useGetUsersLeaderboardQuery,
  useGetGameQuizQuery,
  useGetCategoriesQuery,
  useGetResourceQuery,
} = servicesApi;
