import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TEST_IDS from "../../../constants/testIds";
import useShortSideMenuClasses from "../../../hooks/useShortSideMenuClasses";
import { BRANDS_ROUTE } from "../../../settings/routes";
import { CLOSE_MOBILE_SIDE_MENU_REDUCER } from "../../../store/reducers/MainReducer";
import getGeneratedImgUrl from "../../../utils/getGeneratedImgUrl";
import DirectIconItemComponent from "../../Icons/DirectIconItemComponent";
import LinkComponent from "../../Links/LinkComponent";
import ActiveMenuItemTitleComponent from "./ActiveMenuItemTitleComponent";
import MenuItemListIconComponent from "./MenuItemListIconComponent";
import ShortSideMenuComponent from "./ShortSideMenuComponent";
import SideMenuImageComponent from "./SideMenuImageComponent";
import SideMenuTooltipComponent from "./SideMenuTooltipComponent";
import ThirdLevelMenuComponent from "./ThirdLevelMenuComponent";

// this component is an item of the second level of the side menu
// this component is can be shown in all versions of menu
// his component changes the state of the third level of the side menu
// this component can be modified for a short version of the menu

const SecondLevelMenuItemComponent = ({
  path,
  slug,
  brandName,
  url,
  iconSrc,
  itemId,
  itemName,
  external,
  hasCategories,
  hideAllMenus,
  internalLink,
  onHideAllMenus = () => {},
}) => {
  const containerClassNames = useShortSideMenuClasses(
    "pl-8 lg:pl-4 xl:pl-8",
    "ml-0.5 pl-6",
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { shortSideMenu } = useSelector((state) => state.MainReducer);

  const [showMenu, setShowMenu] = useState(false);
  const [brandId, setBrandId] = useState(null);

  const showDirectionIcon = useMemo(() => url || external, [url, external]);
  const menuIcon = useMemo(() => getGeneratedImgUrl(iconSrc), [iconSrc]);
  const title = useMemo(() => brandName || itemName, [brandName, itemName]);
  const isBrand = useMemo(() => brandName, [brandName]);
  const itemPath = useMemo(() => (isBrand ? url : path), [isBrand, url, path]);
  const isSameBrand = useMemo(() => brandId === itemId, [brandId, itemId]);
  const canShowMenu = useMemo(
    () => hasCategories && showMenu && isSameBrand,
    [hasCategories, showMenu, isSameBrand],
  );
  const handleCloseSideMenu = useCallback(() => {
    dispatch(CLOSE_MOBILE_SIDE_MENU_REDUCER());
  }, [dispatch]);

  const handleClick = useCallback(
    (newBrandId) => async () => {
      if (itemPath) {
        return;
      }

      if (isBrand && shortSideMenu) {
        handleCloseSideMenu();
        navigate(`${BRANDS_ROUTE}/${slug}`);
        return;
      }

      await onHideAllMenus();

      if (newBrandId !== brandId) {
        setShowMenu(true);
        await setBrandId(newBrandId);
      } else {
        setShowMenu(!showMenu);
      }
    },
    [
      brandId,
      showMenu,
      setShowMenu,
      shortSideMenu,
      navigate,
      handleCloseSideMenu,
      onHideAllMenus,
      itemPath,
      isBrand,
    ],
  );

  useEffect(() => {
    if (hideAllMenus) {
      setShowMenu(false);
    }
  }, [hideAllMenus]);

  return (
    <LinkComponent
      to={itemPath}
      internalLink={internalLink}
      externalLink={showDirectionIcon}
      onClick={handleCloseSideMenu}
    >
      <li className="relative">
        <div
          className={`flex gap-2 items-center justify-between w-full h-full py-4 pr-6 text-sm hover:text-primary duration-default ${containerClassNames}`}
          data-testid={TEST_IDS.SIDE_MENU.SECOND_LEVEL_ITEM}
          onClick={handleClick(itemId)}
        >
          <div className="flex gap-4 items-center flex-grow">
            <SideMenuTooltipComponent title={title}>
              <DirectIconItemComponent show={!isBrand && shortSideMenu}>
                <SideMenuImageComponent src={menuIcon} alt={title} />
              </DirectIconItemComponent>
            </SideMenuTooltipComponent>
            <ShortSideMenuComponent>
              <ActiveMenuItemTitleComponent title={title} path={path} />
            </ShortSideMenuComponent>
          </div>
          <ShortSideMenuComponent>
            <DirectIconItemComponent show={showDirectionIcon}>
              <MenuItemListIconComponent
                containerClassName="pl-1"
                show={hasCategories}
                isRotate={showMenu && isSameBrand}
                title={title}
              />
            </DirectIconItemComponent>
          </ShortSideMenuComponent>
        </div>
        <ThirdLevelMenuComponent
          parentSlug={slug}
          brandId={brandId}
          showMenu={canShowMenu}
        />
      </li>
    </LinkComponent>
  );
};

export default React.memo(SecondLevelMenuItemComponent);
