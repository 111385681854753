import React from "react";
import { useSelector } from "react-redux";
import MainContainerComponent from "../../components/Containers/MainContainerComponent";
import MissingAccessTokenPage from "../../pages/Error/MissingAccessTokenPage";
import NetworkErrorPage from "../../pages/Error/NetworkErrorPage";
import LoginPage from "../../pages/Login/LoginPage";
import { LOGIN_ROUTE, LOGOUT_ROUTE } from "../../settings/routes";

export default function useComponentFinder(isAuthenticated) {
  const { networkErrorOccurred } = useSelector((state) => state.Error);
  const { isSessionExpired, isAccessTokenMissing } = useSelector(
    (state) => state.Security,
  );

  return function findRouteComponent(routePath, Component) {
    if (networkErrorOccurred) {
      return <NetworkErrorPage />;
    }

    if (isAccessTokenMissing) {
      return <MissingAccessTokenPage />;
    }

    if (isAuthenticated && !isSessionExpired) {
      return (
        <MainContainerComponent
          withoutMain={[LOGIN_ROUTE, LOGOUT_ROUTE].includes(routePath)}
        >
          <Component />
        </MainContainerComponent>
      );
    }

    return <LoginPage />;
  };
}
