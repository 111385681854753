import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import SpinnerComponent from "../Spinner/SpinnerComponent";

const ButtonComponent = ({
  text,
  testId,
  type = "primary",
  fullWidth = false,
  size = "default",
  className = "",
  onClick = () => void 0,
  disabled = false,
  icon,
  inline,
  loading,
  iconClassName = "",
}) => {
  const styles = classNames(
    "text-center rounded-small font-medium duration-default select-none justify-center gap-1 lg:gap-1.5 items-center py-2 px-4 lg:py-2.5 lg:px-5",
    {
      "bg-primary text-white": type === "primary",
      "text-primary hover:text-primary-dark cursor-pointer":
        type === "tertiary" && !disabled,
      "text-dark opacity-50 disabled cursor-default":
        type === "tertiary" && disabled,
      "text-xs lg:text-sm": size === "small",
      "hover:bg-primary-dark hover:shadow cursor-pointer":
        !disabled && type === "primary",
      "opacity-50 disabled cursor-default bg-gray text-dark-gray":
        disabled && type === "primary",
      "opacity-50 disabled cursor-default border border-gray text-dark-gray hover:border-gray":
        disabled && type === "secondary",
      "text-primary-dark border border-primary-extra-light hover:border-primary hover:shadow cursor-pointer":
        !disabled && type === "secondary",
      "bg-primary-light hover:bg-primary-light hover:shadow":
        loading && type === "primary",
      "text-primary-dark border border-primary-extra-light hover:shadow":
        loading && type === "secondary",
      "w-full": fullWidth,
      "w-fit": !fullWidth,
      "inline-flex": inline,
      flex: !inline,
      [`${className}`]: true,
    },
  );

  return (
    <div
      data-testid={testId}
      className={styles}
      onClick={disabled ? () => void 0 : onClick}
    >
      {icon && (
        <img
          className={`w-3 lg:w-4 ${iconClassName}`}
          src={icon}
          alt={text}
          title={text}
        />
      )}
      {!loading && text}
      {loading && (
        <div className="text-center">
          <SpinnerComponent
            mode={type === "primary" ? "light" : "dark"}
            size="small"
          />
        </div>
      )}
    </div>
  );
};

ButtonComponent.defaultProps = {
  type: "primary",
  fullWidth: false,
  size: "default",
  className: "",
  onClick: () => void 0,
  disabled: false,
  iconClassName: "",
};

ButtonComponent.propTypes = {
  text: PropTypes.string.isRequired,
  testId: PropTypes.string,
  type: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
  fullWidth: PropTypes.bool,
  size: PropTypes.oneOf(["default", "small"]),
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  inline: PropTypes.bool,
  loading: PropTypes.bool,
  iconClassName: PropTypes.string,
};

export default ButtonComponent;
