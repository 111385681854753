import React from "react";
import { Link } from "react-router-dom";

const OptionalLinkComponent = ({
  isCursorPointer,
  children,
  itemPath,
  className = "",
}) => {
  if (!itemPath) {
    return (
      <div
        className={`pointer-events-auto ${
          isCursorPointer ? "cursor-pointer" : "cursor-default"
        } ${className}`}
      >
        {children}
      </div>
    );
  }

  return (
    <Link
      to={itemPath[0] === "/" ? itemPath : `/${itemPath}`}
      title="Optional link"
      className={className}
    >
      {children}
    </Link>
  );
};

export default OptionalLinkComponent;
