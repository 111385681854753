import React from "react";
import { TOOLTIP_ID } from "../../constants/constants";

const TooltipComponent = ({ title, children, className = "" }) => {
  return (
    <div className={`relative inline-block group ${className}`}>
      <div
        className="cursor-pointer"
        data-tooltip-id={TOOLTIP_ID}
        data-tooltip-content={title}
      >
        {children}
      </div>
    </div>
  );
};

export default React.memo(TooltipComponent);
