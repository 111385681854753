import { HELP_EMAIL } from "../constants/constants";
import getBrowser from "./getBrowser";
import getDevice from "./getDevice";
import getPlatform from "./getPlatform";

const getContactSupportMail = () => {
  const device = getDevice();
  const browser = getBrowser();
  const platform = getPlatform();

  // the user will open an email template with body including platform, browser and device information
  return `mailto:${HELP_EMAIL}?subject=I%20need%20help&body=Describe%20your%20problem%20here...%0A%0A%0A%0A---%0APlease%2C%20don't%20remove%20this%20information.%20It%20helps%20us%20resolve%20your%20issue%20faster.%0AMy%20platform%20is%20${platform}%0AMy%20browser%20is%20${browser}%0AMy%20device%20is%20${device}`;
};

export default getContactSupportMail;
