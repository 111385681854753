import createDOMPurify from "dompurify";
import React from "react";
import TEST_IDS from "../../constants/testIds";

const ErrorBoundaryComponent = ({
  Icon,
  title,
  subtitle,
  description,
  iconWidth = "w-52",
  containerClassName = "",
  hide,
  children,
}) => {
  if (hide) {
    return null;
  }
  return (
    <div
      data-testid={TEST_IDS.NOT_FOUND}
      className={`flex flex-col justify-center items-center ${containerClassName}`}
    >
      <Icon className={`${iconWidth}`} />
      <h1 className="text-primary mt-6 mb-4 text-xl text-center">{title}</h1>
      <h2 className="text-lg md:text-2xl font-bold text-center">{subtitle}</h2>
      <h3
        className="text-sm mt-3 text-center"
        dangerouslySetInnerHTML={{
          __html: createDOMPurify.sanitize(description),
        }}
      />
      {children}
    </div>
  );
};

export default React.memo(ErrorBoundaryComponent);
