import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const usePathActive = (path) => {
  const { pathname } = useLocation();
  const isActive = useMemo(
    () => (path === "/" ? pathname === path : pathname?.includes(path)),
    [path, pathname],
  );
  return isActive;
};

export default usePathActive;
