import { IMAGE_EXPIRED_DATE_COOKIES } from "../constants/constants";
import createCookie from "./createCookie";

// Use to create cookies for the first time on the client side
const createCookies = (cookies = [], expireDate) => {
  if (!cookies?.length) {
    return false;
  }

  createCookie(IMAGE_EXPIRED_DATE_COOKIES, expireDate, expireDate);
  cookies.forEach((cookie) => {
    createCookie(cookie.name, cookie.value, expireDate);
  });

  return true;
};

export default createCookies;
