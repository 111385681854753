import { Auth } from "aws-amplify";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AuthorizationContainerComponent from "../../../components/Containers/AuthorizationContainerComponent";
import InputComponent from "../../../components/Inputs/InputComponent";
import { CURRENT_AUTHORIZATION_FORM } from "../../../constants/constants";
import TEST_IDS from "../../../constants/testIds";
import {
  UPDATE_LOGIN_CURRENT_FORM_STEP_REDUCER,
  UPDATE_LOGIN_ERROR_REDUCER,
  UPDATE_LOGIN_LOADING_REDUCER,
  UPDATE_LOGIN_PASSWORD_REDUCER,
} from "../../../store/reducers/LoginReducer";
import getErrorMessage from "../../../utils/getErrorMessage";

const ResetPasswordFormComponent = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.LoginReducer);
  const [codeValue, setCodeValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");

  const handleResetData = useCallback(() => {
    setPasswordValue("");
    setEmailValue("");
    setCodeValue("");
  }, []);

  const handleShowError = useCallback(
    (error) => {
      dispatch(UPDATE_LOGIN_ERROR_REDUCER(getErrorMessage(error)));
    },
    [dispatch],
  );

  const onSubmit = useCallback(
    async (evt) => {
      evt.preventDefault();
      dispatch(UPDATE_LOGIN_ERROR_REDUCER());
      dispatch(UPDATE_LOGIN_LOADING_REDUCER(true));

      try {
        await Auth.forgotPasswordSubmit(emailValue, codeValue, passwordValue);
        dispatch(UPDATE_LOGIN_PASSWORD_REDUCER(passwordValue));
        dispatch(
          UPDATE_LOGIN_CURRENT_FORM_STEP_REDUCER(
            CURRENT_AUTHORIZATION_FORM.SUCCESS_RESET_PASSWORD,
          ),
        );
        handleResetData();
      } catch (error) {
        handleShowError(error);
        setPasswordValue("");
      }

      dispatch(UPDATE_LOGIN_LOADING_REDUCER());
    },
    [emailValue, passwordValue, dispatch, codeValue, handleResetData],
  );

  const handleGoLogin = useCallback(async () => {
    handleResetData();
    dispatch(
      UPDATE_LOGIN_CURRENT_FORM_STEP_REDUCER(CURRENT_AUTHORIZATION_FORM.LOGIN),
    );
  }, [dispatch]);

  useEffect(() => {
    if (data.email) {
      setEmailValue(data.email);
    }
  }, [data]);
  return (
    <AuthorizationContainerComponent
      formStep={CURRENT_AUTHORIZATION_FORM.RESET_PASSWORD}
      title="Create new password"
      subtitle="Please enter the verification code we sent to your email and your new password."
      submitButton={{
        title: "Reset Password",
        onSubmit,
      }}
      helpButton={{
        title: "Back to Sign In",
        onClick: handleGoLogin,
      }}
    >
      <div
        className="flex flex-col sm:flex-row md:flex-col gap-5"
        data-testid={TEST_IDS.RESET_PASSWORD_CONTAINER}
      >
        <InputComponent
          required
          placeholder="Enter verification code"
          label="Verification code"
          autoComplete="off"
          value={codeValue}
          testId={TEST_IDS.INPUTS.VERIFICATION_CODE}
          onChange={(value) => setCodeValue(value)}
        />
        <InputComponent
          required
          placeholder="Enter your email"
          label="Email"
          type="email"
          autoComplete="username email"
          value={emailValue}
          testId={TEST_IDS.INPUTS.EMAIL}
          onChange={(value) => setEmailValue(value)}
        />
        <InputComponent
          required
          placeholder="Enter new password"
          label="New password"
          type="password"
          autoComplete="new-password"
          value={passwordValue}
          testId={TEST_IDS.INPUTS.PASSWORD}
          onChange={(value) => setPasswordValue(value)}
        />
      </div>
    </AuthorizationContainerComponent>
  );
};

export default ResetPasswordFormComponent;
