import React, { createContext } from "react";
import PlaceholderImage from "../assets/images/placeholderResource.jpg";

export const SingleItemCardOrListContext = createContext(null);

export const SingleItemCardOrListProvider = ({
  children,
  to,
  title,
  contentType,
  onClick = () => {},
  resourceData,
  id,
  slug,
  previewImg = PlaceholderImage,
  smaller,
  jobCode,
  itemType,
  expirationDate,
  userDataVersion,
  showIndicator,
  pingColor,
  startDate,
  endDate,
  tags,
}) => {
  return (
    <SingleItemCardOrListContext.Provider
      value={{
        onClick,
        to,
        title,
        contentType,
        resourceData,
        id,
        slug,
        previewImg,
        smaller,
        jobCode,
        itemType,
        expirationDate,
        userDataVersion,
        pingColor,
        showIndicator,
        startDate,
        endDate,
        tags,
      }}
    >
      {children}
    </SingleItemCardOrListContext.Provider>
  );
};
