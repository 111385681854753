import React from "react";

const BrandIconImageComponent = ({ image, title, className = "w-4 h-4" }) => {
  if (!image) {
    return null;
  }

  return (
    <div className="p-2 bg-white rounded shadow">
      <img
        src={image}
        alt={title}
        title={title}
        className={`object-contain min-w-[16px] ${className}`}
      />
    </div>
  );
};

export default React.memo(BrandIconImageComponent);
