import React from "react";

import TooltipComponent from "../Tooltip/TooltipComponent";

const HeaderIconContainerComponent = ({ children, title }) => {
  return (
    <div className="relative w-4 2xl:w-5 mr-2 sm:mr-5 hover:opacity-80 duration-default flex items-center">
      <TooltipComponent title={title}>{children}</TooltipComponent>
    </div>
  );
};

export default React.memo(HeaderIconContainerComponent);
