import { PER_PAGE_PAGINATION } from "../constants/constants";

const getPaginationBody = (
  start = 0,
  end = PER_PAGE_PAGINATION,
  sort = "",
  order = "DESC",
) => ({
  _start: start,
  _end: end,
  _sort: sort,
  _order: order,
});

export default getPaginationBody;
