import { ERRORS } from "../constants/errors";

const getErrorMessage = (error = {}) => {
  const { code, message } = error;

  switch (code) {
    case ERRORS.INVALID_PARAMETER_EXCEPTION.code:
      return ERRORS.INVALID_PARAMETER_EXCEPTION.message;
    case ERRORS.EXPIRED_PASSWORD.code:
      return ERRORS.EXPIRED_PASSWORD.message;
    default:
      return message || "";
  }
};

export default getErrorMessage;
