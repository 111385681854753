import * as React from "react";

const SideMenuIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      viewBox="0 0 22 18"
      {...props}
    >
      <path
        d="M.875 10.5h19.25M.875 2.625h19.25"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: "#212121",
          strokeWidth: 2,
        }}
        transform="translate(.125 -1.625)"
      />
      <path
        d="M11.375 18.375h8.75"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: "#212121",
          strokeWidth: 2,
        }}
        transform="translate(-10.375 -1.635)"
      />
    </svg>
  );
};
export default SideMenuIcon;
