import moment from "moment";

export const expiredTitle = "Expired";
export const tooltipExpiredTitle = "Earning points is no longer possible.";
export const tooltipDateTitle =
  "Following this date, no points will be granted.";
export const upcomingTitle = "Upcoming";
export const createDateTitleWithLeft = (date) => `${date} left`;
export const isUncomingResource = (startDate) =>
  startDate && moment().isBefore(moment(startDate));

const getDateWithParentDate = ({ date, startDate, endDate }) => {
  const response = {
    tooltipTitle: tooltipDateTitle,
  };
  switch (true) {
    case Boolean(endDate.isBefore(moment())):
      response.date = expiredTitle;
      response.tooltipTitle = tooltipExpiredTitle;
      break;
    case Boolean(date.isBefore(startDate)):
      response.date = createDateTitleWithLeft(endDate.fromNow(true));
      break;
    case Boolean(date.isBefore(endDate) && date.isBefore(moment())):
      response.date = expiredTitle;
      response.tooltipTitle = tooltipExpiredTitle;
      break;
    case Boolean(date.isBefore(endDate)):
      response.date = createDateTitleWithLeft(date.fromNow(true));
      break;
    default:
      response.date = createDateTitleWithLeft(endDate.fromNow(true));
      break;
  }

  return response;
};

const getLeftEndDateTitle = ({
  startDate,
  endDate,
  parentStartDate,
  parentEndDate,
  canBeUncoming,
}) => {
  let response = {};
  const date = moment(endDate);

  switch (true) {
    case Boolean(canBeUncoming && startDate && isUncomingResource(startDate)):
      response.date = upcomingTitle;
      break;
    case Boolean(!endDate):
      return response;
    case Boolean(parentStartDate && parentEndDate):
      response = getDateWithParentDate({
        date,
        startDate: moment(parentStartDate),
        endDate: moment(parentEndDate),
      });
      break;
    case Boolean(!date || date.isBefore(moment())):
      response.date = expiredTitle;
      response.tooltipTitle = tooltipExpiredTitle;
      break;
    default:
      response.date = createDateTitleWithLeft(date.fromNow(true));
      response.tooltipTitle = tooltipDateTitle;
  }

  return response;
};

export default getLeftEndDateTitle;
