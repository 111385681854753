import { createSlice } from "@reduxjs/toolkit";

import { CURRENT_AUTHORIZATION_FORM } from "../../constants/constants";

const initialData = {
  error: null,
  data: {
    currentFormStep: CURRENT_AUTHORIZATION_FORM.LOGIN,
    password: "",
    email: "",
    user: {},
  },
  loading: false,
  forgotPasswordLoading: false,
};

export const LoginReducer = createSlice({
  name: "LoginReducer",
  initialState: { ...initialData },
  reducers: {
    UPDATE_LOGIN_ERROR_REDUCER: (state, { payload }) => {
      state.error = payload || null;
    },
    UPDATE_LOGIN_LOADING_REDUCER: (state, { payload }) => {
      state.loading = !!payload;
    },
    UPDATE_FORGOT_PASSWORD_LOADING_REDUCER: (state, { payload }) => {
      state.forgotPasswordLoading = !!payload;
    },
    UPDATE_LOGIN_CURRENT_FORM_STEP_REDUCER: (state, { payload }) => {
      state.data.currentFormStep = payload || null;
    },
    UPDATE_LOGIN_EMAIL_REDUCER: (state, { payload }) => {
      state.data.email = payload || "";
    },
    UPDATE_LOGIN_PASSWORD_REDUCER: (state, { payload }) => {
      state.data.password = payload || "";
    },
    UPDATE_LOGIN_USER_REDUCER: (state, { payload }) => {
      state.data.user = payload || {};
    },
  },
});

export const {
  UPDATE_LOGIN_ERROR_REDUCER,
  UPDATE_LOGIN_CURRENT_FORM_STEP_REDUCER,
  UPDATE_LOGIN_LOADING_REDUCER,
  UPDATE_LOGIN_EMAIL_REDUCER,
  UPDATE_FORGOT_PASSWORD_LOADING_REDUCER,
  UPDATE_LOGIN_USER_REDUCER,
  UPDATE_LOGIN_PASSWORD_REDUCER,
} = LoginReducer.actions;
export default LoginReducer.reducer;
