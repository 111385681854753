import React from "react";
import useShortSideMenuClasses from "../../../hooks/useShortSideMenuClasses";
import SideMenuButtonComponent from "../../SideMenuButton/SideMenuButtonComponent";
import CompanyLogoComponent from "./CompanyLogoComponent";
import ShortSideMenuButtonComponent from "./ShortSideMenuButtonComponent";
import ShortSideMenuComponent from "./ShortSideMenuComponent";

const SideMenuHeaderComponent = () => {
  const containerClassNames = useShortSideMenuClasses(
    "",
    "items-center flex",
    true,
  );
  const buttonsClassNames = useShortSideMenuClasses("lg:hidden", "", true);

  return (
    <div className={`relative ${containerClassNames}`}>
      <div
        className={`top-0 z-1 pt-4 lg:pt-0 h-full w-10 left-mobile absolute sm:left-tablet 2xl:left-desktop lg:relative ${buttonsClassNames}`}
      >
        <SideMenuButtonComponent />
        <ShortSideMenuButtonComponent />
      </div>
      <ShortSideMenuComponent>
        <CompanyLogoComponent
          logoMarginClassNames="ml-6 mr-6"
          containerClassName="border-gray border-r"
        />
      </ShortSideMenuComponent>
    </div>
  );
};

export default React.memo(SideMenuHeaderComponent);
