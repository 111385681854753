import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { NAVBAR_HEIGHT_CLASS_NAMES } from "../../../constants/classNamesConstants";
import { CompanyDataContext } from "../../../contexts/CompanyDataProvider";
import LinkComponent from "../../Links/LinkComponent";
import ShortSideMenuComponent from "./ShortSideMenuComponent";

const CompanyLogoComponent = ({
  logoMarginClassNames = "",
  containerClassName = "",
  useNavbarHeight = true,
}) => {
  const companyContextData = useContext(CompanyDataContext);
  const { logoUrl } = companyContextData.data;

  const containerClasses = classNames(
    "max-w-full relative flex justify-center items-center",
    containerClassName,
    {
      [NAVBAR_HEIGHT_CLASS_NAMES]: useNavbarHeight,
    },
  );

  return (
    <LinkComponent to="/" title="Go to dashboard">
      <div className={containerClasses}>
        <img
          className={`${logoMarginClassNames} max-w-full h-[55px] 2xl:h-[60px] object-contain object-center`}
          src={logoUrl}
          alt="Logo"
        />
        <ShortSideMenuComponent>
          <div className="absolute w-px h-full bg-white left-full top-0 z-20" />
        </ShortSideMenuComponent>
      </div>
    </LinkComponent>
  );
};

CompanyLogoComponent.propTypes = {
  logoMarginClassNames: PropTypes.string,
  containerClassName: PropTypes.string,
  useNavbarHeight: PropTypes.bool,
};

export default React.memo(CompanyLogoComponent);
