import React, { useContext } from "react";

import { CompanyDataContext } from "../../contexts/CompanyDataProvider";
import "../../styles/static-page.scss";

const StaticBackgroundContainerComponent = ({ children }) => {
  const companyContextData = useContext(CompanyDataContext);
  const { staticBackground } = companyContextData.data;
  const defaultClassName =
    "flex flex-col justify-center flex-grow items-center md:h-full  md:w-1/2";
  if (staticBackground) {
    return (
      <div
        className={`${defaultClassName} bg-no-repeat bg-cover bg-center`}
        id="static-page"
        style={{ backgroundImage: `url("${staticBackground}")` }}
      >
        {children}
      </div>
    );
  }

  return (
    <div
      className={`${defaultClassName} bg-primary overflow-hidden relative`}
      id="static-page"
    >
      {children}
      <div className="hidden md:block absolute bg-primary w-[40em] h-[40em] rounded-full lg:bg-white opacity-10 -left-[12em] -top-[12em] z-10" />
      <div className="hidden md:block absolute w-[56em] h-[56em] rounded-full bg-primary sm:bg-white opacity-10 -right-[26em] -bottom-[26em] z-10" />
    </div>
  );
};

export default React.memo(StaticBackgroundContainerComponent);
