import React, { useMemo } from "react";

const TextSkeletonComponent = ({ loading, type = "p" }) => {
  const heightClassName = useMemo(() => {
    switch (true) {
      case type === "h1":
        return "h-6";
      case type === "p":
        return "h-2";
      default:
        return "h-2";
    }
  }, [type]);
  if (!loading) {
    return null;
  }

  return (
    <div
      className={`mt-1 mb-1 animate-pulse bg-gray rounded col-span-2 ${heightClassName}`}
    />
  );
};

export default React.memo(TextSkeletonComponent);
