import changeToDefaultDomain from "./changeToDefaultDomain";

const getGeneratedImgUrl = (item) => {
  // const correctDomain = 'https://rarify-public-files.s3.amazonaws.com';
  const defaultIconsList = [
    "https://rarify-public-files.s3.amazonaws.com/icons/arrow-down.svg",
    "https://rarify-public-files.s3.amazonaws.com/icons/badge.svg",
    "https://rarify-public-files.s3.amazonaws.com/icons/c-pulse.svg",
    "https://rarify-public-files.s3.amazonaws.com/icons/chart-bar.svg",
    "https://rarify-public-files.s3.amazonaws.com/icons/check.svg",
    "https://rarify-public-files.s3.amazonaws.com/icons/checklist.svg",
    "https://rarify-public-files.s3.amazonaws.com/icons/clip.svg",
    "https://rarify-public-files.s3.amazonaws.com/icons/code.svg",
    "https://rarify-public-files.s3.amazonaws.com/icons/cogwheel.svg",
    "https://rarify-public-files.s3.amazonaws.com/icons/control-panel.svg",
    "https://rarify-public-files.s3.amazonaws.com/icons/ctrl-down.svg",
    "https://rarify-public-files.s3.amazonaws.com/icons/digital-key.svg",
    "https://rarify-public-files.s3.amazonaws.com/icons/document.svg",
    "https://rarify-public-files.s3.amazonaws.com/icons/e-remove.svg",
    "https://rarify-public-files.s3.amazonaws.com/icons/edit-curves.svg",
    "https://rarify-public-files.s3.amazonaws.com/icons/layout.svg",
    "https://rarify-public-files.s3.amazonaws.com/icons/multiple.svg",
    "https://rarify-public-files.s3.amazonaws.com/icons/multiple1.svg",
    "https://rarify-public-files.s3.amazonaws.com/icons/new-construction.svg",
    "https://rarify-public-files.s3.amazonaws.com/icons/news.svg",
    "https://rarify-public-files.s3.amazonaws.com/icons/organic.svg",
    "https://rarify-public-files.s3.amazonaws.com/icons/person-and-table.svg",
    "https://rarify-public-files.s3.amazonaws.com/icons/playlist.svg",
    "https://rarify-public-files.s3.amazonaws.com/icons/reading.svg",
    "https://rarify-public-files.s3.amazonaws.com/icons/saved-items.svg",
    "https://rarify-public-files.s3.amazonaws.com/icons/table.svg",
    "https://rarify-public-files.s3.amazonaws.com/icons/talking.svg",
    "https://rarify-public-files.s3.amazonaws.com/icons/tooth.svg",
    "https://rarify-public-files.s3.amazonaws.com/icons/triangle-right.svg",
    "https://rarify-public-files.s3.amazonaws.com/icons/trophy.svg",
    "https://rarify-public-files.s3.amazonaws.com/icons/university.svg",
    "https://rarify-public-files.s3.amazonaws.com/icons/zoom.svg",
  ];

  // some items is converted to other format. For it we need to check mpd_src
  const src = item?.mpd_src || item?.src;

  if (!src) {
    return undefined;
  }

  if (defaultIconsList.includes(src)) {
    return src;
  }

  return changeToDefaultDomain(src);
};

export default getGeneratedImgUrl;
