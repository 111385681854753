import * as React from "react";

const BookmarksIcon = ({ className, color = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
      strokeLinejoin: "round",
    }}
    viewBox="0 0 13 17"
    className={className}
  >
    <path
      d="M7 4.5v4M5 6.5h4M1.5 15.5l5.5-4 5.5 4V2A1.5 1.5 0 0 0 11 .5H3A1.5 1.5 0 0 0 1.5 2v13.5Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: color,
        strokeWidth: "1.5px",
      }}
      transform="translate(-.75 .25)"
    />
  </svg>
);
export default BookmarksIcon;
