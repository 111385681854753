import React from "react";

const MyLearningAndDevelopmentIcon = ({ color, ...props }) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_367_5052)">
        <path
          d="M6.25 11.75C6.94036 11.75 7.5 11.1904 7.5 10.5C7.5 9.80964 6.94036 9.25 6.25 9.25C5.55964 9.25 5 9.80964 5 10.5C5 11.1904 5.55964 11.75 6.25 11.75Z"
          fill={color}
        />
        <path
          d="M6.25 13C6.91304 13 7.54893 13.2634 8.01777 13.7322C8.48661 14.2011 8.75 14.837 8.75 15.5H3.75C3.75 14.837 4.01339 14.2011 4.48223 13.7322C4.95107 13.2634 5.58696 13 6.25 13Z"
          fill={color}
        />
        <path
          d="M11.875 11.125H15.625"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.875 14.875H15.625"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.625 4.875H1.875C1.54348 4.875 1.22554 5.0067 0.991117 5.24112C0.756696 5.47554 0.625 5.79348 0.625 6.125V17.375C0.625 17.7065 0.756696 18.0245 0.991117 18.2589C1.22554 18.4933 1.54348 18.625 1.875 18.625H18.125C18.4565 18.625 18.7745 18.4933 19.0089 18.2589C19.2433 18.0245 19.375 17.7065 19.375 17.375V6.125C19.375 5.79348 19.2433 5.47554 19.0089 5.24112C18.7745 5.0067 18.4565 4.875 18.125 4.875H14.375"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 1.125C10.4973 1.125 10.9742 1.32254 11.3258 1.67417C11.6775 2.02581 11.875 2.50272 11.875 3V7.375H8.125V3C8.125 2.50272 8.32254 2.02581 8.67417 1.67417C9.02581 1.32254 9.50272 1.125 10 1.125V1.125Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_367_5052">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MyLearningAndDevelopmentIcon;
