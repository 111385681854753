import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isSessionExpired: false,
  isAccessTokenMissing: false,
};

export default createSlice({
  name: "Security",
  initialState,
  reducers: {
    sessionExpired: (state) => {
      state.isSessionExpired = true;
    },
    missingAccessToken: (state) => {
      state.isAccessTokenMissing = true;
    },
  },
});
