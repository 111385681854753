import React from "react";

const EmptyBackgroundComponent = ({ children, hide, className = "" }) => {
  if (hide) {
    return children;
  }
  return (
    <>
      <div
        className={`bg-primary absolute top-0 left-0 w-full h-full ${className}`}
      />
      <div
        className={`bg-white opacity-80 w-full h-full absolute top-0 left-0 ${className}`}
      />
    </>
  );
};

export default React.memo(EmptyBackgroundComponent);
