import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import axios from "axios";

import { endpoints } from "../../settings/endpoints";
import { GET, PATCH } from "../../utils/apiRequests";
import { gaUserLogin } from "../../utils/gaEvents";

export const FETCH_USER_REDUCER = createAsyncThunk(
  "UserReducer/fetchUserReducer",
  async (callback = () => {}, { rejectWithValue }) => {
    try {
      const response = await GET(endpoints.user.profile.index);
      const data = response.data;
      gaUserLogin(data.ga_id);

      callback(data);
      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        return rejectWithValue(e?.response?.data);
      }
    }
  },
);

export const UPDATE_USER_REDUCER = createAsyncThunk(
  "UserReducer/updateUserReducer",
  async ({ body, callback = () => {} }, { rejectWithValue }) => {
    try {
      const response = await PATCH(endpoints.user.profile.index, body);
      const data = response.data;

      callback(data);
      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        return rejectWithValue(e?.response?.data);
      }
    }
  },
);

const initialData = {
  error: null,
  data: {},
  loading: false,
};

export const UserReducer = createSlice({
  name: "UserReducer",
  initialState: { ...initialData },
  extraReducers: (builder) => {
    builder.addCase(FETCH_USER_REDUCER.fulfilled, (state, { payload }) => {
      state.data = {
        ...payload,
      };
      state.loading = false;
    });
    builder.addCase(UPDATE_USER_REDUCER.fulfilled, (state, { payload }) => {
      state.data = {
        ...state.data,
        ...payload,
      };
      state.loading = false;
    });
    builder.addMatcher(
      isAnyOf(FETCH_USER_REDUCER.pending, UPDATE_USER_REDUCER.pending),
      (state) => {
        state.error = null;
        state.loading = true;
      },
    );
    builder.addMatcher(
      isAnyOf(FETCH_USER_REDUCER.rejected, UPDATE_USER_REDUCER.rejected),
      (state, { payload }) => {
        state.loading = false;
        state.error = payload || {};
      },
    );
  },
});

export default UserReducer.reducer;
