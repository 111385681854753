export const endpoints = {
  triggerResendCode: "/trigger-resend-code",
  cookies: {
    assets: "/assets-cookies",
    frontend: "/frontend-cookies",
  },
  assessment: {
    current: "/assessment/current/active",
    scoring: "/assessment/scoring",
    save: {
      answers: "/assessment/save/answers",
      finish: "/assessment/save/finish",
    },
  },
  curriculum: {
    personalized: "/curriculum/personalized",
    list: "/curriculum/list",
  },
  user: {
    myTeam: "/user/my_team",
    profile: {
      index: "/user/profile",
      avatar: "/user/profile/avatar",
    },
  },
  contest: {
    list: "/contests",
    milestones: "/milestones",
    challenges: "/challenges",
    successMessage: "/success_message",
    gameQuiz: "/game-integrations/details",
    markAsViewed: "/milestones/messages/mark-as-viewed",
  },
  companies: "/companies",
  search: "/search",
  teamsLeaderboard: "/leaderboard?type=team",
  usersLeaderboard: "/leaderboard?type=user",
  dict: {
    categories: "/dict/categories/active",
    subcategories: "/dict/subcategories/active",
    brands: "/dict/brands/active",
    performanceGoals: "/dict/performance-goals/active",
    resources: {
      index: "/dict/resources",
      new: "/dict/resources/new",
      list: "/dict/resources",
      markCompleted: "/mark-completed",
      upload: "/upload",
      bookmarks: "/bookmarks",
      tags: "/tags",
    },
    news: "/dict/news/active",
    areas: "/dict/areas/active",
    positions: "/dict/positions/active",
    supervisors: "/dict/supervisors/active",
  },
  scormApi: "/scorm-api",
  signedUrl: "/signed-url/answer-upload",
  getScormUrl: () => {
    if (process.env.REACT_APP_SCORM_ROOT_URL) {
      return process.env.REACT_APP_SCORM_ROOT_URL;
    }

    return "/scorm-proxy/resource";
  },
};
