import * as React from "react";
const LinksIcon = ({ color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeMiterlimit: 10,
    }}
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      d="M8.995 7.005a3.518 3.518 0 0 1 0 4.975l-1.99 1.99A3.518 3.518 0 1 1 2.03 8.995l1.748-1.698"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: color,
        strokeWidth: "1.5px",
      }}
      transform="translate(-.25 -.25)"
    />
    <path
      d="M7.005 8.995a3.518 3.518 0 0 1 0-4.975l1.99-1.99a3.518 3.518 0 1 1 4.975 4.975l-1.748 1.698"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: color,
        strokeWidth: "1.5px",
      }}
      transform="translate(-.25 -.25)"
    />
  </svg>
);
export default LinksIcon;
