import * as React from "react";

import { BRAND_BACKGROUND_PATH_CLASS_NAMES } from "../../../constants/constants";

const BrandBackground1Icon = (props) => (
  <svg
    viewBox="0 0 332 123"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    {...props}
  >
    <path
      style={{
        fill: "url(#a)",
      }}
      d="M0 0h332v123H0z"
    />
    <path className={BRAND_BACKGROUND_PATH_CLASS_NAMES} d="M0 0h332v123H0z" />
    <path
      d="M87.512 123C96.624 66.335 145.791 23 205 23c59.209 0 108.376 43.335 117.488 100H87.512Z"
      style={{
        fill: "#fff",
        fillOpacity: 0.13,
      }}
    />
    <path
      d="m204.998 0 .002.5C205 55.967 159.967 101 104.5 101S4 55.967 4 .5l.002-.5h200.996Z"
      style={{
        fill: "#fff",
        fillOpacity: 0.19,
      }}
    />
    <defs>
      <linearGradient
        id="a"
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(-.396 83.0035 -83.0035 -.396 166.468 39.996)"
      >
        <stop
          offset={0}
          style={{
            stopColor: "white",
            stopOpacity: 0,
          }}
        />
        <stop
          offset={0.8}
          style={{
            stopColor: "black",
            stopOpacity: 0.31,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "black",
            stopOpacity: 0.31,
          }}
        />
      </linearGradient>
    </defs>
  </svg>
);

export default BrandBackground1Icon;
