import React from "react";

import PageLoaderComponent from "./PageLoaderComponent";

const FullScreenLoaderComponent = ({ className = "" }) => {
  return (
    <PageLoaderComponent
      loading
      className={`w-screen h-custom-screen fullPageLoader ${className}`}
    />
  );
};

export default FullScreenLoaderComponent;
