import * as Sentry from "@sentry/react";
import React from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import prepareConfiguration from "./prepareConfiguration";

class SentryHelperCls {
  _is_init = false;

  _makeEnvName() {
    try {
      let stage = process.env.REACT_APP_STAGE;
      if (!stage) {
        stage = "unknown";
      }

      let companySlug = "unknown";
      const hostname = window.location.hostname;

      if (["localhost", "127.0.0.1"].includes(hostname)) {
        companySlug = hostname;
      } else {
        const h_segments = hostname.split(".");
        if (h_segments.length >= 2) {
          companySlug = h_segments[0].replace("-admin", "");
        }
      }

      return `${companySlug} [${stage}]`;
    } catch (e) {
      return "[ENV Name ERROR]";
    }
  }

  init() {
    const configuration = prepareConfiguration();

    if (!configuration.dsn || !configuration.enabled) {
      return;
    }

    const replayConfiguration = {
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: configuration.sessionReplay.sampleRate,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: configuration.sessionReplay.onErrorSampleRate,
    };

    Sentry.init({
      dsn: configuration.dsn,
      debug: configuration.debug,
      enableTracing: true,
      tracesSampleRate: 1.0,
      environment: this._makeEnvName(),
      ...replayConfiguration,
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect: React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.httpClientIntegration(),
        Sentry.breadcrumbsIntegration(),
        Sentry.captureConsoleIntegration({
          levels: ["error"],
        }),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      sendDefaultPii: true,
    });

    this._is_init = true;
  }

  isInitialized() {
    return this._is_init;
  }

  getSentry() {
    return Sentry;
  }
}

export const SentryHelper = new SentryHelperCls();
