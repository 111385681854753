import React from "react";

import { useSelector } from "react-redux";
import packageJson from "../../../package.json";
import { USER_ROLES } from "../../constants/constants";

const VersionComponent = () => {
  const { data } = useSelector((state) => state.UserReducer);

  if (data?.user_role !== USER_ROLES.ADMIN) {
    return <span />;
  }
  return <span>Platform version: {packageJson.version}</span>;
};

export default React.memo(VersionComponent);
