import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

import activeIcon from "../../assets/icons/badge/active.svg";
import checkGrayIcon from "../../assets/icons/badge/check-gray.svg";
import checkIcon from "../../assets/icons/badge/check.svg";
import crossGrayIcon from "../../assets/icons/badge/cross-gray.svg";
import crossIcon from "../../assets/icons/badge/cross.svg";
import goAheadIcon from "../../assets/icons/badge/go-ahead.svg";
import inProgressIcon from "../../assets/icons/badge/in_progress.svg";
import STATUS_LIST from "../../constants/statusList";
import TEST_IDS from "../../constants/testIds";

// This component displays statuses and dates for resources, curriculums and challenges
const BadgeComponent = ({
  text,
  status = STATUS_LIST.DEFAULT,
  withIcon = true,
  icon = null,
  hide,
  className,
}) => {
  const badgeClassName = classNames(
    "text-minimum lg:text-xs py-1 px-2.5 lg:py-1.5 lg:px-3 min-w-max w-fit rounded font-medium cursor-default flex gap-1.5 items-center justify-center",
    {
      "bg-success-light text-success-dark": status === STATUS_LIST.FINISHED, // finished
      "bg-in-progress-light text-in-progress-dark": [
        STATUS_LIST.IN_PROGRESS,
        STATUS_LIST.TRY_AGAIN,
      ].includes(status), // in progress
      "bg-active-light text-active-dark": status === STATUS_LIST.ACTIVE, // active
      "bg-warning-light text-warning-dark": status === STATUS_LIST.UPCOMING, // upcoming
      "bg-participantRing text-cement": [
        STATUS_LIST.CANCELED,
        STATUS_LIST.DISABLED,
        STATUS_LIST.DEFAULT,
      ].includes(status), // disabled || canceled || default
      "bg-alert-light text-alert-dark": [
        STATUS_LIST.ENDED,
        STATUS_LIST.FAILED,
        STATUS_LIST.ALERT,
      ].includes(status), // ended || failed || alert
      "bg-primary text-white": status === STATUS_LIST.PRIMARY,
    },
    className,
  );
  const iconSrc = useMemo(() => {
    if (icon) {
      return icon;
    }

    switch (status) {
      case STATUS_LIST.ACTIVE:
        return activeIcon;
      case STATUS_LIST.ENDED:
      case STATUS_LIST.FAILED:
      case STATUS_LIST.ALERT:
        return crossIcon;
      case STATUS_LIST.DEFAULT:
      case STATUS_LIST.DISABLED:
      case STATUS_LIST.CANCELED:
        return crossGrayIcon;
      case STATUS_LIST.TRY_AGAIN:
      case STATUS_LIST.IN_PROGRESS:
        return inProgressIcon;
      case STATUS_LIST.FINISHED:
        return checkIcon;
      case STATUS_LIST.UPCOMING:
        return goAheadIcon;
      default:
        return checkGrayIcon;
    }
  }, [status, icon]);

  if (hide) {
    return null;
  }

  return (
    <div className={badgeClassName} data-testid={TEST_IDS.BADGE}>
      {withIcon && (
        <img
          src={iconSrc}
          alt={text}
          title={text}
          data-testid={TEST_IDS.BADGE_ICON}
        />
      )}
      <span>{text}</span>
    </div>
  );
};

BadgeComponent.propTypes = {
  text: PropTypes.string.isRequired,
  status: PropTypes.oneOf(Object.values(STATUS_LIST)),
  withIcon: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  hide: PropTypes.bool,
  className: PropTypes.string,
};

export default BadgeComponent;
