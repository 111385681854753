import React from "react";
import useShortSideMenuClasses from "../../../hooks/useShortSideMenuClasses";
import SideMenuComponent from "../SideMenuComponent";

const DesktopSideMenuComponent = () => {
  const containerClassNames = useShortSideMenuClasses(
    "lg:w-3/12 xl:w-1/5",
    "lg:w-20",
  );

  return (
    <div className={`hidden lg:block bg-white ${containerClassNames}`}>
      <SideMenuComponent />
    </div>
  );
};

export default React.memo(DesktopSideMenuComponent);
