import classNames from "classnames";
import React, { useContext, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useLocation } from "react-router-dom";
import TEST_IDS from "../../constants/testIds";
import { AuthContext } from "../../contexts/AuthProvider";
import { CompanyDataContext } from "../../contexts/CompanyDataProvider";
import useMetaTitle from "../../hooks/useMetaTitle";
import useShortSideMenuClasses from "../../hooks/useShortSideMenuClasses";
import useShowMobile from "../../hooks/useShowMobile";
import { FETCH_USER_REDUCER } from "../../store/reducers/UserReducer";
import { gaPageViewEvent } from "../../utils/gaEvents";
import BackgroundComponent from "../Background/BackgroundComponent";
import FooterComponent from "../Footer/FooterComponent";
import FullScreenLoaderComponent from "../Loaders/FullScreenLoaderComponent";
import NavbarComponent from "../Navbar/NavbarComponent";
import ServiceWorkComponent from "../ServiceWork/ServiceWorkComponent";
import DesktopSideMenuComponent from "../SideMenu/components/DesktopSideMenuComponent";
import MobileSideMenuComponent from "../SideMenu/components/MobileSideMenuComponent";

// This is a global container
const MainContainerComponent = ({ children, withoutMain }) => {
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const location = useLocation();
  const companyContextData = useContext(CompanyDataContext);
  const authContextData = useContext(AuthContext);
  const isMobile = useShowMobile();
  const containerClassNames = useShortSideMenuClasses("lg:w-4/5", "lg:w-full");
  const { title, showSideMenu } = useSelector((state) => state.MainReducer);
  const metaTitle = useMetaTitle(title);
  const disableContent = useMemo(
    () => (showSideMenu ? "pointer-events-none" : "pointer-events-auto"),
    [showSideMenu],
  );
  const containerClassName = useMemo(() => {
    const height = isMobile ? "" : "h-custom-screen";
    return `w-full lg:w-4/5 flex flex-col justify-between ${disableContent} ${containerClassNames} ${height}`;
  }, [disableContent, containerClassNames, isMobile]);

  // if the page opens another subpage, scroll to the top
  useEffect(() => {
    if (containerRef?.current?.scrollTo) {
      containerRef.current.scrollTo(0, 0);
      return;
    }

    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    // sends a page view event to GA when meta title changes
    if (metaTitle && title) {
      gaPageViewEvent(metaTitle);
      document.title = metaTitle;
    }
  }, [metaTitle]);

  useEffect(() => {
    dispatch(FETCH_USER_REDUCER());
  }, [dispatch]);

  // Login page has no default components of the main container
  if (withoutMain) {
    return <>{children}</>;
  }

  return (
    <>
      {!companyContextData.isLoading && !authContextData.isLoading ? (
        <>
          <div className="block" data-testid={TEST_IDS.DESKTOP_LAYOUT}>
            <div
              className={classNames("text-dark font-inter sm:flex", {
                "sm:h-custom-screen": !isMobile,
              })}
            >
              <DesktopSideMenuComponent />
              <MobileSideMenuComponent />
              <BackgroundComponent />
              <div
                data-testid={TEST_IDS.DESKTOP_MAIN_CONTAINER}
                className={containerClassName}
              >
                <NavbarComponent />
                <div
                  ref={containerRef}
                  className="p-mobile flex-grow overflow-y-auto border-gray sm:p-tablet 2xl:p-desktop"
                >
                  {children}
                </div>
                <FooterComponent />
              </div>
            </div>
          </div>
        </>
      ) : (
        <FullScreenLoaderComponent />
      )}
      <ServiceWorkComponent />
    </>
  );
};

export default React.memo(MainContainerComponent);
