import React, { useMemo } from "react";
import TEST_IDS from "../../constants/testIds";
import TooltipComponent from "../Tooltip/TooltipComponent";

// is used to indicate that it is a newly added challenge or that the milestone has newly added challenges
const PingComponent = ({
  show,
  sizeClassNames = "h-2 w-2",
  pingColor,
  className = "",
}) => {
  const color = useMemo(() => pingColor || "var(--primary-color)", [pingColor]);

  if (!show) {
    return null;
  }

  return (
    <>
      <TooltipComponent title="New updates" className={className}>
        <span
          className={`relative flex ${sizeClassNames}`}
          data-testid={TEST_IDS.PING}
        >
          <span
            className="animate-ping absolute inline-flex h-full w-full rounded-full opacity-75"
            style={{ backgroundColor: color }}
            data-testid={TEST_IDS.PING_ANIMATE}
          />
          <span
            className={`relative inline-flex rounded-full ${sizeClassNames}`}
            style={{ backgroundColor: color }}
          />
        </span>
      </TooltipComponent>
    </>
  );
};

export default React.memo(PingComponent);
