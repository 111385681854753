import {
  RESOURCE_TYPES,
  SINGLE_ITEM_TYPES,
} from "../constants/singleItemTypes";
import { GA_ACTIONS, GA_CATEGORIES, gaEventPush } from "./gaEvents";
import generateSingleItemUrl from "./generateSingleItemUrl";

// if the challenge/resource is a scorm, the platform opens the new tab with the scorm and opens the single page in the current tab
// The user will be redirected to the scorm page.

const openScorm = (resource, itemType) => {
  const { contentType, slug, id } = resource;
  // todo it will be removed when the client gets approval to add this implementation to the productions
  const notRedirect = true;

  if (notRedirect || contentType !== RESOURCE_TYPES.ZIP) {
    return;
  }

  const { url } = generateSingleItemUrl({
    contentType,
    slug,
    itemType,
  });

  const gaType =
    itemType === SINGLE_ITEM_TYPES.CHALLENGE
      ? GA_CATEGORIES.CHALLENGES
      : GA_CATEGORIES.RESOURCES;

  gaEventPush(gaType, GA_ACTIONS.OPENED, id);

  window.open(url, "_blank");
};

export default openScorm;
