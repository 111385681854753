import { Auth } from "aws-amplify";
import moment from "moment/moment";
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCookie } from "react-use";
import {
  IMAGE_ACCESS_COOKIES,
  IMAGE_EXPIRED_DATE_COOKIES,
} from "../constants/constants";
import { LOGIN_REDIRECT_STORAGE } from "../constants/storageNames";
import { LOGIN_ROUTE } from "../settings/routes";
import updateAccessToken from "../utils/localStorage/token/updateAccessToken";
import refreshUserSession from "../utils/security/refreshUserSession";
import setInitialCookies from "../utils/setInitialCookies";

export const AuthContext = createContext({});

export const AuthProvider = ({ children, initialLoading = true }) => {
  const [accessCookieValue] = useCookie(IMAGE_ACCESS_COOKIES);
  const [expiredDateCookieValue] = useCookie(IMAGE_EXPIRED_DATE_COOKIES);
  const [isLoading, setIsLoading] = useState(initialLoading);
  const navigate = useNavigate();
  const location = useLocation();
  const isOpenedLogin = useMemo(
    () => location.pathname === LOGIN_ROUTE,
    [location.pathname],
  );

  const setAccessToken = async (accessToken) => {
    updateAccessToken(accessToken);
  };

  const checkImageAssetCookies = useCallback(async () => {
    // if user returns from another tab and cookies are expired, should get new cookies
    const isCookiesExpired =
      !accessCookieValue ||
      !expiredDateCookieValue ||
      moment().isAfter(expiredDateCookieValue);

    if (process.env.NODE_ENV === "production" && isCookiesExpired) {
      try {
        await setInitialCookies();
        window.location.reload();
      } catch {
        // do nothing
      }
    }
  }, [accessCookieValue, expiredDateCookieValue]);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const accessToken = await Auth.currentSession()
      .then((res) => res.getAccessToken().getJwtToken())
      .catch(() => "");
    if (accessToken) {
      await setAccessToken(accessToken);
    } else {
      localStorage.setItem(LOGIN_REDIRECT_STORAGE, location.pathname);
      navigate(LOGIN_ROUTE);
    }
    await checkImageAssetCookies();
    setIsLoading(false);
  }, [navigate, checkImageAssetCookies]);

  // run when user returns from another tab
  const handleVisibilityChange = useCallback(async () => {
    if (!document.hidden && !isOpenedLogin) {
      await checkImageAssetCookies();
    }
  }, [isOpenedLogin]);

  useEffect(() => {
    const intervalId = refreshUserSession();

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (!isOpenedLogin) {
      fetchData();
    }
  }, [isOpenedLogin]);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  return (
    <AuthContext.Provider value={{ isLoading, setAccessToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
