const isIpad = () => {
  const userAgent = navigator.userAgent.toLowerCase();

  return (
    /ipad/i.test(userAgent) ||
    (/macintosh/i.test(userAgent) && "ontouchend" in document)
  );
};

export default isIpad;
