import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { DEFAULT_ACTIVE_TAB } from "../../constants/constants";
import { GET } from "../../utils/apiRequests";

export const FETCH_MY_TEAM_FILTER_ITEMS_REDUCER = createAsyncThunk(
  "MyTeamReducer/fetchMyTeamFilterItemsReducer",
  async (
    { endpoint, itemId = "id", itemLabel, id, callback = () => {} },
    { rejectWithValue },
  ) => {
    try {
      const response = await GET(endpoint);
      const responsedData = Array.isArray(response.data)
        ? response.data
        : response.data.data;
      const data = responsedData.map((item) => ({
        label: item[itemLabel],
        value: item[itemId],
        checked: false,
      }));

      data.unshift({
        ...DEFAULT_ACTIVE_TAB,
        checked: false,
      });

      callback();
      return {
        data,
        id,
      };
    } catch (e) {
      if (axios.isAxiosError(e)) {
        callback();
        return rejectWithValue(e?.response?.data);
      }
    }
  },
);

const initialFilters = {
  resources: [],
  brand: [],
  category: [],
  supervisor: [],
  position: [],
  area: [],
  strength: [],
  weakness: [],
};
const initialData = {
  error: null,
  data: {
    cachedFilters: initialFilters,
    checkedFilters: initialFilters,
    filters: initialFilters,
  },
  loading: false,
};

export const MyTeamReducer = createSlice({
  name: "MyTeamReducer",
  initialState: { ...initialData },
  reducers: {
    UPDATE_FILTER_ITEM_REDUCER: (state, { payload }) => {
      const { id, value, checked } = payload;
      const data = state.data;
      const checkedItems = [];

      if (!data?.filters[id]?.length) {
        return;
      }

      if (value === DEFAULT_ACTIVE_TAB.value) {
        data.checkedFilters[id] = checkedItems;
        data.filters[id] = data.filters[id].map((item) => ({
          ...item,
          checked,
        }));
        return;
      }

      data.filters[id] = data.filters[id].map((item) => {
        const newItem = {
          ...item,
          checked: item.value === value ? checked : item.checked,
        };

        if (newItem.checked) {
          checkedItems.push(newItem.value);
        }

        return newItem;
      });

      data.checkedFilters[id] = checkedItems;
    },
    RESET_FILTERS_REDUCER: (state) => {
      state.data.checkedFilters = { ...initialFilters };
      state.data.filters = { ...state.data.cachedFilters };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(FETCH_MY_TEAM_FILTER_ITEMS_REDUCER.pending, (state) => {
      state.error = null;
      state.loading = true;
    });
    builder.addCase(
      FETCH_MY_TEAM_FILTER_ITEMS_REDUCER.fulfilled,
      (state, { payload }) => {
        const { id, data } = payload;

        if (state.data.filters[id]) {
          state.data.filters[id] = data || [];
          state.data.cachedFilters[id] = data || [];
          state.data.checkedFilters[id] = [];
        }
        state.loading = false;
      },
    );
    builder.addCase(
      FETCH_MY_TEAM_FILTER_ITEMS_REDUCER.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload || {};
      },
    );
  },
});

export const { UPDATE_FILTER_ITEM_REDUCER, RESET_FILTERS_REDUCER } =
  MyTeamReducer.actions;
export default MyTeamReducer.reducer;
