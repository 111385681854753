import * as React from "react";
const DownloadResourceIcon = ({ className, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
      strokeLinejoin: "round",
    }}
    viewBox="0 0 17 17"
    className={className}
  >
    <path
      d="M8 .5v11M3.5 7 8 11.5 12.5 7M.5 15.5h15"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: color,
        strokeWidth: "1.5px",
      }}
      transform="translate(.25 .25)"
    />
  </svg>
);
export default DownloadResourceIcon;
