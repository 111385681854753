import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import TEST_IDS from "../../constants/testIds";
import { UPDATE_MAIN_ITEM_REDUCER } from "../../store/reducers/MainReducer";
import ShortSideMenuComponent from "../SideMenu/components/ShortSideMenuComponent";
import SideMenuIconComponent from "../SideMenu/components/SideMenuIconComponent";

const SideMenuButtonComponent = () => {
  const dispatch = useDispatch();
  const { showSideMenu } = useSelector((state) => state.MainReducer);

  const handleToggleMenu = useCallback(() => {
    dispatch(
      UPDATE_MAIN_ITEM_REDUCER({
        id: "showSideMenu",
        value: !showSideMenu,
      }),
    );
  }, [showSideMenu, dispatch]);

  return (
    <ShortSideMenuComponent isControl viceVersa>
      <div
        className="p-2 block lg:hidden"
        onClick={handleToggleMenu}
        data-testid={TEST_IDS.SIDE_MENU.ICON}
      >
        <SideMenuIconComponent />
      </div>
    </ShortSideMenuComponent>
  );
};

export default React.memo(SideMenuButtonComponent);
