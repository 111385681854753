import { Amplify } from "aws-amplify";
import { SnackbarProvider } from "notistack";
import React, { Suspense } from "react";
import ReactGA from "react-ga4";
import { Provider as StoreProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { Tooltip } from "react-tooltip";
import RoutesWrapper from "./RoutesWrapper";
import FullScreenLoaderComponent from "./components/Loaders/FullScreenLoaderComponent";
import RouterProviderComponent from "./components/RouterProvider/RouterProviderComponent";
import { TOOLTIP_ID } from "./constants/constants";
import AuthProvider from "./contexts/AuthProvider";
import CompanyDataProvider from "./contexts/CompanyDataProvider";
import { CONFIGURE_AMPLIFY } from "./settings/aws";
import { setupStore } from "./store";
import "react-tooltip/dist/react-tooltip.css";
import { setStore } from "./utils/api";

Amplify.configure(CONFIGURE_AMPLIFY);

if (process.env.REACT_APP_GA_MEASUREMENT_ID) {
  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
}

const App = () => {
  const store = setupStore();
  setStore(store);

  return (
    <>
      <SnackbarProvider
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        style={{
          background: "white",
          borderRadius: "var(--radius-small)",
          color: "var(--color-dark)",
        }}
        className="border border-gray"
        autoHideDuration={3000}
      >
        <StoreProvider store={store}>
          <BrowserRouter>
            <AuthProvider>
              <CompanyDataProvider>
                <Suspense fallback={<FullScreenLoaderComponent />}>
                  <RouterProviderComponent>
                    <RoutesWrapper />
                  </RouterProviderComponent>
                </Suspense>
              </CompanyDataProvider>
            </AuthProvider>
          </BrowserRouter>
        </StoreProvider>
      </SnackbarProvider>
      <Tooltip id={TOOLTIP_ID} />
    </>
  );
};

export default App;
